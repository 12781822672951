<div style="min-height: 478px;">
<div class="registerbtn_" style="font-family: OpenSansHebrewRegular;">
    <!-- <div (click)="backPress()" class="toolbar" style="cursor: pointer;"> -->
    <!-- <img (click)="backPress()" src="assets/images/Right_Arrow_White.png" style="cursor: pointer;"> -->
<h1 (click)="backPress()" style="position: inherit; text-align: center;
padding-top: 15px; color: white; font-family: OpenSansHebrewBold;" >רכוש גיפט קארד</h1>
<!-- </div> -->
</div> 
<!-- <div class="d-flex">
    <div class="m-auto" style="width: 73%; text-align: right; font-weight: bold;"> -->
    <div class="keep d-flex m-auto" style="width: 73%; font-weight: bold; font-family: OpenSansHebrewBold;">
        <img style="margin: auto;
        display: flex; align-items: center;
        color: white;" src="assets/images/Buy_Gift_Card_Input_Mony@3x.png">               
        <input style="font-size: 17px; padding: 5px; border: none; height: 30px;
        width: 20%; outline: none; box-shadow: none; color: black;" [(ngModel)]="sum" class="btn" placeholder="הזן סכום"/>
      </div>
<div class="d-flex m-auto " style="width: 57%; direction: rtl; text-align: right; font-family: OpenSansHebrewBold;">
        <div class="col-5" style="font-size: 13px;">
            <img src="assets/images/Pay_Page_Number_Box_Green.png" />
            <div class="caption post-content">
                <span style="color: white;">1</span>
            </div>
            <span style="font-weight: bold;">שלח גיפט קארד לחבר</span>
            </div>
    <div class="align-self-center">
        <img (click)="forFriend()" style="cursor: pointer;background-size: contain;" [src]="forFriendImg">
    </div>
</div>
<div class="d-flex m-auto " style=" font-family: OpenSansHebrewBold; width: 58%; direction: rtl; text-align: right;padding: .75rem 0rem;">
    <div class="col-5 alert-dismissible" style="font-size: 13px;">      
        <span style="font-weight: bold;">גיפט קארד עבורי</span>
        </div>
<div>
    <img (click)="forMe()" style="cursor: pointer;background-size: contain;" [src]="forMeImg">
</div>
</div>
<div class="d-flex"  style="margin-top: 15px; margin-bottom: 10px; font-family: OpenSansHebrewBold;" >
    <img class="m-auto" style="width: 72.3%; " src="assets/images/Pay_Page_Line.png">
</div>
<div *ngIf="isForFriend" class="d-flex m-auto " style="font-family: OpenSansHebrewBold; width: 56.5%; direction: rtl; text-align: right;">
    <div class="col-5" style="font-size: 13px;">
        <img src="assets/images/Pay_Page_Number_Box_Green.png" />
        <div class="caption post-content">
            <span style="color: white;">2</span>
        </div>
        <span style="font-weight: bold;">הזן מספר טלפון לשליחת</span>
        <div  style="font-size: 13px; font-weight: bold;">    
        <span style="padding-right: 2.3rem;">הגיפט קארד לחבר</span>
        </div>
        </div>
</div>
<div *ngIf="isForFriend" class="d-flex carousel-item-next" style="font-family: OpenSansHebrewBold; width: 38.5%; font-size: 20px;
font-weight: bold; direction: rtl;">
    <div style="margin-top: 15px; margin-bottom: 10px;" >
        <input  [(ngModel)]="phone" style="height: auto; text-align: center; color: black;
        font-size: 16px;"/>
        <img class="m-auto" src="assets/images/Buy_Gift_Card_Line_2@3x.png">
    </div>
    <!-- <div style="margin-top: 15px; margin-bottom: 10px; width: 172px;" >
    <input style="height: auto; text-align: center; color: black;
    font-size: 16px; width: 172px; width: 240px;" value="+972"/>
    <img class="m-auto" src="assets/images/Buy_Gift_Card_Line_3@3x.png">
</div> -->

</div>
<!-- <div *ngIf="isForFriend"  class="d-flex"  style="margin-top: 15px; margin-bottom: 10px;" >
    <img class="m-auto" style="width: 73%; " src="assets/images/Pay_Page_Line.png">
</div> -->


<div *ngFor="let p of payments ; let ind = index ">
    <div class="d-flex m-auto " style="font-family: OpenSansHebrewBold; width: 71.5%; direction: rtl; text-align: right;padding: .75rem 0rem;">
        <div class="col-5 alert-dismissible" style="font-size: 13px;">  
            <span style="font-weight: bold;">{{p.text}}</span>
            </div>
        <div class="align-self-center">
        <img (click)="paymentsBy(ind)" style="cursor: pointer;background-size: contain;" [src]="p.imageNumber==1 ? paymentsOn : paymentsOff">
        </div>
        </div>
</div>
<div  class="container" style=" font-family: OpenSansHebrewBold; height: 40%; font-size: 13px; background-size: 100% 100%;
background-image: url(assets/images/Buy_Gift_Card_BG_2@3x.png)">
    <form class="form-signin" [formGroup]="paymentForm">
        <div>
        <div class="d-flex m-auto " style="width: 97%; direction: rtl; text-align: right;">
            <div class="col-5" style="font-size: 13px;">
                <img src="assets/images/Pay_Page_Number_Box_Green.png" />
                <div class="caption post-content">
                    <span style="color: white;">{{paymentCardNumber}}</span>
                </div>
                <span style="font-weight: bold;">תשלום באשראי</span>
                </div>
        </div>
<div class="bg-img d-flex" style="padding-right:1rem;">
    <div class="col-6" style="font-size: 13px;">
    <div >
   <div class="d-flex">     
<p style="margin-bottom: 5px; padding-right: 2px;">שם בעל הכרטיס</p>
   </div>
   <div class="d-flex">
    <input [(ngModel)]="methodsPayment.UserName" autocomplete="off"  matInput formControlName="nvCardholderName" [ngClass]=" !isValidInput('nvCardholderName') ? 'input-street' : 'input-street-error' " type="text" required>
</div>
</div>
<div>
<div class="d-flex" style="margin-bottom: 5px; margin-top: 5px;">
<p class="col-6" style=" padding-right: 2px;">מספר הכרטיס</p>
</div>
<div class="d-flex"> 
    <input  [(ngModel)]="methodsPayment.CardNumber" autocomplete="off"  matInput formControlName="nvCardNumber" [ngClass]=" !isValidInput('nvCardNumber') ? 'input-street' : 'input-street-error' " required>
</div>
</div>
<div style="margin-top: 5px;">
    <div class="d-flex" style="margin-bottom: 5px;" >
        <p class="col-6" style="padding-right: 2px;">תוקף</p>
        <p class="col-6">cvv</p>
        </div>
        <div style="display: flex">   
            <mat-form-field appearance="outline">
                <input autocomplete="off" matInput [matDatepicker]="dp" (click)="dp.open()" class="box2" [formControl]="date"  >
            
                <mat-datepicker #dp
                                startView="multi-year"
                                (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)"
                                panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field>
              <input class="box1" style="margin-right: 3px;" maxlength="3" autocomplete="off" matInput name="methodsPayment.card_cvv" class="box2" [(ngModel)]="methodsPayment.card_cvv" required formControlName="cvv"/> 
            </div> 
            <div class="d-flex">     
                <p style="margin-bottom: 5px; padding-right: 2px;">מספר תעודת זהות של בעל הכרטיס</p>
                   </div>
                   <div class="d-flex">
                    <input [(ngModel)]="methodsPayment.UserIdentity" autocomplete="off"  matInput formControlName="identityNumber" [ngClass]=" !isValidInput('identityNumber') ? 'input-street' : 'input-street-error' " type="text" required>
                </div>
        </div>
 <br>
  <img (click)="keepCard()" [src]="keepCardImg" style="cursor: pointer;">
  <span style="margin-right: 5px; font-size: 13px;">שמור את הכרטיס שלי</span>
  <br>
  <br>
  <span *ngIf="paymentForm.valid==false"  style="color: #ff8585;">יש להזין את כל פרטי האשראי</span>
</div>
<div class="col-12" style="position:absolute; bottom:-15%; left:10%;">
    <div (click)="payment()"  class="wrapper" style="cursor: pointer; position: absolute; bottom: 0; left: 0;">
      <img src="assets/images/Delivery_Popup_Ok_Button.png" />
      <div   [class.isDisabled]="paymentForm.valid" class="caption post-content" style="cursor: pointer; margin: -27px 39% 12px;">
          <span style="color: white;">תשלום</span>
      </div>  
  </div>
  </div>
</div>
</div>
</form> 
</div>
</div>

