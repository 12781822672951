<div style="min-height: 580px; margin-top: 50px;">
    <div class="d-flex flex-wrap container m-auto" style=" font-family: OpenSansHebrewRegular">
                <div class="d-flex" style="width: 90%;" >
    <div class="col-md-4">
       <img style="margin-top: 25px;"  src="assets/images/Delivery_Popup_Logo.png">
    </div>
    <div style="left: 25%; margin-top: 20px; position: absolute;" class="col-md-6">
    <form [formGroup]="paymentForm" style="display: inline-block; font-size: 12px;" class="form-signin" >
    <br>
    <div  style="display: inline-block; left: 15%; position: absolute; top:10% ;" class="col-md-6">
        <div class="txt-input">
        <h3  style="color: black; font-weight: bold; font-size: 18px;">פרטי האשראי שלי</h3>
    </div>
    <br>
    <br>
    <div class="txt-input" >
    <span >שם בעל הכרטיס</span>
    </div>
    <br>
        <input autocomplete="off" name="payment.UserName" matInput  required class="box1" formControlName="UserName" [(ngModel)]="payment.UserName"/>
        <br>
            <div class="txt-input" >
                <span >מספר הכרטיס</span>
            </div>      
                <br> 
                <input autocomplete="off" matInput name="payment.CardNumber" required class="box1" [(ngModel)]="payment.CardNumber" formControlName="CardNumber" />            
                    <br>     
                        <div>
                            <div class="txt-input" style="width: 85%; display: flex;" >
                                <span style="width: 50%;
                                text-align: right;">תוקף </span>   
                                 <span class="col-7" style="text-align: right;">cvv</span>
                                </div>                      
                            <br>
                            <div style="display: flex; width: 85%;">   
                <mat-form-field appearance="outline">
                    <input autocomplete="off" matInput [matDatepicker]="dp" (click)="dp.open()" class="box2" [formControl]="date"  >
                
                    <mat-datepicker #dp
                                    startView="multi-year"
                                    (yearSelected)="chosenYearHandler($event)"
                                    (monthSelected)="chosenMonthHandler($event, dp)"
                                    panelClass="example-month-picker">
                    </mat-datepicker>
                  </mat-form-field>
                  <br>
                                 <input class="box1" style="margin-right: 3px;" maxlength="3" autocomplete="off" matInput name="payment.card_cvv" class="box2" [(ngModel)]="payment.card_cvv" required formControlName="cvv"/> 
                                </div>   
                            
                                <div class="txt-input" >
                                    <span >מספר תעודת זהות של בעל הכרטיס</span>
                                    </div>
                                    <br>
                                        <input autocomplete="off" name="payment.UserIdentity" matInput  required class="box1" formControlName="identityNumber" [(ngModel)]="payment.UserIdentity"/>               
                                    </div> 
                                    <span *ngIf="paymentForm.valid==false"  style="color: #ff8585; display: flex;">* יש להזין את כל הפרטים</span>   
                                    <br>
                                    <div class="txt-input" >
                                        <img class="align-self-center" (click)="keepCard()" [src]="keepCardImg" style="cursor: pointer;">                                                         
                                        <span style="margin-right: 10px; font-weight: normal; font-size: 11px; margin-bottom: 2px;">שמור את הכרטיס שלי</span>
                                    </div>  
                                    <div style="margin-top: 25px;">
                                        <div  class="left " style="left:-10%;"  >                      
                                            <div class="keep"  (click)="keep()">
                                             <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                                             <span class="btn_">שמור</span>
                                           </div>
                                     </div>
                                     <div  class="left " style="left: 35%;"  >                      
                                        <div class="keep"  (click)="remove()">
                                         <img src="assets/images/Delivery_Popup_Cancel_Button.png">               
                                         <span class="btn" style="color:#25b25e;">מחק אמצעי תשלום</span>
                                       </div>
                                    </div>
                                       </div>                                          
    </div>                           
    </form>
               </div></div>         
        </div> 
    </div>
    