import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { filter, pairwise } from 'rxjs/operators';
import { GiftCards } from 'src/app/models/giftCard';
import { Router ,RoutesRecognized ,NavigationEnd } from '@angular/router'
import { UserService } from 'src/app/services/user-service';
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder } from 'src/app/models/order';
import { CreditCardService } from 'src/app/services/creditCard-service';
import { PopupGiftcardComponent } from '../popup-giftcard/popup-giftcard.component';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.css']
})
export class GiftCardComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  giftCards:GiftCards[];
  order:MyOrder;
  page:number=1;
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  previousUrl:string=null;
  currentUrl: string = null;
  isPaymentByGiftCard:boolean=false;

  constructor(private dataService:DataService, private matDialog:MatDialog,private router: Router,private creaditCardService:CreditCardService, private proxy :AppProxy,private orderService:OrdersService,private userService:UserService) 
  {
    // router.events
    // .pipe(
    //   filter(event => event instanceof RoutesRecognized),
    //   pairwise()
    // )            
    // .subscribe((e: any) => {
    //     this.previousUrl = e[0].urlAfterRedirects;
    // });
   }

  ngOnInit(): void {
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.userService.getGiftCards().subscribe((res:any)=>{
      if(this.previousUrl==null)
      {
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
});
      }
      this.giftCards=res;
    
    });
    // this.giftCards=[
    //   {
    //     "ID":1,
    //     "Price":75,
    //     "BarCode":"12345",
    //   },
    //   {
    //     "ID":2,
    //     "Price":100,
    //     "BarCode":"56789",
    //   },
    //   {
    //     "ID":3,
    //     "Price":50,
    //     "BarCode":"54321",
    //   },
    //   {
    //     "ID":4,
    //     "Price":25,
    //     "BarCode":"09876",
    //   },
    //   {
    //     "ID":5,
    //     "Price":50,
    //     "BarCode":"78321",
    //   },
    //   {
    //     "ID":6,
    //     "Price":89,
    //     "BarCode":"01176",
    //   },
      
    // ]
  }

  
  onScrollDown() {
    this.appendItems();
    this.direction = "down";
  }

  onUp() {
    this.sum += 20;
    this.prependItems();
    this.direction = "up";
  }

  prependItems() {
    this.addItems();
  }

  appendItems() {
    this.addItems();
  }

  addItems() {
  //   this.page=Number(this.page)+1;
  //   if(this.id>-1)
  //   {
  //     this.bussinssService.getMainList(5,this.id,this.page).subscribe((res:any[])=>{
  //       for(let i=0;i<res.length;i++)
  //       {
  //       this.restaurantsItem.push(res[i]);
  //       }
  //   });
  // }
  // else
  // {
  //   this.bussinssService.getMenuBySearch(this.text,this.page).subscribe((res:any[])=>{
  //     for(let i=0;i<res.length;i++)
  //     {
  //     this.restaurantsItem.push(res[i]);
  //     }
  //   });
  // }
  }

  selectCard(giftCard:GiftCards){
    // this.router.events
    // .pipe(
    //   filter(event => event instanceof RoutesRecognized),
    //   pairwise()
    // )            
    // .subscribe((e: any) => {
    //     this.previousUrl = e[0].urlAfterRedirects;
    // });
   if(this.creaditCardService.IsPaymentByGiftCard==true)
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      let allPrice=Number(this.order.TotalPrice);
      if(this.order.IsDelivery==1)
      allPrice+=Number(this.order.DeliveryPrice);
      // if(this,this.order.Balance!=null && this.order.Balance!="")
      // allPrice=Number(Number(allPrice)- Number(this.order.Balance));

     // this.router.navigate(['../restaurantCategory']);
      if(this.order.OrderID!=0) 
      {
        let price=Number(this.order.Balance);
        let status=2;
        if(price>Number(giftCard.Price))
        {
          price=Number(giftCard.Price)
          status=1;
        }
        let isAddress=1;
        if(this.order.Address!=null)
        isAddress=0;
        this.orderService.setUpdateOrder(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,
          null,0,status,null,isAddress,this.order.Address,this.order.Lat+"",this.order.Lng+"",1,Number(giftCard.Number),price,null,null).subscribe((res:any)=>{
            this.orderService.getOrderDetails(false).subscribe((res:any)=>{
              this.order=res;
            localStorage.MENU_ID=0;
            if(Number(allPrice)<=Number(giftCard.Price) || Number(this.order.Balance)<=0)
            this.router.navigate(['../endOrder']);
            else
           {
           
            let iPay=Number(Number(allPrice)-Number(this.order.Balance));
            this.matDialog.open(PopupGiftcardComponent, {
              data: { price: allPrice+"" ,price2:Number(iPay) , payBy:"גיפט קארד"},
            });
           }
        
          });
          });}
    else
  {
    this.proxy.showError("אין לך הזמנה פתוחה","");
  }});
  }

  buyGiftCard(){
    this.router.navigate(['../giftCardPayment']);
  }

  backPress()
  {
    window.history.back();
  }
}
