import { Component, OnInit ,EventEmitter ,Input ,Output } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryItem ,Category} from 'src/app/models/category';
import { MyOrder } from 'src/app/models/order';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';
import { OrdersService } from 'src/app/services/order-service';
import { PopupOrderComponent } from '../popup-order/popup-order.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-main-list-item',
  templateUrl: './main-list-item.component.html',
  styleUrls: ['./main-list-item.component.css']
})
export class MainListItemComponent implements OnInit {
  stars:number[]=[1,2,3,4,5];
  @Input() categoryItem:CategoryItem;
  @Input() category:Category;
  @Input() indexList:number;
  @Input() restaurantCategory:number;
  @Output()
  change: EventEmitter<number> = new EventEmitter<number>();
  categoryType:number=1;
  clickFevorite=false;
  fevorit='assets/images/Restorant_Page_Fevorit_Button.png';
  fevoritActive='assets/images/Restorant_Page_Fevorit_Button_On.png';
  order:MyOrder;
  localId;
  Rate= new Array<number>(3);
  isClick=false;

  constructor(private matDialog:MatDialog,private router: Router,private dataService:DataService, private proxy: AppProxy,private bussinssService: BussinssService ,private orderService:OrdersService) {  }

  onClickCategory(CategoryNumber:number)
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
    if(localStorage.ACCESS_TOKEN  == "null" || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך מחובר נא להתחבר","");
    else
    {
      this.orderService.restaurant=null;
      localStorage.PRICE=0;
      this.isClick=true;
  {
    let id=this.categoryItem.ID;
    if(CategoryNumber==1 || this.restaurantCategory==8)
    {
      localStorage.ID=id;
      // localStorage.ID_CATEGORY= this.localId; 
        this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
      if(this.category.Name=="קטגוריות")
      this.router.navigate(['../restaurantCategory']);
             else     if(this.order.OrderID==0 && (this.order.list==null || this.order.list.length==0)) 
        {
            if(this.category.Name=="")
            localStorage.ID=this.categoryItem.RstaurantID;
                this.router.navigate(['../restaurant']);
        }
          else
          {
            if(this.isClick==true)
            {
              if(this.order.list==null || this.order.list.length==0 )
              {
              this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
              null,null,0,null,1,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
              this.router.navigate(['../restaurant']);
                 });
              }
              else
                 this.matDialog.open(PopupOrderComponent, {
                  data: { OrderID: this.order.OrderID },
                });
        }
         
        }
      });
    }
    else if(CategoryNumber==2)
    {
      localStorage.ID=id;
        this.router.navigate(['../restaurantCategory']);
    }}
}
  }

  ngOnInit(): void {
    this.localId=localStorage.ID;
    this.clickFevorite=false;
    if(this.categoryItem !=null && this.categoryItem.ImageFile!=null)
    if(this.category.categoryType==3 )
    this.categoryType=1;
    else if(this.category.categoryType==2)
    this.categoryType=2;
    if(this.categoryItem.IsFevorite!=0)
    this.fevorit='assets/images/Restorant_Page_Fevorit_Button_On.png';
    else
    this.fevorit='assets/images/Restorant_Page_Fevorit_Button.png';
    if(this.categoryItem.Rate==0 ||this.categoryItem.Rate==null)
    this.categoryItem.Rate=3;
  }

  addFevorit()
  {
    if(localStorage.ACCESS_TOKEN  == "null")
    this.router.navigate(['../mainHome']);
    else
    {
    this.clickFevorite=true;
   this.bussinssService.setFavoriteBusiness(this.categoryItem.ID).subscribe((res:any)=>{
      if(this.categoryItem.IsFevorite===0)
      this.categoryItem.IsFevorite=1;
      else
      this.categoryItem.IsFevorite=0;
      this.change.emit();
  });
}
}
}

