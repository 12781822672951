 <div class="d-flex" style="width: 73%; font-weight: bold;color: black;     margin-top: 65px; font-family: OpenSansHebrewRegular;">
  <footer style="height: 16%;" class="footer d-flex ">
      <div class="m-auto" >
        <div class="row d-flex m-auto" >
          <div style="background-color: #f5f5f5;  text-align: right;position: relative; margin-top: 30px;" class="col-4">
            <a style="font-family: OpenSansHebrewBold; cursor: pointer; font-size: 13px; color: black; text-decoration: underline;" (click)="openDialog()"
            target="_blank" rel="noopener">מדיניות פרטיות ותקנון</a>
  
          </div>
  
          <div  class="col-md-4 footer-column">
            <ul class="nav flex-column">
              <li class="nav-item">
                <img style="background-size: contain; background-size: contain;
                height:90px; margin-right: 2.1rem;" src="assets/images/Botoom_Bar_Logo.png"/>
              </li>
            
            </ul>
          </div>
          <div class="col-4 footer-column" style="text-align: left;position: relative; margin-top: 30px;">
            <ul class="nav flex-column" >
              <li class="nav-item">
                <span style="font-size: 13px; font-family: OpenSansHebrewBold;">  כל הזכויות שמורות ל "בא לי בקליק" </span>           
              </li>
            </ul>
          </div>
        </div>
      </div> 
    </footer>
</div>