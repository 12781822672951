<div style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold;">
  <form class="form-signin"> 
      <div class="form-group">
          <div style="text-align: center; color: #41c777; font-size: 25px; margin-top: 25px;">נשלח לינק ללקוח לאישור תשלום</div>
          <br>
          <br>
          <div style="padding: 15px; text-align: center; color: black; font-size: 18px; align-items: center">אנא המתן עד לקבלת האישור מחברת האשראי </div>
          <a (click)="copyMessage()" value="click to copy"  style="cursor: pointer; color: #416ec7; text-align:center; display: block; margin: 0 auto;"  >{{linkForSecure}}</a>
          <br> 
      </div>         
  </form>
</div>





