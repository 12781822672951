<div style="min-height: 580px;">
<div class="d-flex flex-wrap container" style="font-family: OpenSansHebrewBold ; margin-top: 50px;">
        <div class="left"  style="text-align: center; width: auto;color:#41c777 ;margin-top: 80px; font-size: 40px; font-weight: bold;">
            <div >איזה כיף!</div>
            <br>
            <br>
            <div style="padding: 15px;">הזמנתך התקבלה בהצלחה</div>
            <div class="keep"  (click)="ok()" style="margin-top: 160px; left: 25%;">
              <img src="assets/images/Delivery_Popup_Ok_Button.png">               
              <span style="font-size: 14px;" class="btn">אישור</span>
            </div>
            </div>
            <div class="d-flex" style="width: 90%;" >
<div class="col-md-4">
   <img style="margin-top: 25px;"  src="assets/images/Delivery_Popup_Logo.png">
</div>
         </div>     
           <!-- <div> -->
            <!-- <div  class="left " style="bottom: 45%;" >                       -->
                <!-- <div class="keep"  (click)="ok()">
                 <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                 <span style="font-size: 14px;" class="btn">אישור</span>
               </div> -->

               <!-- <div class="left keep" (click)="ok()" style=" margin-top: 12px;font-family: OpenSansHebrewRegular; width: max-content;">
                <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                <span class="btn">שלח</span> -->
    
        <!-- </div>  -->
         <!-- </div>     -->
       
           <!-- </div>                     -->
    </div> 
  </div>



