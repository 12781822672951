import { Component, OnInit ,HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router'
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder } from 'src/app/models/order';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-end-order',
  templateUrl: './end-order.component.html',
  styleUrls: ['./end-order.component.css']
})
export class EndOrderComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  order:MyOrder;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.router.navigate(['../mainHome']);
  }

  constructor(private dataService:DataService, private router: Router, private proxy :AppProxy,private orderService:OrdersService) { }

  ngOnInit(): void {
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
  }

  ok()
  {
  //   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
  //     this.order=res;
  //    // this.router.navigate(['../restaurantCategory']);
  //     if(this.order.OrderID!=0) 
  //     {
  //       this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,
  //         null,null,0,null,2,null,null,null,null,null,null,null).subscribe((res:any)=>{
  //           localStorage.MENU_ID=0;
  //           this.router.navigate(['../mainHome']);
  //         });
  // }
  // else
  this.router.navigate(['../mainHome']);
//});
  }
}
