import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentInfoComponent } from './components/payment-info/payment-info.component';
import { ContactComponent } from './components/contact/contact.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { ShippingHistoryComponent } from './components/shipping-history/shipping-history.component';
import { HistoryOrderItemComponent } from './components/history-order-item/history-order-item.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { SecurePaymentComponent } from './components/secure-payment/secure-payment.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { CategoryItemComponent } from './components/category-item/category-item.component';
import { MainHomeComponent } from './components/main-home/main-home.component';
import { PasswordComponent } from './components/password/password.component';
import { CategoryComponent } from './components/category/category.component';

//import { NgxCarouselModule } from 'ngx-carousel';
import { RouterModule, Route } from '@angular/router';
//import { from } from 'rxjs';
import { InjiService } from './services/inji.service'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/http.interceptor';
import { UserService} from './services/user-service'
//import {CarouselModule} from "ngx-carousel-lib";
import { NguCarouselModule } from '@ngu/carousel';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { NgCircleProgressModule } from 'ng-circle-progress';
//import {MDCDialog} from '@material/dialog';
 //import {  MatDialogModule } from '@angular/material/dialog';
//  import {
//   MatDialogModule,
// } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReservedRestaurantsComponent } from './components/reserved-restaurants/reserved-restaurants.component';
import { TopHeaderGreenComponent } from './components/top-header-green/top-header-green.component';
import { RestaurantComponent } from './components/restaurant/restaurant.component';
import { YourOrderComponent } from './components/your-order/your-order.component';
import { SearchComponent } from './components/search/search.component';
import { ReviewComponent } from './components/review/review.component';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
//import { MatProgressSpinnerModule } from '@angular/material'; 
//import { DragScrollModule } from 'ngx-drag-scroll';
//import { CarouselModule } from 'ngx-bootstrap';
import { 
	IgxCarouselModule,
	IgxListModule
 } from "igniteui-angular";
import { MatDialog } from '@angular/material/dialog';
import { AppProxy } from './services/app-proxy';
import { HttpClient } from '@angular/common/http';
import { Global_service } from './services/global.service';
import { RestaurantCategoryComponent } from './components/restaurant-category/restaurant-category.component';
import { ToppingsComponent } from './components/toppings/toppings.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { GiftCardPaymentComponent } from './components/gift-card-payment/gift-card-payment.component';
import { BussinssService } from './services/bussinss-service';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoginPhoneVarificationComponent } from './components/login-phone-varification/login-phone-varification.component';
import { OrdersService } from './services/order-service';
import { ToastrModule } from 'ngx-toastr';
import { CreditCardService } from './services/creditCard-service';
import { HistoryDeliveryItemComponent } from './components/history-delivery-item/history-delivery-item.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { GiftCardSucsessComponent } from './components/gift-card-sucsess/gift-card-sucsess.component';
import { ResraurantItemComponent } from './components/resraurant-item/resraurant-item.component';
import { SliderComponent } from './components/slider/slider.component';
//import { SlickModule } from 'ngx-slick';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {CdkScrollableModule} from '@angular/cdk/scrolling';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { CodeWorkerComponent } from './components/code-worker/code-worker.component';
import { DataService } from './services/data.service';
import { EndOrderComponent } from './components/end-order/end-order.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { MainListComponent } from './components/main-list/main-list.component';
import { MainListItemComponent } from './components/main-list-item/main-list-item.component';
import { BigImageComponent } from './components/big-image/big-image.component';
import { PopupOrderComponent } from './components/popup-order/popup-order.component';
import { FitText } from './fit-text';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { PopupGiftcardComponent } from './components/popup-giftcard/popup-giftcard.component';
import { PopupLoginComponent } from './components/popup-login/popup-login.component';
import { DialogOutOfBoundsComponent } from './components/dialog-out-of-bounds/dialog-out-of-bounds.component';
import { MapComponent } from './components/map/map.component';
import { Topping2Component } from './components/topping2/topping2.component';
import { VerificationCodeComponent } from './components/verification-code/verification-code.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PopupCreditSecureComponent } from './components/popup-creditSecure/popup-creditSecure.component';
const SERVICES = [
  InjiService,];

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    RegisterComponent,
    LoginComponent,
    HeaderComponent,
    PaymentInfoComponent,
    ContactComponent,
    DeliveryComponent,
    ShippingHistoryComponent,
    HistoryOrderItemComponent,
    OrderHistoryComponent,
    SecurePaymentComponent,
    TermsOfUseComponent,
    CategoryItemComponent,
    MainHomeComponent,
    PasswordComponent,
    CategoryComponent,
    ReservedRestaurantsComponent,
    TopHeaderGreenComponent,
    RestaurantComponent,
    YourOrderComponent,
    SearchComponent,
    ReviewComponent,
    RestaurantCategoryComponent,
    ToppingsComponent,
    GiftCardComponent,
    GiftCardPaymentComponent,
    LoginPhoneVarificationComponent,
    HistoryDeliveryItemComponent,
    GiftCardSucsessComponent,
    ResraurantItemComponent,
    SliderComponent,
    CodeWorkerComponent,
    EndOrderComponent,
    MainListComponent,
    MainListItemComponent,
    BigImageComponent,
    PopupOrderComponent,
    PopupCreditSecureComponent,
    FitText,
    PopupGiftcardComponent,
    PopupLoginComponent,
    DialogOutOfBoundsComponent,
    MapComponent,
    Topping2Component,
    VerificationCodeComponent,
    LoaderComponent,
  ],
  imports: [
    //MatInputModule,
    CdkScrollableModule,
    ScrollingModule,
    NgSelectModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    GalleryModule,
    LightboxModule,
   // CarouselModule,
   FormsModule,
   MatDatepickerModule,
   MatNativeDateModule,
   ReactiveFormsModule,
     //MatDialog,
    // MatFormField,
    MatInputModule,
     MatFormFieldModule,
     MatDialogModule,
    AppRoutingModule,
   // SlickModule.forRoot(),
    SlickCarouselModule,
   // NgxCarouselModule,

    //CarouselModule.forRoot(),
    IgxCarouselModule,
    MatCarouselModule.forRoot(),
   // NgbCarousel,
    NguCarouselModule,
   IgxListModule,
   ToastrModule.forRoot(),
   //DragScrollModule,
  
  
    // RouterModule.forRoot([
    //   { path: 'orderHistory', component: OrderHistoryComponent },
    // ]),
   // MDCDialog,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    //CarouselModule,

  NgbModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,
  MatSelectModule,
  MatFormFieldModule,
  NgxMatSelectSearchModule,
  VirtualScrollerModule,
  GooglePlaceModule,
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyC1lIMPz3cuZmZqhylKPGgbQzVq4_nnEzY',
    libraries: ['places']
  }),
  NgCircleProgressModule.forRoot({}),
  MatProgressSpinnerModule,
  ],

  entryComponents: [
    LoginComponent,
    BigImageComponent,
    LoginPhoneVarificationComponent,
    PasswordComponent,
    TermsOfUseComponent,
    PopupOrderComponent,
    PopupCreditSecureComponent,
    PopupGiftcardComponent,
    PopupLoginComponent,
    DialogOutOfBoundsComponent,
    VerificationCodeComponent,
    LoaderComponent,
  ],
  providers: [
    UserService,
    BussinssService,
    OrdersService,
    CreditCardService,
    DataService,
    // InjiService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AppProxy,
    //   multi: true
    // },
    AppProxy,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AppHttpInterceptor,
    //   multi: true
    // },
    Global_service,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
