<div style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold;">
    <form class="form-signin"> 
      <div class="form-group">
      <div style="text-align: center; color: #41c777; font-size: 25px; margin-top: 25px;" >התחברות</div>
      <br>
      <br>
      <div style="padding: 15px;   text-align: center;
      color: black;
      font-size: 18px;">אינך מחובר </div>
        <div style="padding: 15px;   text-align: center;
        color: black;
        font-size: 18px;">האם הנך מעוניין להתחבר?</div>
                <br> 
                <div>
                  <div  class="btm" style="font-size: 15px;">
                    <p (click)="end()"  class="p" style="cursor: pointer;color: #41c777; font-weight: bold ;">התחברות</p>
                    <p (click)="cancel()"  class="p" style="right: 62%; cursor: pointer;color: black; font-weight: bold ;">ביטול</p>
                  </div> 
                  </div> 
                  </div>         
    </form>
  </div>