import { Component, OnInit ,EventEmitter ,Input ,Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { OrdersService } from 'src/app/services/order-service';
import { UserService } from 'src/app/services/user-service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { AppComponent } from 'src/app/app.component';
import { CategoryComponent } from '../category/category.component';
import { DataService } from 'src/app/services/data.service';
import { MyOrder } from 'src/app/models/order';
import { Restaurant } from 'src/app/models/restaurant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  searchText:String;
  text: string;
  @Output()change: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  logInOrOut:string;
  price;
  widthScreen;
  priceTotal="0 - פריטים";
  order:MyOrder;
  show=true;
  restaurant:Restaurant=new Restaurant;
  showProgress=false;
  searchByFood=false;
  showSearch=false;

  constructor( private router: Router,private proxy:AppProxy,private dataService:DataService,private matDialog:MatDialog,private bussinssService: BussinssService,private orderService:OrdersService,private userService:UserService) {}

  connect(){
    this.router.navigate(['../register']);
  }
  orderHistory()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../orderHistory']);
  }

  shippingHistory(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../shippingHistory']);
  }

  paymentInfo(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../paymentInfo']);
  }

  reservedRestaurants(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../reservedRestaurants']);
  }

  delivery()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../delivery']);
  }

  search()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../search']);
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(LoginComponent, { disableClose: true });

  }

  payment()
  {
    if(location.hash=="#/restaurant")
    {
      this.showProgress=true;
      this.orderService.restaurant=this.bussinssService.restaurant;
    if(this.priceTotal!="0 - פריטים")
    {
      let canContinue = true;
           this.orderService.getOrderDetails(false).subscribe((res:any)=>{
       this.order=res;
      //  localStorage.isClickPayment="false";
      //  localStorage.phone="";
      //  localStorage.orderName="";
      //  localStorage.email="";
      //  localStorage.street="";
      //  localStorage.enter="";
      //  localStorage.floor="";
      //  localStorage.numberHoush="";
      //  localStorage.nvCardNumber="";
      //  localStorage.cvv="";
      //  localStorage.date="";
      //  localStorage.identityNumber="";
      //  localStorage.nvCardholderName="";
     // this.order=this.orderService.order;
      if(this.order!=null && this.order.list!=null)
      for(let i=0;i<this.order.list.length;i++)
      {
        let orderList=this.order.list[i];
         // for(let j=0;j<this.restaurant.MenuList.length;j++) {
            //  let categoryItemFood = this.restaurant.MenuList[j];
              if (orderList.IsAdditionRequired == 1)
              canContinue = false;
              if(orderList.listAddition.length>0)
              canContinue = true;
                  //if (orderList.MenuID == categoryItemFood.ID)
                    //  if(categoryItemFood.IsAdditionRequired==1) {
                  
                      //    if (orderList.listAddition.length==0) {
                            
                            //  j=this.restaurant.MenuList.length;
                        //  }
                    // }
      //    }
      }
      if(canContinue==true)
      {
        this.showProgress=false;
        this.show=false;
        this.dataService.show.next(false);
        this.changeShowPrice.emit();
        this.router.navigate(["../yourOrder"]);
      }
    else
    {
      this.showProgress=false;
    this.proxy.showError("לא בחרת \n את כל תוספות החובה","");
    }
    });
}
    else
    {
      this.showProgress=false;
    this.proxy.showError("ההזמנה ריקה","");
    }
}
else if(location.hash=="#/yourOrder")
{

}
else
{
  this.showProgress=false;
  this.show=false;
  this.dataService.show.next(false);
  this.changeShowPrice.emit();
  this.router.navigate(["../yourOrder"])
}
  }

  inOrOut()
  {
    if(localStorage.ACCESS_TOKEN != "null")
    {
      let reset = confirm("האם אתה בטוח שברצונך להתנתק מהאתר ");
      if (reset)
      {
        this.dataService.state.next("התחבר");
        localStorage.ACCESS_TOKEN = null;
        this.router.navigate(["../mainHome"])
      }
    }
    else{
      this.openDialog();
    }
  }


  // editProfil()
  // {
  //   if(localStorage.ACCESS_TOKEN  == "null")
  //   this.proxy.showError("אינך רשום למערכת","");
  //   else
  //   this.router.navigate(['../register']);
  // }


  searchByText()
  {
    if(this.text)
    {
     localStorage.TEXT_SEARCH=this.text;
     localStorage.ID="0";
     this.dataService.search.next(this.text);
     this.change.emit();
    // if(a=="#/restaurantCategory")
    
     //this.bussinssService.getMenuBySearch(this.text).subscribe((res:any)=>{
     
     this.router.navigate(['restaurantCategory']);
   //  });
    // else
  //  this.router.navigate(['../restaurantCategory']);
    this.text="";
    }
  }

  searchByTextFood()
  {
    if(this.text && this.text!="" && this.searchByFood==true )
    {
      let id;
      if(this.bussinssService.restaurant.ID!=undefined)
      id=this.bussinssService.restaurant.ID;
      else
      id=localStorage.ID;
     localStorage.ID=this.bussinssService.restaurant.ID;
      this.bussinssService.getBusinessMenuBySearch(id,this.text,1).subscribe((res:any[])=>{
        this.restaurant.MenuList=res;
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        if(this.restaurant.MenuList==null || this.restaurant.MenuList.length==0)
        {
          this.showProgress=false;
          this.proxy.showError("לא נמצאו מנות\n במסעדה זו","");
          this.text="";
        }
        else
        {
     localStorage.TEXT_SEARCH=this.text;
   //  localStorage.ID="0";
     this.dataService.search.next(this.text);
     this.change.emit();
     localStorage.ID=id;

      this.dataService.endToppings.next(true);
      this.change.emit();
    let url: string = "/restaurant/" ;
     this.router.navigateByUrl(url);
    this.text="";
  }
  });
}
  }

  mainHome()
  {
    if(localStorage.SMS=="")
    this.proxy.showError("חובה להכניס קוד","");
    else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
     this.proxy.showError("אינך רשום למערכת","");
     else
 this.router.navigate(['../mainHome']);
  }

  ngOnInit(): void {
  this.logInOrOut = this.dataService.state.getValue() || {};
  this.dataService.state.subscribe(data=>{
    this.logInOrOut=data;
  });
  this.priceTotal = this.dataService.price.getValue() || {};
  this.dataService.price.subscribe(data=>{
    this.priceTotal=data;
  });

  this.show = this.dataService.show.getValue() || {};
  this.dataService.show.subscribe(data=>{
    this.show=data;
  });
  this.searchByFood = this.dataService.byFood.getValue() || {};
  this.dataService.byFood.subscribe(data=>{
    this.searchByFood=data;
  });

  
  this.showSearch = this.dataService.showSearch.getValue() || {};
  this.dataService.showSearch.subscribe(data=>{
    this.showSearch=data;
  });
 // this.show=localStorage.SHOW;
  // if(location.hash=="#/restaurant")
  // this.show=true;
  // if(location.hash=="#/yourOrder")
  // this.show=false;
    this.widthScreen=this.widthScreen;
   // this.price=AppComponent.price;
   // this.priceTotal==AppComponent.priceTotal;
    this.bussinssService.getRestaurantDetails(localStorage.ID,false,false,false,null,null).subscribe((res:any)=>{
      this.restaurant=res;
    });
    // if(localStorage.ACCESS_TOKEN  == "null")
    // this.logInOrOut="התחבר";
    // else
    // this.logInOrOut="התנתק";
  }
}
