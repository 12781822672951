import { Component, OnInit ,ViewChild ,ElementRef, NgZone, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators ,FormBuilder } from '@angular/forms';
import { Address, Payment, UserEditProfile } from 'src/app/models/user';
import { Payments } from 'src/app/models/payments';
import { UserService } from 'src/app/services/user-service';
import { AppProxy } from 'src/app/services/app-proxy';
import { CreditCardService } from 'src/app/services/creditCard-service';
import { CreditCardObject } from 'src/app/models/creditCard';
import {MatDatepicker} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder } from 'src/app/models/order';
import { MapsAPILoader } from '@agm/core';
import { Restaurant } from 'src/app/models/restaurant';
import { BussinssService } from 'src/app/services/bussinss-service';
import { filter } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VerificationCodeComponent } from '../verification-code/verification-code.component';
import { DataService } from 'src/app/services/data.service';
import { PopupCreditSecureComponent } from '../popup-creditSecure/popup-creditSecure.component';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-secure-payment',
  templateUrl: './secure-payment.component.html',
  styleUrls: ['./secure-payment.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class SecurePaymentComponent implements OnInit {
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  anotherAddressImg='assets/images/Pay_Page_Chack_Box_On.png';
  myAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  isCreditPayment:boolean=true;
  cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
  paymentCreditImg='assets/images/Pay_Page_Chack_Box_On.png';
  isCreditSecureImg='assets/images/Pay_Page_Chack_Box_On.png';
  keepCardImg='assets/images/Pay_Page_Chack_Box_On.png';
  notKeepCardImg='assets/images/Pay_Page_Chack_Box_Off.png';
  isKeepCard=true;
  paymentForm: FormGroup;
  //addressForm:FormGroup;
  paymentCard:Payment;
  valid:boolean=false;
  payments:Payments[];
  paymentsOn='assets/images/Pay_Page_Chack_Box_On.png';
  paymentsOff='assets/images/Pay_Page_Chack_Box_Off.png';
  creditCardObject:CreditCardObject;
  userEditProfile:UserEditProfile;
  date = new FormControl(moment());
  date1:string="";
  month:string="";
  year:string="";
  totalPrice:string;
  deliveryPrice;
  isDelivery=0;
  paymentBy=1;
  address:Address;
  isChangeAdress=0;
  order:MyOrder;
  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  lat: string;
  lng: string;
  zoom = 1;
  address_: string;
  private geoCoder;
  methodsPayment:Payment=new Payment;
  Address:string;
  AddressMe:string;
  citiesList;
  priceGiftCard=0;
  selectedValue;
  token:string=null;
  byGiftCard=false;
  myAddressText:string="רביבים";
  price2:number=50;
  payBy=false;
  note:string;
  progressBar = document.querySelector('.progress-bar');
  progress = 0;
  intervalId;
  isClickPayment=false;
  showProgress=false;
  restaurant:Restaurant=new Restaurant;
  inCash=true;
  onCredit=true;
  myAdd = "";
  isCreditSecure = true
  dialogRef: MatDialogRef<VerificationCodeComponent>;
  startTime : Date
  endTime : Date
 
  options={
    componentRestrictions:{
      country:["isr"]
    }
  };
  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('dp')
  public dateElementRef: ElementRef;
  @ViewChild("cvv") cvvElement: ElementRef;
  @ViewChild("year") yearElement: ElementRef;
  @ViewChild("identity")identityElement:ElementRef;
  isAnotherAddress:boolean=true;
  IsDelivery=1;

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.year=""+normalizedYear.year();
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.month=""+Number(normalizedMonth.month()+1);
    datepicker.close();
    this.date.value._i="";
  }

  constructor(private router: Router, private dataService: DataService, private bussinssService:BussinssService, private proxy:AppProxy,private creaditCardService:CreditCardService
    ,private fb: FormBuilder, private matDialog:MatDialog,private orderService:OrdersService,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone) {
      this.address=new Address();
    }

    ngOnDestroy() {
      clearInterval(this.intervalId);
    }

    createForm() {

    //   this.paymentForm = this.fb.group({ // create nested formgroup to pass to child
    //     nvCardholderName: [, [Validators.required]],
    //     nvCardNumber: [, [Validators.required]],
    //     date: [],
    //     cvv: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
    //     identityNumber: new FormControl("", [Validators.required, Validators.minLength(9), Validators.maxLength(9)]),
    //     nvOrderName: [, [Validators.required]],
    //     nvStreet: [, [Validators.required]],
    //     nvEntry: [],
    //     nvfloor: [],
    //     nvNumberHoush: [],
    //     nvPhone: new FormControl("", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),
    //     nvEmail: new FormControl("", [Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    // });
      this.paymentForm = this.fb.group({
        nvCardholderName: ["", Validators.required],
        nvCardNumber: ["", Validators.required],
        month: ["", [Validators.required, Validators.maxLength(2)]],
          year: ["", [Validators.required, Validators.maxLength(2)]],
          cvv:  ["", [Validators.required, Validators.maxLength(3),Validators.minLength(3)]],
         identityNumber: ["",[Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
         nvOrderName: ["", Validators.required],
         nvStreet: ["", Validators.required],
         nvEntry: [""],
         nvfloor:[""],
         nvNumberHoush: [""],
         nvEmail: ["", [Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
         nvPhone: ["", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]],
      });

      this.paymentForm.get("month").valueChanges
      .pipe(filter((value: string) => value.length === 2))
      .subscribe(() => {
        this.yearElement.nativeElement.focus();
        this.paymentForm.get("year").setValue("");
      });

    this.paymentForm.get("year").valueChanges
      .pipe(filter((value: string) => value.length === 2))
      .subscribe(() => this.cvvElement.nativeElement.focus());

      this.paymentForm.get("cvv").valueChanges
      .pipe(filter((value: string) => value.length === 3))
      .subscribe(() => this.identityElement.nativeElement.focus());
    }

    createForm2() {
      this.paymentForm = this.fb.group({
        nvCardholderName: ["", Validators.required],
        nvCardNumber: ["", Validators.required],
        month: ["", [Validators.required, Validators.maxLength(2)]],
        year: ["", [Validators.required, Validators.maxLength(2)]],
        cvv:  ["", [Validators.required, Validators.maxLength(3),Validators.minLength(3)]],
        identityNumber: ["",[Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
        nvOrderName: ["", Validators.required],
        nvEmail: ["", [Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        nvPhone: ["", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]],
      });
    }

    createForm3() {
      this.paymentForm = this.fb.group({ // create nested formgroup to pass to child
        nvOrderName: [, [Validators.required]],
        nvStreet: [],
        nvEntry: [],
        nvfloor: [],
        nvNumberHoush: [],
        nvPhone: new FormControl("", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),
        nvEmail: new FormControl("", [Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    });
    }

    clearMonth(){
      this.paymentForm.get("month").setValue("");
    }
    
    clearYear(){
      this.paymentForm.get("year").setValue("");
    }

    ngAfterViewInit() {
      this.paymentForm.get("month").valueChanges
    .pipe(filter((value: string) => value.length === 2))
    .subscribe(() => {
      this.yearElement.nativeElement.focus();
      this.paymentForm.get("year").setValue("");
    });

  this.paymentForm.get("year").valueChanges
    .pipe(filter((value: string) => value.length === 2))
    .subscribe(() => this.cvvElement.nativeElement.focus());

    this.paymentForm.get("cvv").valueChanges
    .pipe(filter((value: string) => value.length === 3))
    .subscribe(() => this.identityElement.nativeElement.focus());
}

  getOrderDetails()
  {
   
    this.showProgress=true;
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      let note=this.order.Note;
      if(this.order.Note==null || this.order.Note=='')
      note=null;
      let PaymentBy;
      if(this.paymentBy==1)
      PaymentBy=0;
      else
      PaymentBy=1;
      let status=1;
      this.local();
      //if(this.paymentBy>1)
     // status=1;
      if(this.order.OrderID!=0) 
      {
        if(this.IsDelivery)
        {
          // if(this.byGiftCard==true)
          // {
          //   this.Address=this.order.Address;
          //   this.lat=this.order.Lat+"";
          //   this.lng=this.order.Lng+"";


          //   if(this.paymentBy==2)
          //   {
          //     this.showProgress=false;
          //   this.router.navigate(['../giftCard']); 
          //   this.creaditCardService.IsPaymentByGiftCard=true;
          //   }
          //  else if(this.paymentBy==3)
          //  {
          //   this.showProgress=false;
          //  this.router.navigate(['../codeWorker']);  
          //  }
         // }
         // else
          {
            if(this.Address!=null && this.Address!=undefined)
            {
          let entry=null;
          if(this.paymentForm.value.nvEntry!='')
          entry=this.paymentForm.value.nvEntry;
          let floor=null;
          if(this.paymentForm.value.nvfloor!='')
          floor=this.paymentForm.value.nvfloor;
          let homeNumber=null;
          if(this.paymentForm.value.nvNumberHoush!='')
          homeNumber=this.paymentForm.value.nvNumberHoush;
          if(!this.Address.includes(":"))
          {
       if(entry!=null)
       this.Address+=" כניסה: "+entry;
       if(floor!=null)
       this.Address+=" קומה: "+floor;
       if(homeNumber!=null)
       this.Address+=" דירה: "+homeNumber;
          }
          
          // let card_mask=null;
          // let card_validity=null;
        
          // if(this.paymentBy==1)
          // {
          //   card_mask=this.paymentForm.value.nvCardNumber;
          // card_validity=this.date1.replace("/","");
          // }
          let email=null;
          if(this.paymentForm.value.nvEmail!="")
          email=this.paymentForm.value.nvEmail;
            this.orderService.setUpdateOrder3(this.order.OrderID,this.paymentForm.value.nvOrderName,this.paymentForm.value.nvPhone,email,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,PaymentBy,
               Number(this.order.Balance),status,note,this.Address,this.lat,this.lng).subscribe((res:any)=>
               {
                localStorage.MENU_ID=0;
              
                if(this.paymentBy!=undefined )
                localStorage.paymentBy=this.paymentBy;
                if(this.isChangeAdress==1)
       if(res.responseMessage=="the delivery out of bounds")
     {
       this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
       this.paymentForm.value.nvStreet="";
       this.searchElementRef=null;

     }
     else{
      this.orderService.sendConfirmationSms(this.order.OrderID , this.isCreditSecure == true? localStorage.PRICE : 0).subscribe((res:any)=>{
      
       this.showProgress=false;
          this.openDialog(res.responseMessage);
          localStorage.isClickPayment="true";
        });
     }
            //    else 
            //    {
            //     this.showProgress=true
            //     if(this.paymentBy==1)
            //     {
            //         //if(this.date1=="")
            //         {
            //       var last2 = this.year.slice(-2);
            //       if(this.month.length==1)
            //       this.month="0"+this.month;
            //       this.date1=this.month+last2;
            //       this.date1=this.paymentForm.value.month+this.paymentForm.value.year;
            //         }
            //         if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
            //         this.token=null;
            //       this.orderService.setOrderPayment(this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
            //       this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,Number(this.order.Balance))
            //       .subscribe((res:any)=>
            //       {
            //        if(res.error==true)
            //        {
            //         this.showProgress=false;
            //         this.isClickPayment=false;
            //         this.proxy.showError(res.responseMessage,"");
            //        }
            //        else
            //       {
                 
            //         if(this.order.IsDelivery==1)
                 
            //           this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
            //           {
            //                 this.router.navigate(['../endOrder']);
            //                });
              
            //                 else
            //                 this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2).subscribe((res:any)=>
            //                 {
            //        this.router.navigate(['../endOrder']);
            //         });
            //       }
            //         //this.getOrderDetails();
            //        });
            //     }
            //    else 
            //   // if(this.paymentBy!=1 )
            //   {
            //     if(this.order.IsDelivery==1)
            //     {
            //     this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
            //     {
            //           this.router.navigate(['../endOrder']);
            //          });
            //         }
            //           else
            //           this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2).subscribe((res:any)=>
            //           {
            //  this.router.navigate(['../endOrder']);
            //   }); 
            // }
            //    }
               //if(this.paymentBy<=1)        
               // this.setUserCreditCard();
        
               
              });
          }
        else{
          this.proxy.showError("הכתובת שלך מחוץ לטווח\n חובה לבחור כתובת מגוגל ","");  
          this.paymentForm.value.nvStreet="";
          this.searchElementRef=null;
          this.isClickPayment=false;
        }}}
          else
          {
            let email=null;
            if(this.paymentForm.value.nvEmail!="")
            email=this.paymentForm.value.nvEmail;
            this.orderService.setUpdateOrder2(this.order.OrderID,this.paymentForm.value.nvOrderName,this.paymentForm.value.nvPhone,email,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,PaymentBy,status,note,1).subscribe((res:any)=>
            {
               localStorage.MENU_ID=0;
               //this.showProgress=false;
               {
                this.orderService.sendConfirmationSms(this.order.OrderID , this.isCreditSecure == true? localStorage.PRICE : 0).subscribe((res:any)=>{
                  // if(this.innerWidth<=480)
                  // this.router.navigate(['../verificationCode']);
                  // else
                  this.showProgress=false;
                     this.openDialog(res.responseMessage);
                   });
            //     this.showProgress=true
            //     if(this.paymentBy==1)
            //     {   
            //       var last2 = this.year.slice(-2);
            //       if(this.month.length==1)
            //       this.month="0"+this.month;
            //       this.date1=this.month+last2;
            //       this.date1=this.paymentForm.value.month+this.paymentForm.value.year;
            //         if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
            //         this.token=null;
            //       this.orderService.setOrderPayment(this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
            //       this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,Number(this.order.Balance)).subscribe((res:any)=>
            //       {
            //        if(res.error==true)
            //        {
            //         this.showProgress=false;
            //         this.isClickPayment=false;
            //         this.proxy.showError(res.responseMessage,"");
            //        }
            //        else
            //       {
                 
            //         if(this.order.IsDelivery==1)
            //         {
            //           this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
            //           {
            //                 this.router.navigate(['../endOrder']);
            //                });
            //               }
            //                 else
            //                 this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2).subscribe((res:any)=>
            //                 {
            //        this.router.navigate(['../endOrder']);
            //         });
            //       }
            //        });
            //     }
            //    else 
            //   {
            //     if(this.order.IsDelivery==1)
            //     {
            //     this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
            //     {
            //           this.router.navigate(['../endOrder']);
            //          });
            //         }
            //           else
            //           this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2).subscribe((res:any)=>
            //           {
            //  this.router.navigate(['../endOrder']);
            //   }); 
            // }
              }
              //this.setUserCreditCard();
             });
          }
        }
        else
        {
          let email=null;
          if(this.paymentForm.value.nvEmail!="")
          email=this.paymentForm.value.nvEmail;
        this.orderService.setUpdateOrder2(this.order.OrderID,this.paymentForm.value.nvOrderName,this.paymentForm.value.nvPhone,email,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,PaymentBy
        ,status,note,1).subscribe((res:any)=>
        {
            localStorage.MENU_ID=0;
            this.showProgress=false;
        //  this.setUserCreditCard();
        {
          this.orderService.sendConfirmationSms(this.order.OrderID , this.isCreditSecure == true? localStorage.PRICE : 0).subscribe((res:any)=>{
            // if(this.innerWidth<=480)
            // this.router.navigate(['../verificationCode']);
            // else
               this.openDialog(res.responseMessage);
             });
      //     this.showProgress=true
      //     if(this.paymentBy==1)
      //     {   
      //       var last2 = this.year.slice(-2);
      //       if(this.month.length==1)
      //       this.month="0"+this.month;
      //       this.date1=this.month+last2;
      //       this.date1=this.paymentForm.value.month+this.paymentForm.value.year;
      //         if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
      //         this.token=null;
      //       this.orderService.setOrderPayment(this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
      //       this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,Number(this.order.Balance)).subscribe((res:any)=>
      //       {
      //        if(res.error==true)
      //        {
      //         this.showProgress=false;
      //         this.isClickPayment=false;
      //         this.proxy.showError(res.responseMessage,"");
      //        }
      //        else
      //       {
           
      //         if(this.order.IsDelivery==1)
      //         {
      //           this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
      //           {
      //                 this.router.navigate(['../endOrder']);
      //                });
      //               }
      //                 else
      //                 this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2).subscribe((res:any)=>
      //                 {
      //        this.router.navigate(['../endOrder']);
      //         });
      //       }
      //        });
      //     }
      //    else 
      //   {
      //     if(this.order.IsDelivery==1)
      //     {
      //     this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
      //     {
      //           this.router.navigate(['../endOrder']);
      //          });
      //         }
      //           else
      //           this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2).subscribe((res:any)=>
      //           {
      //  this.router.navigate(['../endOrder']);
      //   }); 
      // }
        }
          });
        }
  });
  }

  local(){

    if(this.paymentBy==1)
    this.localPayment();
    else
    localStorage.paymentBy=this.paymentBy;
    localStorage.phone=this.paymentForm.value.nvPhone;
     localStorage.orderName=this.paymentForm.value.nvOrderName;
     localStorage.email=this.paymentForm.value.nvEmail;
     localStorage.street=this.paymentForm.value.nvStreet;
     localStorage.enter=this.paymentForm.value.nvEnter;
     localStorage.floor=this.paymentForm.value.nvfloor;
     localStorage.numberHoush=this.paymentForm.value.nvNumberHoush;
  }

  localPayment()
  {
    localStorage.paymentBy=this.paymentBy;
   // localStorage.nvCardNumber=this.paymentForm.value.nvCardNumber;
    //localStorage.cvv=this.paymentForm.value.cvv;
    //localStorage.identityNumber=this.paymentForm.value.identityNumber;
   // localStorage.nvCardholderName=this.paymentForm.value.nvCardholderName;
    var last2 = this.year.slice(-2);
    if(this.month.length==1)
    this.month="0"+this.month;
    this.date1=this.month+last2;
    this.date1=this.paymentForm.value.month+"/"+this.paymentForm.value.year;
     localStorage.date=this.date1;
     localStorage.IsCancel3DS = !this.isCreditSecure
  }



  localPaymentRemove()
  {
    // localStorage.removeItem('paymentBy')

    // localStorage.removeItem('nvCardNumber')
    // localStorage.removeItem('cvv')
    // localStorage.removeItem('identityNumber')
    // localStorage.removeItem('nvCardholderName')
    // localStorage.removeItem('date')
    // localStorage.removeItem('IsCancel3DS')
    // localStorage.removeItem('isClickPayment')
    // this.isClickPayment = false


  }

  openDialog(res : String) {
    localStorage.ADDRESS2=localStorage.ADDRESS;
    localStorage.LAT2=localStorage.LAT;
    localStorage.LONG2=localStorage.LONG;
   
    this.matDialog.closeAll();

    if (res == "NoSMS")
      {
        this.goToPayment(3)
        return
      }


    this.dialogRef = this.matDialog.open(VerificationCodeComponent, { disableClose: true });

    this.dialogRef.afterClosed().subscribe(result =>{ 
      console.log('The dialog was closed');
     
        this.goToPayment(result)

   } )
    // this.matDialog.afterAllClosed.subscribe(() => {
    //   console.log('All dialogs are closed.');
    //   this.localPaymentRemove()
      
// Your code here
    // });
    /*
       Subscribe to events emitted when the backdrop is clicked
       NOTE: Since we won't actually be using the `MouseEvent` event, we'll just use an underscore here
       See https://stackoverflow.com/a/41086381 for more info
    */
    // dialogRef.backdropClick().subscribe(() => {
    //   // Close the dialog
    //   dialogRef.close();
    // })
    
  }

  goToPayment(result : any)
  {
    if (result != null)
      {
switch (result){
// backPress 
case 1 : 
{
if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder')
  this.router.navigate(["../payment"])
  else
 this.router.navigate(["../securePayment"]);
};
 //openDialog
 case 2: 
 { 
 this.dialogRef  = this.matDialog.open(VerificationCodeComponent, {
   backdropClass: 'cdk-overlay-transparent-backdrop',
   hasBackdrop: false,
   disableClose:false
 });

 this.dialogRef.afterClosed().subscribe(result =>
  
  { 


  
    this.goToPayment(result)

} )
 break;
};

 //newPassword after OK of sms
 case 3: 
 { 
  this.myAdd = (this.Address != "") ? this.Address :this.order.Address 
 localStorage.isClickPayment=true;
 if(localStorage.paymentBy=="1")
 {   
 this.date1=localStorage.date;
     if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
     this.token=null;
   this.orderService.setOrderPayment(this.paymentForm.value.nvCardNumber,localStorage.date.replace("/",""),
   this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,Number(this.order.Balance),localStorage.IsCancel3DS).subscribe((res:any)=>
   {
    if(res.error==true)
    {
     this.showProgress=false;
     this.isClickPayment=false;
    this.matDialog.closeAll();
    
     this.proxy.showError(res.responseMessage,"");

     if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder')
      this.router.navigate(["../payment"])
      else
     this.router.navigate(["../securePayment"]);
    }
    else
   {
  
     if (res.responseMessage == "OK")
       this.afterCreditCart()
     else
     {
       this.matDialog.open(PopupCreditSecureComponent, {
         data: { linkForSecure: res.responseMessage },
         disableClose: true,
       });
       this.startTime = new Date()
       this.endTime = new Date();
       this.goToCreditSecure()

     }

   }
    });
 }
else 
{
 if(this.order.IsDelivery==1)
 {
 this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.myAdd,this.order.Lat+"",this.order.Lng+"").subscribe((res:any)=>
 {
   if(res.error==false )
   {
   this.router.navigate(['../endOrder']);
   this.noItem();
   }
   else
   this.proxy.showError("שגיאה בשליחת ההזמנה" + res.responseMessage,"");
      });
     }
       else
       this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2).subscribe((res:any)=>
       {
         if(res.error==false)
         {
         this.router.navigate(['../endOrder']);
         this.noItem();
         }
         else
         this.proxy.showError("שגיאה בשליחת ההזמנה"+ res.responseMessage,"");
}); 
}



}
  }
}
  }

  goToCreditSecure() {

    this.endTime = new Date()
    var endTimeCopy = new Date()
    if ( this.endTime.getTime() - this.startTime.getTime() < 1000 * 180 * 2) {
       

        this.orderService.check3DS(this.order.OrderID).subscribe((res: any) => {
          
          console.log(res)
          if (res.error) {

                this.handleError(res.responseMessage);

            } else {
                if (res.responseMessage === "Waiting") {
                 
                    setTimeout(() => {
                       this.goToCreditSecure();
                    }, 5000); 
                    // 5000 milliseconds = 5 seconds
                } else {
                    this.afterCreditCart();
                }
            }
        });
    } else {
        this.handleTimeout();
    }
}


private handleError(errorMessage: string) {
    this.showProgress = false;
    this.isClickPayment = false;
    this.matDialog.closeAll();
    this.proxy.showError(errorMessage, "");
    
}

private handleTimeout() {
    this.showProgress = false;
    this.isClickPayment = false;
    this.matDialog.closeAll();
    this.proxy.showError("Time out. Try again later", "");
}
  afterCreditCart()
  {
    if(this.order.IsDelivery==1)
      {
        this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.myAdd,this.order.Lat+"",this.order.Lng+"").subscribe((res:any)=>
        {
          
       
              this.router.navigate(['../endOrder']);
              this.noItem();

             });
     }
              else
              this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2).subscribe((res:any)=>
              {
                this.router.navigate(['../endOrder']);
                this.noItem();

      });
  }
  noItem()
  {
    let priceTotal="0 - פריטים";
    localStorage.PRICE=0;
    localStorage.PRICE_TOTAL=priceTotal;
    this.dataService.price.next(priceTotal);
  //  this.changePrice.emit();
    this.showProgress=false;
    this.matDialog.closeAll();
  }
  5()
  {
    this.showProgress=true
    if(this.paymentBy==1)
    {   
      var last2 = this.year.slice(-2);
      if(this.month.length==1)
      this.month="0"+this.month;
      this.date1=this.month+last2;
      this.date1=this.paymentForm.value.month+this.paymentForm.value.year;
        if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
        this.token=null;
      this.orderService.setOrderPayment(this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
      this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,Number(this.order.Balance) ,localStorage.IsCancel3DS).subscribe((res:any)=>
      {
       if(res.error==true)
       {
        this.showProgress=false;
        this.isClickPayment=false;
        this.proxy.showError(res.responseMessage,"");
       }
       else
      {
     
        if(this.order.IsDelivery==1)
        {
          this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
          {
                this.router.navigate(['../endOrder']);
               });
              }
                else
                this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2).subscribe((res:any)=>
                {
       this.router.navigate(['../endOrder']);
        });
      }
       });
    }
   else 
  {
    if(this.order.IsDelivery==1)
    {
    this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
    {
          this.router.navigate(['../endOrder']);
         });
        }
          else
          this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2).subscribe((res:any)=>
          {
 this.router.navigate(['../endOrder']);
  }); 
}
  }

  payment()
  {
    if(this.isClickPayment==false || localStorage.isClickPayment=="true")
    {
    this.isClickPayment=true;
    //console.log("payment");
    this.valid=true;
    if(this.paymentForm.valid==true)
    {
    if(this.isCreditPayment==true)
    {
    if(this.paymentForm.valid)
  //this.setUserCreditCard();
  this.getOrderDetails();
  else
  this.isClickPayment=false;
  }
  else if(this.isAnotherAddress)
  {
    if(this.paymentForm.valid)
 //this.setUserCreditCard();
 this.getOrderDetails();
  else
  this.isClickPayment=false
  }
  else
 //this.setUserCreditCard();
 this.getOrderDetails();
}
else
this.isClickPayment=false;
}
}

  backPress()
  {
  }
  
  creditPayment()
  {
    if(localStorage.DELIVERY==1)
    {
       this.createForm();
       this.place();
       this.IsDelivery=1;
    }
       else
       {
       this.createForm2();
       this.IsDelivery=0;
       }
       this.paymentBy=1;
this.isCreditPayment=true;
this.isCreditSecure = true
this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_On.png';
this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
  }

  cashPayment()
  {
    this.paymentBy=0;
this.isCreditPayment=false;
this.isCreditSecure = false
this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_On.png';
this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_Off.png';
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
this.createForm3();
  }

  paymentsBy(i)
  {
    this.createForm();
    this.place();
    this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
    this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_Off.png';
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
this.payments[i].imageNumber=1;
this.isCreditPayment=false;
 //if(i==1)
 this.paymentBy=1;
// {
//   if(this.isAnotherAddress==false)
//   this.router.navigate(['../giftCard']); 
//   else if(this.addressForm.valid)
//   this.router.navigate(['../giftCard']); 
// }
// else if(i==2)
// {
//   this.router.navigate(['../codeWorker']); 
// }
  }

//05/05/24 Naomi
  isCreditSecureClick()
  {
    this.isCreditSecure = !this.isCreditSecure
    this.isCreditSecureImg = this.isCreditSecure == true ?
     'assets/images/Pay_Page_Chack_Box_On.png' 
    : 'assets/images/Pay_Page_Chack_Box_Off.png'
  }
  anotherAddress()
  {
   this.isAnotherAddress=true;
   this.anotherAddressImg='assets/images/Pay_Page_Chack_Box_On.png';
   this.myAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  }

  myAddress()
  {
    this.isAnotherAddress=false;
    this.myAddressImg='assets/images/Pay_Page_Chack_Box_On.png'
    this.anotherAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.paymentForm.controls[fieldName].invalid ;
    // && (this.registerForm.controls[fieldName].dirty || this.registerForm.controls[fieldName].touched)
    ;
  }

  isValidInputPhone(fieldName): boolean {
    if(this.valid==true)
    {
     if(this.paymentForm.controls[fieldName].value == undefined || this.paymentForm.controls[fieldName].value == "")
    // return true;
     //this.errorPhone="שדה חובה"
    // else
    // this.errorPhone="עליך להכניס מספר טלפון שמתחיל בספרות  05xxxxxxxx";
   //else
    return this.paymentForm.controls[fieldName].invalid;
    }
  }

  isValidInputMail(fieldName): boolean {
    if(this.valid==true)
    {
    // if(this.paymentForm.controls[fieldName].value == undefined || this.paymentForm.controls[fieldName].value == "")
    // this.errorMail="שדה חובה"
    // else
    // this.errorMail="כתובת מייל אינה תקינה";
    return this.paymentForm.controls[fieldName].invalid;
    }
  }

  place()
  {
    this.citiesList = [
      {id: 1, name: "אילת"}
   ];

   var southWest = new google.maps.LatLng( 29.490725, 34.732479);
   var northEast = new google.maps.LatLng( 30.123217, 35.144384 );
   var bangaloreBounds = new google.maps.LatLngBounds( southWest, northEast );
    this.mapsAPILoader.load().then(() => { 
      var options = {
        componentRestrictions: {country: "IL"},
        bounds: bangaloreBounds,
        strictBounds: true ,
       }; 
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,options);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.lat = ""+(place.geometry.location.lat());
          this.lng =""+(place.geometry.location.lng());
          this.Address = place.name;
          this.AddressMe = (""+place.name);
          this.zoom = 12;
          this.isChangeAdress=1;
          this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            this.order=res;
            this.note=this.order.Note;
            if(this.order.Note==null || this.order.Note=='')
            this.note=null;
            this.isDelivery=0;
            // this.orderService.setOrderAddress(this.Address,this.lat,this.lng).subscribe((res:any)=>{
            //   if(res.responseMessage=="the delivery out of bounds")
            //   {
            //     this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
            //     this.addressForm.value.nvStreet="";
            //     this.searchElementRef=null;
            //   }
            //   else
            //   {
            //    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            //      this.order=res;
            //      if(this.order.IsDelivery==1)
            //      this.isDelivery=1;
            //      this.deliveryPrice=this.order.DeliveryPrice;
            // });
            this.orderService.setUpdateOrder(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,null,
              null,Number(this.order.TotalPrice),1,this.note,0,this.Address,this.lat,this.lng,null,null,null,null,null)
              .subscribe((res:any)=>
              {
           if(res.responseMessage=="the delivery out of bounds")
     {
       this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
       this.paymentForm.value.nvStreet="";
       this.searchElementRef=null;
     }
     else
     {
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
        if(this.order.IsDelivery==1)
        this.isDelivery=1;
        this.deliveryPrice=this.order.DeliveryPrice;
    });
  }});
});});});});
  }

  ngOnInit(): void {
 if(localStorage.DELIVERY==1)
 {
    this.createForm();
    this.place();
    this.IsDelivery=1;
 }
    else
    {
    this.createForm2();
    this.IsDelivery=0;
    }
    //     this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    //    this.order=res;  
    //    this.IsDelivery=this.order.IsDelivery;
    //    if(this.IsDelivery==0)
    //    this.createForm2();
    // });
    this.paymentBy=1;
    this.price2=localStorage.PRICE_GIFTCARD;
    this.selectedValue = null;
   this.isDelivery=0;

   if(this.isDelivery!=1)
   this.isAnotherAddress=false;
  else
  {
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
 if(this.order.IsDelivery==1)
  {
  if(Number(this.order.Balance)<Number(Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice)))
  this.isAnotherAddress=false;
  }
  else 
  {
  if(Number(this.order.Balance)<Number(this.order.TotalPrice))
  this.isAnotherAddress=false;
  }
});
  }
    // this.userService.getUserDetails(1).subscribe((res:any)=>{
    //   this.userEditProfile=res;
    //   this.myAddressText="("+this.userEditProfile.userAddress+")";
    // });
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      let price:number=0;
      for(let i=0;i<this.order.list.length;i++)
{
  price+=Number(this.order.list[i].Price);
  for(let j=0;j<this.order.list[i].listAddition.length;j++)
  for(let m=0;m<this.order.list[i].listAddition[j].listA.length;m++)
  price+=Number(this.order.list[i].listAddition[j].listA[m].Price);
}
if( this.price2==undefined || this.price2==0  || this.price2==Number(this.order.Balance))
{
      this.totalPrice=this.order.TotalPrice;
      this.byGiftCard=false;
}
     else
     {
       this.byGiftCard=true;
       let allPrice=Number(this.order.TotalPrice)
       if(this.order.IsDelivery==1)
       allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice)
      
       let iPay= Number(Number(allPrice)-Number(this.order.Balance));
       var number = Number(Number(allPrice)-Number(iPay));
      this.totalPrice=" הזמנה: " +allPrice +" ₪"+" שולם עד כה: "+Number(iPay)+" ₪"+" הסכום ליתרה: "+parseFloat(number.toFixed(2));
      localStorage.PRICE_GIFTCARD=0;
    }
      this.deliveryPrice=this.order.DeliveryPrice;
      this.bussinssService.getRestaurantDetails_(this.order.RestaurantID).subscribe((res:any)=>{
        this.restaurant=res;
      
          for(let i=0;i<this.restaurant.listSetting.length;i++)
        {
          if(this.restaurant.listSetting[i].Text=="מזומן")
          {
            if(this.restaurant.listSetting[i].Show==0)
            this.inCash=false;
          }
          if(this.restaurant.listSetting[i].Text=="אשראי")
          if(this.restaurant.listSetting[i].Show==0)
            this.onCredit=false;
          }
           
    });
  });

    this.payments=[
      // {
      //   "text":"תשלום באשראי",
      //   "imageNumber":1
      // },
      {
        "text":"תשלום ב Gift Card",
        "imageNumber":0
      },
      {
        "text":"תשלום קוד עובד",
        "imageNumber":0
      }
  ];
  this.dataService.show.next(false);
  this.changeShowPrice.emit();
  }}
