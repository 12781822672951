<div style="min-height: 580px;">
<div class="d-flex flex-wrap container" style="font-family: OpenSansHebrewRegular; margin-top: 50px;">
                    <div  *ngIf="levelDelivery==5" class="left"  style="text-align: center; width: auto; color:#41c777 ;margin-top: 80px; font-size: 50px; font-weight: bold;">
                        <div style="padding: 5px;">עלות המשלוח</div>
                     <br>
                        <div style="padding: 15px;">שביקשת תעמוד</div>
                       <br>
                        <div style=" padding: 15px;">על 25 ₪</div>

                        <div style="margin-top: 120px; position: absolute; right: 40%;" >
                            <div  class="left" style="font-family: OpenSansHebrewRegular;">                     
                                <div class="keep"  (click)="order()">
                                 <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                                 <span class="btn">הזמן</span>
                               </div>
                         </div>    
                         <!-- <div style="font-family: OpenSansHebrewRegular; position: absolute; right: 250px;" >                      
                            <div class="keep" (click)="cancel()">
                             <img src="assets/images/Delivery_Popup_Cancel_Button.png">               
                             <span class="btn" style="color: #41c777;">ביטול</span>
                           </div>
                        </div> -->
                           </div>  
                        </div>
                        <div  *ngIf="levelDelivery==1" class="left"  style="text-align: center; width: auto;color:#41c777 ;margin-top: 80px; font-size: 50px; font-weight: bold;">
                            <div >בקשת השליחות התקבלה</div>
                            <br>
                            <div style="padding: 15px;">ונציג יחזור אליכם</div>
                            <div style="padding: 15px;"> בדקות הקרובות...</div>
                            <div class="keep" style="left: 35%; margin-top: 150px;"  (click)="order()">
                                <img  src="assets/images/Delivery_Popup_Ok_Button.png">               
                                <span class="btn">אישור</span>
                              </div>
                            </div>
                            <div class="d-flex" style="width: 90%;" >
              <div class="col-md-4">
                   <img style="margin-top: 25px;"  src="assets/images/Delivery_Popup_Logo.png">
                </div>
              <div style="left: 25%; margin-top: 20px; position: absolute;" class="col-md-6">
                <form *ngIf="levelDelivery==0" style="display: inline-block; font-size: 15px;" class="form-signin" [formGroup]="deliveryForm">
                <br>
                <div>
                <div class="txt-input" >
                    <img class="align-self-center" src="assets/images/Delivery_Popup_Icon_1.png" style="padding-left: 8px;"/>

                    <div class="map-container" >
                        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                        </agm-map>
                      </div>
                      
                      <input #search id="pac-input"  [(ngModel)]="delivery.Origin" placeholder="כתובת איסוף" style="font-size: 15px; height: auto;" matInput formControlName="nvCollectionAddress" name="nvCollectionAddress"  [ngClass]="!isValidInput('nvCollectionAddress') ? 'input-street' : 'input-street-error' "  type="text" required>  
                    <!-- <input placeholder="כתובת איסוף" style="font-size: 15px; height: auto;" matInput formControlName="nvCollectionAddress"  [(ngModel)]="delivery.Origin" type="text" required/> -->
                </div>
                <br>
                <br>
                    <div class="txt"></div>
                    </div>  
                    <br>
                <div>
                  <div class="txt-input" >
                    <img class="align-self-center" src="assets/images/Delivery_Popup_Icon_1.png" style="padding-left: 8px;"/>

                    <div class="map-container" >
                        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                        </agm-map>
                      </div>
                      
                      <input #search2 id="pac-input"  [(ngModel)]="delivery.Destination" placeholder="כתובת לשילוח" style="font-size: 15px; height: auto;" matInput formControlName="nvShippingAddress" name="nvShippingAddress"  [ngClass]="!isValidInput('nvShippingAddress') ? 'input-street' : 'input-street-error' "  type="text" required>  
                    <!-- <input placeholder="כתובת איסוף" style="font-size: 15px; height: auto;" matInput formControlName="nvCollectionAddress"  [(ngModel)]="delivery.Origin" type="text" required/> -->
                </div>
                <!-- <div class="txt-input" >
                    <img class="align-self-center" src="assets/images/Delivery_Popup_Icon_2.png" style="padding-left: 6px;"/>
                    <input placeholder="כתובת לשילוח" style="font-size: 15px; height: auto;"  matInput formControlName="nvShippingAddress" [(ngModel)]="delivery.Destination" type="text" required/>
                </div> -->
                <br>
                <br>
                    <div class="txt"></div>
                    </div>  
                    <br>
                                   <div>
                                        <div class="txt-input" >
                                            <img class="align-self-center" style="margin-top: 5px;" src="assets/images/Delivery_Popup_Icon_3.png" style="padding-left: 18px;"/>
                                            <input value="שעת משלוח" style="font-size: 15px; height: auto; width: auto;" type="time" matInput formControlName="nvDeliveryTime" [(ngModel)]="delivery.DeliveryTime" required/>
                                          
                                
                                
                                        </div>
                                        <!-- <mat-datepicker #dp
                                        startView="year" style="font-size: 15px;"
                                        (yearSelected)="chosenYearHandler($event)"
                                        (monthSelected)="chosenMonthHandler($event, dp)"
                                        panelClass="example-month-picker"></mat-datepicker> -->
                                        <br>
                                        <br>
                                            <div class="txt"></div>
                                            </div>  
                                            <br>
                                    <div>
                                        <div class="txt-input" >
                                            <img class="align-self-center" src="assets/images/Delivery_Popup_Icon_4.png" style="padding-left: 12px;"/>
                                            <input style="font-size: 15px; height: auto;" placeholder="טלפון לברורים" minlength="9" maxlength="10" type="tel" [(ngModel)]="delivery.Phone"  matInput formControlName="nvPhoneInquiries" />
                                        </div>
                                        <br>
                                        <br>
                                            <div class="txt"></div>
                                            </div>  
                                            <br>
                                            <div>
                                                <div class="txt-input" style="width: 37%;" >
                                                    <img class="align-self-center" src="assets/images/Delivery_Popup_Icon_5.png" style="padding-left: 7px; margin-bottom: 45px;"/>
                                                    <textarea style="font-size: 15px; height: auto; font-weight: 100;" [(ngModel)]="delivery.Description"  formControlName="nvShippingDescription" type="text" mdInput placeholder="תאור המשלוח" rows="4" (input)="limitLines($event, 4)"></textarea>                      

                                                </div> 
                                                <br>
                                                <br>
                                                <br>
                                                <br>
                                                    <div class="txt"></div>
                                                    <span *ngIf="deliveryForm.valid==false"   style="color: #ff8585; display: flex; font-size: 13px;">* יש להזין את כל הפרטים</span>  
                                                    <!-- <div  style="font-family: OpenSansHebrewRegular;">                       -->
                                                        <div class="keep" style="left: 0;"  (click)="order()" [ngStyle]="deliveryForm.valid==true && {'margin-top':'12px'}">
                                                         <img src="assets/images/Delivery_Popup_Ok_Button.png">               
                                                         <span class="btn">הזמן</span>
                                                       </div>
                                                 <!-- </div>  -->
                                                </div>
                                                                                                   
                </form>
                           </div>  
                         </div>     
                           <!-- <div *ngIf="levelDelivery==1" >
                            <div  class="left" style="bottom: 48%; font-family: OpenSansHebrewRegular;">                      
                                <div class="keep"  (click)="order()" style="right: 2.5%;">
                                 <img (click)="order()" src="assets/images/Delivery_Popup_Ok_Button.png">               
                                 <span (click)="order()" class="btn">הזמן</span>
                               </div>
                         </div>    
                         <div class="left" style="font-family: OpenSansHebrewRegular; bottom: 48%; margin-left: 220px;" >                      
                            <div class="keep" (click)="cancel()">
                             <img src="assets/images/Delivery_Popup_Cancel_Button.png">               
                             <span class="btn" style="color: #41c777;">ביטול</span>
                           </div>
                        </div>
                           </div>                     -->
                    </div> 
                </div>


