import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { OrderHistoryComponent } from '../app/components/order-history/order-history.component'
import { AppComponent } from './app.component';
import { CategoryComponent } from './components/category/category.component';
import { ContactComponent } from './components/contact/contact.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { LoginComponent } from './components/login/login.component';
import { MainHomeComponent } from './components/main-home/main-home.component';
import { PasswordComponent } from './components/password/password.component';
import { PaymentInfoComponent } from './components/payment-info/payment-info.component';
import { RegisterComponent } from './components/register/register.component';
import { ReservedRestaurantsComponent } from './components/reserved-restaurants/reserved-restaurants.component';
import { RestaurantCategoryComponent } from './components/restaurant-category/restaurant-category.component';
import { RestaurantComponent } from './components/restaurant/restaurant.component';
import { ReviewComponent } from './components/review/review.component';
import { SearchComponent } from './components/search/search.component';
import { SecurePaymentComponent } from './components/secure-payment/secure-payment.component';
import { ShippingHistoryComponent } from './components/shipping-history/shipping-history.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { YourOrderComponent } from './components/your-order/your-order.component';
import { ToppingsComponent } from './components/toppings/toppings.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { GiftCardPaymentComponent } from './components/gift-card-payment/gift-card-payment.component';
import { LoginPhoneVarificationComponent } from './components/login-phone-varification/login-phone-varification.component';
import { GiftCardSucsessComponent } from './components/gift-card-sucsess/gift-card-sucsess.component';
import { CodeWorkerComponent } from './components/code-worker/code-worker.component';
import { EndOrderComponent } from './components/end-order/end-order.component';
import { MainListComponent } from './components/main-list/main-list.component';
import { MapComponent } from './components/map/map.component';
import { Topping2Component } from './components/topping2/topping2.component';

const routes: Routes = [
  { path: 'orderHistory', component: OrderHistoryComponent },
  { path: 'mainHome', component: MainHomeComponent },
  { path:'reservedRestaurants',component: ReservedRestaurantsComponent},
  { path:'shippingHistory',component: ShippingHistoryComponent},
  { path:'paymentInfo',component: PaymentInfoComponent},
  { path:'delivery',component: DeliveryComponent},
  { path:'contact',component: ContactComponent},
  { path:'register', component:RegisterComponent },
  { path:'restaurant',component:RestaurantComponent},
  { path:'yourOrder',component:YourOrderComponent},
  { path:'search',component:SearchComponent},
  { path:'password',component:PasswordComponent},
  { path:'login',component:LoginComponent},
  { path:'loginPhoneVarification',component:LoginPhoneVarificationComponent},
  { path:'review',component:ReviewComponent},
  { path:'securePayment',component:SecurePaymentComponent},
  { path:'category',component:CategoryComponent},
  { path:'termsUse',component:TermsOfUseComponent},
  { path:'restaurantCategory',component:RestaurantCategoryComponent},
  {path:'toppings',component:ToppingsComponent},
  {path:'toppings2',component:Topping2Component},
  {path:'giftCard',component:GiftCardComponent},
  {path:'giftCardPayment',component:GiftCardPaymentComponent},
  {path:'giftCardSucsess' ,component:GiftCardSucsessComponent},
  {path:'codeWorker' , component:CodeWorkerComponent},
  {path:'endOrder' ,component:EndOrderComponent},
  {path:'mainList' ,component:MainListComponent},
  {path:'map' ,component:MapComponent},

  { path: '', pathMatch: 'full', component: AppComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
