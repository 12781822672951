<div style="min-height: 580px;">
<!-- <div class="registerbtn_" style="background-image: url(assets/images/Pay_Page_Topbar.png); font-family: OpenSansHebrewRegular;">
<h1 (click)="backPress()" style="padding-top: 15px; text-align: center; color: white;" >Gift Card</h1>
</div> -->
<!-- <div style="width: 73%; display: flex; min-height: 410px;" class="card-desk">
      <div class="card-desk d-flex flex-wrap" style="justify-content: center;"> -->
        <div class="d-flex flex-wrap m-auto" style="position: relative;
        right: 90px; justify-content: center; font-family: OpenSansHebrewRegular; margin-top: 10px; width: 73%; direction: rtl; min-height: 410px;" class="card-desk">
          <div class="d-flex flex-wrap " style=" font-family: OpenSansHebrewRegular; direction: rtl;"
infinite-scroll
[infiniteScrollDistance]="scrollDistance"
[infiniteScrollUpDistance]="scrollUpDistance"
[infiniteScrollThrottle]="throttle"
(scrolled)="onScrollDown()"
(scrolledUp)="onUp()">
<div *ngFor="let g of giftCards" class="carousel-item-next" >
    <div (click)="selectCard(g)" class="keep align-self-center" style="direction: rtl; width: 73%;">
        <img style="width: 350px;" src="assets/images/Bali_Gift_Card@3x.png"> 
        <span class="barCode">{{g.Number}}</span>              
        <span class="btn">{{g.Price}} ₪</span>
      </div>
    </div>
      </div>
</div>
<div class=" d-flex" style="justify-content: center; direction: rtl; float: center;">
<div (click)="buyGiftCard()" class="keep align-self-center" style="justify-content: center; direction: rtl; float: center;">
  <span class="btn_" style="font-family: OpenSansHebrewBold">רכוש GIFT CARD</span>
    <img src="assets/images/Buy_Gift_Card_Button@3x.png">               
  </div>
</div>
</div>
