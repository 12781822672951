import { Component, OnInit ,Input} from '@angular/core';
import { Router } from '@angular/router';
import { HistoryDelivery } from 'src/app/models/order';
import { AppProxy } from 'src/app/services/app-proxy';

@Component({
  selector: 'app-history-delivery-item',
  templateUrl: './history-delivery-item.component.html',
  styleUrls: ['./history-delivery-item.component.css']
})
export class HistoryDeliveryItemComponent implements OnInit {

  require: any
  FileSaver = require('file-saver');
  @Input() orderItem:HistoryDelivery;
 
   constructor(private router: Router ,private proxy:AppProxy) { }
 
   ngOnInit(): void {}
 
   orderAgain()
   {
    // this.router.navigate(['../yourOrder']);
   }
 
   downloadPdf(pdfUrl: string, pdfName: string ) {
     //const pdfUrl = 'assets/sample.pdf';
     //const pdfName = 'your_pdf_file';
     if(this.orderItem.FileInvoice!=null)
     this.FileSaver.saveAs(this.orderItem.FileInvoice, pdfName);
     else
     this.proxy.showError("אין לך חשבונית להורדה","");
   }
   
 
}
