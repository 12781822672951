<div *ngIf="categoryType==1" class="d-flex" [ngClass]="indexList!=0 ? 'marginText' : null" style="font-family: OpenSansHebrewRegular;
  font-weight: bold;text-align: right; margin-top: 15px;cursor: pointer;" (click)="onClickCategory(1)">
    <div class="m-auto"  >
      <div class="image1">
        <div style="overflow:hidden ; height: 85%; position: relative;" >
          <img class="imgA1" style="height: 100%; width: 100% ; background-size: cover; background-size: 100% 100% ;background-repeat: no-repeat;  border-radius: 14px 14px 0px 0px;" [src]="categoryItem.ImageFile" >
          <img *ngIf="categoryItem.RstaurantID==0 && categoryType==1" (click)="addFevorit()" class="imgB1"  [src]="categoryItem.IsFevorite==0 ? fevorit : fevoritActive">
        </div>

    <div style="width: auto; font-size: 14px; margin-top: 5px;">
      <div style="white-space: nowrap; width: 300px; overflow: hidden; text-overflow: ellipsis;">
      <span style="font-size: 17px; font-weight: 550; margin-right: 13px; font-family: OpenSansHebrewBold;    margin-top: 4px;">{{categoryItem.Name}}</span>
      <span *ngIf="categoryItem.Category!=''" style=" margin-right: 4px; font-size: 14px; font-weight: 540; ">- {{categoryItem.Category}}</span>
    </div>
      <div *ngIf="categoryType==1" class="d-flex card-subtitle btn" style=" direction: rtl; position: relative; margin-top: 9px; display: flex; justify-content: flex-start;">
    <span style="font-size: 15px; margin-top: 4px; color: black;">{{categoryItem.Header}}</span>
    </div>
      </div>  
      <div>     
  </div>
  <div >
  </div> 
  </div>
      </div>
  </div>
  <div *ngIf="category.categoryType==2" class="d-flex" [ngClass]="indexList!=0 ? 'marginText' : null" style="cursor: pointer;  font-weight: bold; text-align: right; margin-top: 15px;" (click)="onClickCategory(2)">
    <div class="m-auto"  >
      <div class="image2" >
        <div style="overflow:hidden ; height: 80%;" >
      <img style="height: 100%; width: 100% ; background-size: cover; background-size: 100% 100% ;background-repeat: no-repeat;  border-radius: 10px 10px 0px 0px;" [src]="categoryItem.ImageFile" >
    </div>
        <div style="width: 100%;" class="card-deck d-block">
    <div style="margin-right: 25px; margin-top: 10px; font-family: OpenSansHebrewBold; font-size: 1.5rem;">{{categoryItem.Category}}</div>
    <div style="margin-top: 4px;  margin-right: 25px; font-size: 1.5rem; font-weight: 540;" >{{categoryItem.CountRate}} מקומות</div>
  </div>
  <div >
  </div> 
  </div>
      </div>
  </div>
  
  
      
  
