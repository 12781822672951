<div style="height :580px; position: relative; bottom: 50px;">
  <div style="display: flex; justify-content: center; position: relative;cursor: pointer;font-family: OpenSansHebrewRegular;
top: -15px;">
<div  style="background-repeat: no-repeat; height: 176px; width: 74.5%;">
    <img [src]="Image"  style="padding: 15px; height: 100% ;width: 100%;">
</div>
</div>

    <div class="d-flex" style="width: 85%;">
    <div style="width: 85%; direction: rtl;position: absolute;" class="d-flex">
      <div  style="height: auto; width: 880px; color:#373737; font-weight: bold; margin-top: 32px;
        float: center; min-height: 100px; margin-left: 80px;max-height: 360px;
    max-width: 1100px; height: auto; float: center; direction: rtl;
    border: 2px solid #e2e2e2; border-radius: 12px; box-shadow: inset 0 1px 1px #ededed;">
          <div style="color: #575658;font-size: 15px; text-align: center; margin-top: 3px;" [ngStyle]="positionPage==1 && {'margin-right':'200px'}"> {{foodName}}</div>
          <div style="width: 350px; max-height: 280px;" class="d-flex">
          <div *ngFor="let t of toppingsPage; let ind = index"  class="d-flex flex-column " [ngStyle]="ind==0 && {'margin-right':'10px'}">
              <span  style="font-size: 15px; text-align: center;">{{t.TypeTxt}}</span>
              <div style="overflow: auto;">
              <div *ngFor="let i of t.listSelect ; let in = index" style="font-size: 15px; "
               [ngStyle]="ind!=0 && {'margin-right':'15px'}"> 

          <mat-select  msInfiniteScroll *ngIf="i.listA.length>0 " (click)="clickList(ind,in,i.Text,$event)" 
             ng-options="item.name for item in i.listA" [(ngModel)]="i.Text"
             placeholder="ללא בחירה"  class="form-control col-form-label-lg" [ngStyle]="in==0 && {'margin-top':'10px'}" style="
          font-size: 14px; background-image: url(assets/images/Tosafot_Choice_Button.png);" #singleSelect>
           <cdk-virtual-scroll-viewport [itemSize]="48" [style.height.px]="ind==0 ? heightAdding : heightAdding2" style="direction: rtl ; overflow: scroll;">
            <mat-option class="col-form-label-lg" style="width: 100%; min-height: 50px; direction: rtl;
            text-align: center;" (click)="selectItem(ind,in,i.Text,t.Type,inde,t.TypeID)" *ngFor="let topping of i.listA; let inde=index" 
            [value]="topping.Food">  
              <span *ngIf="topping.Price==null || topping.Price==0" style="direction: rtl;
              overflow: hidden; text-overflow: ellipsis;" > {{topping.Food}}</span>
              <span *ngIf="topping.Price!=null && topping.Price!=0"  >{{topping.Food}} - {{topping.Price}}  ₪</span>
            </mat-option>
          </cdk-virtual-scroll-viewport>
          </mat-select>
                  </div>
                </div>
         </div>
        </div>
        <div class="d-flex" style="position: relative;">
        <div (click)="continue()" class="keep" style="margin-left: 35px; margin-bottom: 10px; margin-top: 10px; position: absolute; left: 0;">
          <img src="assets/images/Tosafot_Next_Button.png">               
          <span  style="color: white;
          font-size: 16px; position: absolute; left: 40%; top: 25%;">{{continued}}</span>
        </div> 

        <div (click)="back()" class="keep" style="margin-right: 10px; margin-bottom: 10px; margin-top: 10px; position: relative; right: 0;">
          <img src="assets/images/Tosafot_Next_Button.png">               
          <span  style="color: white;
          font-size: 16px; position: absolute; left:40%; top: 25%;">חזרה</span>
        </div> 
      </div>
      </div>

      <div >
        <div style="font-size: 18px; text-align: center; color: #373737;
        font-weight: bold;left: 10%; position: relative;"> {{foodName}}</div>
        <img  [src]="ImageFood!='' ? ImageFood : null"  style="margin-top: 10px; border-radius: 10px; position: relative;
        width: 90%; left: 12%; align-items: center; padding: 1px;"/>
        <div style="font-size: 14px; text-align: center; margin-top: 10px; color: #373737; white-space: pre-line; max-width: 350px;
        font-weight: bold;"> {{foodDescription}}</div>
      </div>
  </div>
</div>
</div>