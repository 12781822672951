import { Component, OnInit ,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-popup-giftcard',
  templateUrl: './popup-giftcard.component.html',
  styleUrls: ['./popup-giftcard.component.css']
})
export class PopupGiftcardComponent implements OnInit {
  price:number=20;
  price2:number=50;
  payBy="גיפט קארד";

  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) private data: {price: number,price2:number,payBy: string},private matDialog:MatDialog) { }

  ngOnInit(): void {
    this.price=this.data.price;
    this.price2=this.data.price2;
    this. payBy=this.data. payBy;
  }

  continue()
  {
    this.matDialog.closeAll();
    localStorage.PRICE_GIFTCARD=this.price2;
    localStorage.PRICE_TOTAL=this.price;
    this.router.navigate(['../securePayment']); 
  }

}
