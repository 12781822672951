import { Component, OnInit ,Input} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-header-green',
  templateUrl: './top-header-green.component.html',
  styleUrls: ['./top-header-green.component.css']
})
export class TopHeaderGreenComponent implements OnInit {
  @Input()customTitle: string;

  constructor(private router: Router) { }

  backPress()
  {
    window.history.back();
  }

  ngOnInit(): void {}
}
