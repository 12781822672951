import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { MyOrder } from 'src/app/models/order';
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { isUndefined } from 'util';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupGiftcardComponent } from '../popup-giftcard/popup-giftcard.component';

@Component({
  selector: 'app-code-worker',
  templateUrl: './code-worker.component.html',
  styleUrls: ['./code-worker.component.css']
})
export class CodeWorkerComponent implements OnInit {
  stars:number[]=[1,2,3,4,5];
  orderFinal:boolean=true;
codeWorker:string=null;
order:MyOrder;

  constructor(private matDialog:MatDialog,private router: Router ,private proxy:AppProxy,private orderService:OrdersService){}

  ngOnInit(): void {
  }

  star()
  {

  }

  continue()
  {
    if(this.codeWorker!="" && this.codeWorker!=null)
    {
      //this.proxy.showError("קוד שגוי","");
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
        let price=this.order.TotalPrice;
       // this.router.navigate(['../restaurantCategory']);
        if(this.order.OrderID!=0) 
        {
          this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            this.order=res;
            let BudgetSum=Number(this.order.BudgetSum);
            let status=1;
            let price=Number(this.order.TotalPrice);
            if(this.order.IsDelivery==1)
            price+=Number(this.order.DeliveryPrice)
           // let price=Number(this.order.Balance);
            if(BudgetSum>Number(price))
            status=2;
            if(BudgetSum>0)
            {
              this.orderService.setUpdateOrderEmploye(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,
                null,null,status,null,1,null,null,null,null,null,null,1,this.codeWorker).subscribe((res:any)=>{
                  localStorage.MENU_ID=0;
                  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
                    this.order=res;
                  //let price=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice);
                 // let price=Number(this.order.Balance)
                  if(BudgetSum>Number(price) || Number(this.order.Balance)<=0)
                  this.router.navigate(['../endOrder']);
                  else
                  {
                   
                    let iPay=Number(Number(price)-Number(this.order.Balance));
                  this.matDialog.open(PopupGiftcardComponent, {
                    data: { price: price ,price2:iPay , payBy:"קוד עובד"},
                });
              }});
          
          });
        }
          else
          this.proxy.showError("אין לך יתרה בקוד עובד","");
          });
         
        
          }
    });
  }
    else
    this.proxy.showError("חובה למלא קוד עובד","");
  }

  cancel(){
    window.history.back();
  }
}

