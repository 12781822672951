import { Component, OnInit ,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-big-image',
  templateUrl: './big-image.component.html',
  styleUrls: ['./big-image.component.css']
})
export class BigImageComponent implements OnInit {
  @Inject(MAT_DIALOG_DATA) public data: any
  url;

  constructor(private matDialog:MatDialog) { }

  ngOnInit(): void {
   // this.url=this.data;
  }


  close()
  {
    this.matDialog.closeAll();
  }
}
