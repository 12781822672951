<div style="min-height: 577px; position: relative; bottom: 50px;">
<div style="display: flex; justify-content: center; position: relative;cursor: pointer;font-family: OpenSansHebrewRegular;
top: -15px;">
<div  style="background-repeat: no-repeat; height: 176px; width: 74.5%;">
    <img [src]="Image"  style="padding: 15px; height: 100% ;width: 100%;">
</div>
</div>
<!-- <div style="display: flex; justify-content: center; position: relative;cursor: pointer;font-family: OpenSansHebrewRegular; top: -35px;">
<div (click)="payment()" class="priceTotal d-flex" style="z-index: 100; position: fixed; cursor: pointer; background-image: url(assets/images/Button__Green_On.png);">
  <span style="direction: rtl; font-size: 18px; padding-top: 8px; cursor: pointer;"  class="nav-link textHeader" >{{priceTotal}}</span>
</div> 
</div> -->
<div class="d-flex" style="justify-content: center; direction: rtl;">
<div style="width: 74.5%; ">

<div *ngIf="restaurantItem.length>0"  style="text-align: right; font-weight: bold; margin-top: -18px;">
  <div class="d-flex" style="direction: ltr; width: 98%;">
             <!-- <img style="height: 10%; margin-top: 4px;" (click)="addFevorit()" class="imgB1" [src]="restaurant.IsFevorite==0 ? fevorit : fevoritActive">  -->
<div  class="d-flex" style="direction: rtl; margin-left: 12px; align-items: center;">
      <!-- <div *ngFor="let s of Rate  let i = index" >
          <img src="assets/images/Star_Full_Small.png"/>
      </div> -->
      <span style="direction: rtl;
        font-weight: bold; font-size: large; font-family: OpenSansHebrewBold;">{{restaurant.Header}}</span>
    </div>
    <span *ngIf="restaurantItem.length>0" style="position: absolute; right: 14%; direction: rtl;
      margin-top: 5px; font-weight: bold; font-size: large; font-family: OpenSansHebrewBold;">{{restaurant.Name}} {{restaurant.Kashrut}}</span>
  </div>
  <img style="width: 96%; height: 1px; margin-right: 16px;  margin-top: 10px;" src="assets/images/Restorant_Page_Food_Big_Line.png">
  <br>
  <br>
  <!-- <span *ngIf="restaurantItem.length>0" style="margin-right: 22px; font-weight: bold; font-size: large; font-family: OpenSansHebrewBold;">אודות {{restaurant.Name}}</span> -->
  <div class="d-flex" style="direction: rtl; width: 100%; margin-bottom: 10px; flex-wrap: wrap;">
  <div  [ngStyle]="a.Show==1 && {'margin-right':'20px'}" *ngFor="let a of restaurant.listSetting ; let i = index">
      <img *ngIf="a.Show==1" class="align-text-top"  src="assets/images/Circle_On.png" style=" cursor: pointer;">
      <span *ngIf="a.Show==1" style="color: #41c777; margin-right:10px ;font-size: 14px;">{{a.Text}}</span>
</div>
  </div>
</div> 
</div>
</div>


<div class="d-flex">
<!-- <div *ngIf="restaurantItem.length>0" class="m-auto" style="width: 73%; text-align: right; font-weight: bold;">
    <div class="d-flex" style="direction: ltr; margin-top: 15px;">
        <!-- <div class="align-self-lg-center" style="margin-left: 10px; font-weight: bold;
        font-size: medium;">{{restaurant.Price} ₪</div> -->
               <!-- <img style="height: 90%; margin-top: 4px;" (click)="addFevorit()" class="imgB1"  [src]="restaurant.IsFevorite==0 ? fevorit : fevoritActive"> 
<div  class="d-flex" style="direction: rtl; margin-left: 12px; align-items: center;">
        <div *ngFor="let s of Rate  let i = index" >
            <img src="assets/images/Star_Full_Small.png"/>
        </div>
      </div> -->
      <!-- <div class="align-self-lg-center" style="margin-right: 10px;font-weight: bold;
      font-size: 15px;" >{{restaurant.Rate}}</div> -->
    <!-- </div>
    <img style="width: 100%; height: 1px;" src="assets/images/Restorant_Page_Food_Big_Line.png">
    <br>
    <span *ngIf="restaurantItem.length>0" style="font-weight: bold; font-size: medium; font-family: OpenSansHebrewBold;">אודות {{restaurant.Name}}</span>
    <div class="d-flex" style="direction: rtl; width: 100%; margin-top: 15px;">
    <div [ngStyle]="i!=0 && {'margin-right':'120px'}" *ngFor="let a of aboutRestaurant ; let i = index">
        <img *ngIf="a.isCheck==1" (click)="restaurantType(i)" src="assets/images/Circle_On.png" style=" cursor: pointer;">
        <span *ngIf="a.isCheck==1" style="color: #41c777; margin-right:10px ;font-size: 14px;">{{a.aboutRestaurantName}}</span>
</div>
    </div>
</div>  --> 
</div>

<div *ngIf="restaurantItem.length>0" class="border d-flex" style="left: 0; right: 0; width: 72.5% ; font-size: 15px; top: 7px;
position: relative; height: 170px; margin: auto; color: black;">
<span style="margin-right: 25px; margin-left: 25px; margin-top: 5px;
line-height: 25px; text-align: right;font-family: OpenSansHebrewRegular;" >{{restaurant.Text}}</span>
  <img *ngIf="restaurant.IsFreeShipping==1" src="assets/images/Delivery_Free_Stiker_Desktop_Web.png" style="position: absolute;
  top: 0; left: 0;">
    <div class="d-flex alert m-auto time col-12">
      <div class="col-11" style="text-align: right;">
    <img src="assets/images/Restorant_Page_Open_Icon.png">
      <span class="right_" style="margin-right: 10px;" >שעות פתיחה: {{dayTime}}</span>
    <!-- <br> -->
    <!-- <span style="margin-right: 10px;" >ו'-ש: 08:00 - 16:00 </span> -->
    <img class="addressIcon" style="margin-right: 40px;" src="assets/images/Restorant_Page_Address_Icon.png">
    <span class="address right_" style="margin-right: 10px; font-family: OpenSansHebrewRegular;" >{{restaurant.Address}}</span>
    </div>
    <span *ngIf="restaurant.MinimumOrder!=null && restaurant.MinimumOrder!=''" class="leftText float-xl-left" style="direction: rtl; margin-top: 5px;
          font-weight: bold; font-family: OpenSansHebrewBold;">מיני' {{restaurant.MinimumOrder}} ₪</span>
  </div>
</div>

<div class="d-flex" style="justify-content: center; direction: rtl;">
  <div class="d-flex" style="direction: rtl; width: 76.5%; margin-top: 10px; margin-bottom: 10px; flex-wrap: wrap;">

<div *ngFor="let c of categores; let i = index"  style="flex-wrap: wrap; font-family: OpenSansHebrewRegular; cursor: pointer; margin-left: 30px; margin-top: 20px; margin-bottom: 10px;">
  <div class="btn-group-vertical" style="cursor: pointer; font-size: 15px; margin-left: 130px; margin-right :30px">
<div (click)="category(i)"  *ngIf="c.isCheck==1"  style="cursor: pointer;" class="category">
<img (click)="category(i)" src="assets/images/Restorant_Page_Green_Button.png" style="cursor: pointer;">               
<span (click)="category(i)"  style="cursor: pointer;text-align: center;
width: 100%; text-align: center;" class="centered">{{c.Category}}</span>
</div>

<div (click)="category(i)" *ngIf="c.isCheck==0"   style="cursor: pointer;" class="category">
  <img (click)="category(i)" src="assets/images/Restorant_Page_White_Button.png" style="cursor: pointer;">               
  <span (click)="category(i)"  style="cursor: pointer;
 text-align: center;" class="centered" style="color: #41c777; text-align: center; width: 100%;">{{c.Category}}</span>
  </div>
</div>
</div>
</div>
</div>

<!-- <div  class="d-flex flex-wrap m-auto col-lg-offset-3" style="    justify-content: center; width: 73%; direction: rtl;"> -->
 
  <div class="d-flex flex-wrap m-auto" style=" font-family: OpenSansHebrewRegular; margin-top: 10px; width: 73%; direction: rtl;">
    <div class="d-flex flex-wrap" style="font-family: OpenSansHebrewRegular; direction: rtl;"
infinite-scroll
[infiniteScrollDistance]="scrollDistance"
[infiniteScrollUpDistance]="scrollUpDistance"
[infiniteScrollThrottle]="throttle"
(scrolled)="onScrollDown()"
(scrolledUp)="onUp()">
  <div  *ngFor="let c of restaurantItem; let i = index" style=" font-family: OpenSansHebrewRegular; margin-left: 20px;">
    <div class="border" style="margin-top: 40px;
    height: auto; width: 445.2px; color: #979797;">
    <div class="d-flex" style="direction: rtl;">
      <div  style=" height: 110px; width: 110px;
      background-size: 100% 100%; background-image: url(assets/images/Food_Box.png);">
     <img (click)="bigImage(i)"  [src]="c.Image!='' ? c.Image : null"  style="border-radius: 10px; padding: 1px; height: 100% ;width: 110px;"/>
</div>
      <div>
       
        </div>
          <div class="image0">
        <div>
          <div style="text-align: right; margin-right: 7px; width: auto;">
          <div class="d-flex m-auto" style="direction: rtl; font-weight: bold; color: black;">
            <span name="foodName" class="align-self-lg-center" style="margin-right: 8px; font-size: 16px;font-family: OpenSansHebrewBold;" ng-maxlength="5">{{c.Name}}</span>
            <div style="direction: rtl;" class="mr-lg-auto align-self-lg-center d-flex">
              <div *ngFor="let s of rate  let i = index" >
                <img src="assets/images/Star_Full_Small.png"/>
            </div>
          <!-- <div class="align-self-lg-center" style="margin-right: 3px; font-size: 14px; " >{{c.Rate}}</div> -->
          </div>
        </div>
      <div style=" margin-top: 8px; color: black; font-size: 14px; margin-right: 8px;" > {{c.MenuText}}  </div>

      <div style="white-space: pre-line; margin-top: 8px; color: black; font-size: 13px; margin-right: 8px; width: 300px;
      overflow: hidden; text-overflow: ellipsis;" > {{c.Description}}  </div>
      
        </div>   
      
    </div>
    
    </div> 
    </div>
    <div >
      <img style="width: 68%; height: 1px; position: absolute; left: 10px; margin-top: 2px;" src="assets/images/Big_Image_Line.png"> 
    </div>
    <div class="d-flex m-auto" style="direction: rtl; font-weight: bold; color: black;">
      <div  style="margin-right: 28px;
      margin-top: 20px; font-size: 20px; color :#41c777 ;" >{{c.Price}} ₪</div>
      <div style="direction: rtl;" class="mr-lg-auto">
        <img (click)="minus(i)" class="align-self-center"  src="assets/images/Restorant_Page_Minus_Button.png" style="cursor: pointer;"/>
        <span style="font-size: 14px;">{{c.Rate}}</span>
        <img (click)="plus(c.ID,i,c.Rate)" class="align-self-center"  src="assets/images/Restorant_Page_Plus_Button.png" style="cursor: pointer;"/>
    </div>
    </div>
    <div *ngIf="c.Rate>0">
       <div  *ngFor="let a of c.ChangeFood; let j = index" class="group" > 
      <div class="d-flex" style="direction: rtl; font-weight: bold; color: black; width: 100%; margin-top: 12px;">
        <span style="font-size: 13px; margin-right: 5px;">{{a.Name}}</span>
          <div *ngIf="a.Text!='' && a.Text!=null" (click)="changeOnFood(c.ID,i,j)" class="keep align-self-center" style="direction: rtl;">
            <img src="assets/images/Tosafot_Button.png" style="background-repeat: no-repeat;">               
            <span class="btn" style="color: white; font-size: 12px;">{{a.Text}}</span>
          </div>
        </div>
      </div>
      </div> 
    </div> 
      </div>
  </div>
  </div>
  </div>
  


