import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PasswordComponent } from '../password/password.component';
import { User, UserEditProfile } from 'src/app/models/user';
import { UserService} from '../../services/user-service';
import { DataService } from 'src/app/services/data.service';
import { AppProxy } from 'src/app/services/app-proxy';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public registerForm: FormGroup;
  user:User;
  valid:boolean=false;
  loginObj;
  userEditProfile:UserEditProfile;

   constructor( private router: Router,private proxy:AppProxy,private dataService:DataService,private matDialog:MatDialog,private userService: UserService) {}

   forgotPassword(){
    this.openDialog();
   }

  createAccount()
  {
    this.matDialog.closeAll();
    localStorage.ACCESS_TOKEN = "null"
    this.router.navigate(['../register']);
  }

  continue()
  {
    let name = this.registerForm.controls['userName'].value;
    let pass = this.registerForm.controls['userPassword'].value;
     this.userService.login(name,pass).subscribe((res:any)=>{
     this.matDialog.closeAll();
    //  this.userService.getUserDetails(1).subscribe((res:any)=>{
    //   this.userEditProfile=res;
    //   this.matDialog.closeAll();
    //   // this.userService.setUserPhone(this.userEditProfile.userPhone).subscribe((res:any)=>{
    //   // });
    //  });
     });
  }

  close()
  {
    this.dataService.state.next("התחבר");
      this.matDialog.closeAll();
  }

  openDialog() {
    this.matDialog.closeAll();
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PasswordComponent, dialogConfig);
  }

  createForm() {
    this.registerForm = new FormGroup({
      userName: new FormControl("", Validators.required),
      userPassword: new FormControl("", [Validators.required, Validators.minLength(8),Validators.maxLength(20)]), 
    });
  }

  ngOnInit(): void {
    this.user = new User();
    this.createForm();
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.registerForm.controls[fieldName].invalid;
  }
}
