import { Injectable } from '@angular/core';
import { AppProxy, HttpMethod } from "./app-proxy";
import { Router } from '@angular/router';
import { CreditCardObject } from '../models/creditCard';
import { Observable } from 'rxjs';

@Injectable()
export class CreditCardService {
  IsPaymentByGiftCard=false;

    //test
    terminal_id:string = "0963655";
    user = "israeli";
    password = "I!fr43s!34";
    cg_gateway_url = "https://cguat2.creditguard.co.il/xpo/Relay";

    constructor(private proxy: AppProxy,private router: Router) { }

    AuthorizationCreditCard(creditCardObject:CreditCardObject)
    {
         let date =  creditCardObject.card_validity.replace("/","");
         let postString = "<ashrait>" +
                "<request>" +
                "<command>doDeal</command>" +
                "<requestId></requestId>" +
                "<version>2000</version>" +
                "<language>" + "HEB" + "</language>" +
                "<mayBeDuplicate>0</mayBeDuplicate>" +
                "<doDeal>" +
                "<terminalNumber>" + this.terminal_id + "</terminalNumber>" +
                "<cardNo>" + creditCardObject.card_number.replace("-","") + "</cardNo>" +
                "<cardExpiration>" + date + "</cardExpiration>" +
                "<cvv>" + creditCardObject.bn_number + "</cvv>" +
                "<id>" + + "</id>" +
                "<transactionType>Debit</transactionType>" +
                "<creditType>RegularCredit</creditType>" +
                "<currency>ILS</currency>" +
                "<transactionCode>Phone</transactionCode>" +
                "<validation>Token</validation>" +
                "<dealerNumber></dealerNumber>" +
                "<user>" + this.user + "</user>" +
                "</doDeal>" +
                "</request>" +
                "</ashrait>";
                let url="https://cguat2.creditguard.co.il/xpo/Relay?user="+this.user+"&password="+
                this.password+"&int_in="+postString;
                return new Observable(observer => {
                    this.proxy.executePost<String>(url, HttpMethod.Post)
                      .subscribe(
                        result => {
                          let res: any = result;
                          if (res.error == false) {
                            let resList: string = res.list;
                              observer.next(resList);
                          }
                        },
                        error => console.log('oops', error));
                  })
    }
}