<div class="container">

    <agm-map #map [latitude]="lat_" [longitude]="long" [zoom]="zoom"(mapReady)="onMapReady($event)">
      <agm-marker  [latitude]="lat_" [longitude]="long" [iconUrl]="image"></agm-marker>
      <agm-polygon (polyClick)="onPolyClick($event)"  [visible]="true" [draggable]=options.draggable [strokeColor]="options.fillColor" [strokeWeight]="2" [fillColor]=options.fillColor  [fillOpacity]="0.3">
      </agm-polygon>
    </agm-map>
  
    <div class="d-flex">     
      <div class="map-container"  style="height: 50px;">
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
            <agm-marker [latitude]="lat_" [longitude]="long"></agm-marker>
          </agm-map>
        </div>   
        <input #search id="pac-input" name="address" [(ngModel)]="Address.nvStreet" matInput  class="input-street place"  type="text" required>       
    </div> 
             
      <span (click)="select()" class="select">בחר</span>
    <!-- <div id="map" ></div> -->
  
    </div> 