import { AppProxy } from "../services/app-proxy";
import { Injectable } from "@angular/core";
@Injectable()
export class Global_service {
    public static startPlayImediatly = false;
    public static dialog = null;
    public static lstAlert = [];
    constructor(private serv: AppProxy) {

    }


    // setReceiverType(nvType: string) {
    //     return this.serv.post("setReceiverType", { nvType: nvType })
    //         .then(res => {
    //             if (!res)
    //                 throw ("עדכון הרסיבר נכשל");


    //         })
    //         .catch(err => { Global_service.showMessage("הגישה לשרת נכשלה", "fail"); console.log("err-----", err); return false; })
    // }

  
    // ReadFromExcel(target: DataTransfer, callBack) {
    //     try {
    //         let file = target.files[0]

    //         if (file == undefined) throw new Error('Cannot use multiple files');

    //         const reader: FileReader = new FileReader();
    //         reader.onload = (e: any) => {
    //             const bstr: string = e.target.result;//תוכן הקובץ
    //             let workbook = XLSX.read(bstr, { type: 'binary', cellStyles: true });//המרה למשהו שהתוכנה יכולה לקרוא
    //             let headerNames: any = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
    //             let data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
    //             // var cell_ref = XLSX.utils.encode_cell({c:0, r:2});
    //             // console.log(XLSX);
    //             // xlsx__WEBPACK_IMPORTED_MODULE_1__.utils.sheet_to_html(workbook.Sheets[workbook.SheetNames[0]])
    //             callBack(data);
    //         };
    //         reader.readAsBinaryString(file);
    //     } catch (error) {
    //         Global_service.showMessage("טעינת הקובץ נכשלה", "fail");
    //         console.log(error);
    //     }



    // }

    // exportAsExcelFile(json: any[], excelFileName: string): void {
    //     const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //     const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    //     XLSX.writeFile(workbook, excelFileName + EXCEL_EXTENSION);
    // }

    downloadFile(url: string, name: string, type: string) {
        console.log(url);
        this.toDataUrl(url, function (base64) {
            let data = base64.split(',')[1];
            let binaryString = window.atob(data);
            let binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                let ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            data = bytes;

            let file = type ? new Blob([data], { type: type }) : new Blob([data]);
            let link = document.createElement('a');
            link.setAttribute('id', 'linkDownload');
            link.href = window.URL.createObjectURL(file);
            link.download = name + (type ? '.' + type : '');
            link.click();
            try {
                document.getElementById('linkDownload').remove();
            } catch (e) {
                //Global_service.showMessage("הורדת הקובץ נכשלה", "fail");
                console.log(e);
            }

        });
    }

    generateDownloadJsonUri(jsonData, fileName) {
        let sJson = JSON.stringify(jsonData, null, 2);
        let element = document.createElement('a');
        let file = new Blob([sJson], { type: 'json' });
        element.setAttribute('href', URL.createObjectURL(file));
        element.setAttribute('download', fileName + ".json");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click(); // simulate click
        try {
            document.body.removeChild(element);
            return Promise.resolve(true);

        } catch (err) {
            console.log(err);
        }


    }

    toDataUrl(url, callback) {
        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
            let reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    public static showMessage(message, type, autoClose = true) {

        // const dialogRef = Global_service.mydialogref.open(DialogOverviewExampleDialog, {
        //     width: '250px',
        //     data: {message: "message",type:type,autoClose:autoClose},
        //     hasBackdrop:true
        //   });

        Global_service.dialog = {};
        Global_service.dialog.message = message;
        Global_service.dialog.typeMessage = type;

        // console.log(message);
        if (autoClose)
            setTimeout(() => { Global_service.dialog = null }, 1700);
        //  return AppComponent.showMessage(message, type);
    }    
    
    public static showAlert(message, type, autoClose = true) {

        Global_service.lstAlert.push({
            message:message,
            type:type,
            
        })
        // if (autoClose)
        //     setTimeout(() => { Global_service.dialog = null }, 1700);
        
    }



}