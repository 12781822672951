<div *ngIf="categoryType==1" class="d-flex" [ngClass]="indexList!=0 ? 'marginText' : null" style="font-family: OpenSansHebrewRegular;
  font-weight: bold;text-align: right; margin-top: 15px;cursor: pointer;" (click)="onClickCategory(1)">
    <div class="m-auto"  >
      <div class="image1">
        <div style="overflow:hidden ; height: 85%; position: relative;" >
        
          <img class="imgA1" style="height: 100%; width: 100% ; background-size: cover; background-size: 100% 100% ;background-repeat: no-repeat;  border-radius: 14px 14px 0px 0px;" [src]="categoryItem.ImageFile" >
          <img *ngIf="categoryItem.IsFreeShipping==1" src="assets/images/Delivery_Free_Stiker_Desktop_Web.png" style="position: absolute;
          top: 0; left: 0;">
          <!-- <img *ngIf="categoryItem.RstaurantID==0 && categoryType==1" (click)="addFevorit()" class="imgB1"  [src]="categoryItem.IsFevorite==0 ? fevorit : fevoritActive"> -->

        </div>
      <!-- <img class="imgA1"  [src]="categoryItem.ImageFile"  style="cursor: pointer;border-radius: 14px 14px 0px 0px;"> -->
      <!-- <div style="width: auto; font-size: 13px; margin-right: 5px;">
        <div style="font-size: 16px; margin-right: 5px;font-family: OpenSansHebrewBold;">{{categoryItem.Name}}</div>
        <div style="margin-top: 4px; margin-right: 5px; font-size: 13px;" >{{categoryItem.Category}}</div>
        <img *ngIf="categoryType==1" style="margin-top: 2px; margin-right: 5px; width: 95%;
        height: 1px;" src="assets/images/Big_Image_Line.png">
        <div class="d-flex card-subtitle" style="direction: rtl; margin-bottom: 7px;">
            <div *ngFor="let s of Rate">
                <img src="assets/images/Star_Full_Small.png"/>
            </div>
      
        </div> 
    </div>     -->

    <div style="width: auto; font-size: 14px; margin-top: 5px;">
      
      <div class="d-flex textSize" style="white-space: nowrap;text-align: right; overflow: hidden; text-overflow: ellipsis;">
        <div class="col-8 d-flex">
         <span class="fontSize" style="font-size: 17px; font-weight: 550; font-family: OpenSansHebrewBold;">{{categoryItem.Name}}</span>
         <!-- <span class="categoryFontSize" *ngIf="categoryItem.Category!=null" style=" margin-right: 4px; font-size: 14px; font-weight: 540; ">- {{categoryItem.Category}}</span> -->
        </div>
         <span *ngIf="categoryItem.MinimumOrder!=null && categoryItem.MinimumOrder!=''" class="col-4" style="text-align: left; padding: 0px; left: 15px;
         font-family: OpenSansHebrewBold;">מיני' {{categoryItem.MinimumOrder}} ₪</span>
       </div>
      <div *ngIf="categoryType==1" class="d-flex card-subtitle btn" style=" direction: rtl; position: relative; margin-top: 9px; display: flex; justify-content: flex-start;">
        <!-- <div *ngFor="let s of Rate">
    
            <img src="assets/images/Star_Full_Small.png"/>
    </div> -->
    <span style="font-size: 15px; margin-top: 4px; color: black;">{{categoryItem.Header}}</span>
    </div>
   
      <!-- <img *ngIf="categoryType==1" style="margin-top: 2px; margin-right: 5px; width: 95%; height: 1px;" src="assets/images/Big_Image_Line.png"> -->
   
      </div>  
      <div>
          
  </div>
  <div >
  </div> 
  </div>
      </div>
  </div>
  <div *ngIf="category.categoryType==2" class="d-flex" [ngClass]="indexList!=0 ? 'marginText' : null" style="cursor: pointer;  font-weight: bold; text-align: right; margin-top: 15px;" (click)="onClickCategory(2)">
    <div class="m-auto"  >
      <div class="image2" >
        <div style="overflow:hidden ; height: 80%;" >
          <!-- <div> -->
      <img style="height: 100%; width: 100% ; background-size: cover; background-size: 100% 100% ;background-repeat: no-repeat;  border-radius: 10px 10px 0px 0px;" [src]="categoryItem.ImageFile" >
    
      <!-- </div> -->
    </div>
      <!-- <div > -->
        <div style="width: 100%;" class="card-deck d-block">
    <div style="margin-right: 25px; margin-top: 10px; font-family: OpenSansHebrewBold; font-size: 1.5rem;">{{categoryItem.Category}}</div>
  
    <div style="margin-top: 4px;  margin-right: 25px; font-size: 1.5rem; font-weight: 540;" >{{categoryItem.CountRate}} מקומות</div>
    <!-- </div>     -->
  </div>
  <div >
  </div> 
  </div>
      </div>
  </div>
  
  
      
  
