<div style="min-height: 580px;">
<div class="d-flex m-auto" style="direction: rtl;
width:73%; ">
<div  class="d-flex flex-fill bd-highlight">
    <img  class="align-self-center bd-highlight" style="margin-top: 5px; margin-right: 5px;" src="assets/images/Topbar_Icon.png"/>
    <span  class="align-self-center align-self-end"  style="color: black; font-size: 20px; text-align: right;
     font-weight: bold; margin-top: 10px;font-family: OpenSansHebrewBold">היסטורית המשלוחים שלך</span>
    <div class="txt-input align-self-end searchBox" style="background-image: url(assets/images/Search_Box_1.png);">
        <img (click)="filterItem(numberInput.value)" style="cursor: pointer; padding-right: 8px;" src="assets/images/Search_Button_1.png"/>
        <input #numberInput (keyup.enter)="filterItem(numberInput.value)" [(ngModel)]="text"  name="text"  style="    position: absolute;
        right: 30%; font-weight: normal; font-size: 13px; color: black; font-family: OpenSansHebrewRegular;" placeholder="חפש הזמנה"/>
    </div>
      <br>
      <br> 
  </div>
</div>
<div *ngIf="filteredItems.length>0" class="d-flex" style="margin-top: 25px;">
    <img class="m-auto" style="width: 72%; height: 1.5px; margin-left: 3px;
    left: 14%; position: absolute;"  src="assets/images/History_Line_1.png"/>
</div>
<div class="d-flex flex-wrap container" style="margin-top: 25px;">
    <div class="d-flex flex-wrap justtify-content-center"   style="width: 100%; margin-top: 20px;">
        <div style="width: 100%;" *ngFor="let o of filteredItems" >
            <app-history-delivery-item [orderItem]="o" style="width: 100%;"></app-history-delivery-item>
        </div>
    </div>
    </div>
</div>


