import { Component, OnInit ,Input ,HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, CategoryItem} from 'src/app/models/category';
import { ChangeFood} from 'src/app/models/restaurant'
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-main-list',
  templateUrl: './main-list.component.html',
  styleUrls: ['./main-list.component.css']
})
export class MainListComponent implements OnInit {
  @Input()
  items: CategoryItem[];
  buffer: CategoryItem[] = [];
  loading: boolean;
  restaurantsItem:CategoryItem[]=[];
  restaurantsItemAll:CategoryItem[]=[];
  category:Category;
  changeFood:ChangeFood[];
  id:number=-1;
  text:string;
  categoryName="";
  num=0;
  page:number=1;
  mber=1;
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  categoryId:number;
  categores:Category[]=[];
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.backPress();
  // }

  onScrollDown() {
    this.appendItems();
    this.direction = "down";
  }

  onUp() {
    this.sum += 20;
    this.prependItems();
    this.direction = "up";
  }

  prependItems() {
    this.addItems();
  }

  appendItems() {
    this.addItems();
  }

  addItems() {
    this.page=Number(this.page)+1;
    if(this.categoryName=="קטגוריות")
    {
      this.bussinssService.getCategoryList(this.page).subscribe((res:any[])=>{
        for(let i=0;i<res.length;i++)
        {
        this.restaurantsItem.push(res[i]);
        }
    });
    }
   else if(this.id>-1)
    {
      this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
        for(let i=0;i<res.length;i++)
        {
        this.restaurantsItem.push(res[i]);
        }
    });
  }
  else
  {
    this.bussinssService.getMenuBySearch(this.text,this.page).subscribe((res:any[])=>{
      for(let i=0;i<res.length;i++)
      {
      this.restaurantsItem.push(res[i]);
      }
    });
  }
  }

  constructor(private proxy:AppProxy, private route: ActivatedRoute,private dataService:DataService, private bussinssService:BussinssService) { }

  ngOnInit(): void {
    this.scrollToTop();
    this.page=1;
    if(localStorage.TEXT_SEARCH!=null)
     this.text=localStorage.TEXT_SEARCH;
     if(localStorage.ID!="0")
     this.id=localStorage.ID;
      if(this.text=="all")
     {
       if(this.bussinssService.restaurantCategoryList!=null && this.bussinssService.restaurantCategoryList.length>0)
       {
        this.restaurantsItem=this.bussinssService.restaurantCategoryList;
        this.categoryName=this.bussinssService.categoryName;
        let categoryType=3;
        if(this.categoryName=="קטגוריות")
        categoryType=2
        this.category={
          "Name":this.bussinssService.categoryName,
          "ID": 33,
          "categoryItems":this.restaurantsItem,
          "isCheck":true,
          "categoryType":categoryType
        }
     }
     else
     {
     if(this.bussinssService.categoryName=="מסעדות מומלצות") 
       {
     this.restaurantsItem=this.bussinssService.categores[3].categoryItems; 
     this.category=this.bussinssService.categores[3];
       }
     else if(this.bussinssService.categoryName=="קטגוריות")
     {
      this.restaurantsItem=this.bussinssService.categores[1].categoryItems;
      this.category=this.bussinssService.categores[1];
    }
      else if(this.bussinssService.categoryName=="מתוקים") 
      {
      this.restaurantsItem=this.bussinssService.categores[2].categoryItems; 
      this.category=this.bussinssService.categores[2];
      }
      else if(this.bussinssService.categoryName=="מסעדות") 
      {
      this.restaurantsItem=this.bussinssService.categores[3].categoryItems; 
      this.category=this.bussinssService.categores[3];
       }
       this.categoryName=this.bussinssService.categoryName;
       let categoryType=3;
       if(this.categoryName=="קטגוריות")
       categoryType=2
      }
    }
  
   else if(this.id>-1)
    {
    this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
      this.restaurantsItem=res;
      if(this.restaurantsItem!=null && this.restaurantsItem.length>0)
      {
      if(this.bussinssService.categoryName!=null)
      this.categoryName=this.bussinssService.categoryName;
      this.category={
        "Name": this.bussinssService.categoryName,
        "ID": 33,
        "categoryItems":this.restaurantsItem,
        "isCheck":true,
        "categoryType":3
      }
    }
    else
    this.proxy.showError("לא נמצאו עסקים בקטגוריה זו","");
    });
  }
  else{
    this.bussinssService.getMenuBySearch(this.text,this.page).subscribe((res:any[])=>{
      this.restaurantsItem=res;
      this.bussinssService.setGlobalSearch(this.text);
      if(this.bussinssService.categoryName!=null)
      this.categoryName=this.bussinssService.categoryName;
      this.category={
        "Name": this.categoryName,
        "ID": 33,
        "categoryItems":this.restaurantsItem,
        "isCheck":true,
        "categoryType":3
      }
    });
  }
  } 
  
  backPress()
  {
 window.history.back();
  }

  scrollToTop(): void {
    setTimeout(() => window.scroll(0, 0), 0);
  }

  onClickCategory(id:number){
   }
}
