import { Component, OnInit ,Inject, Output, EventEmitter } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/order-service';
import {MatDialog } from '@angular/material/dialog';
import { Router} from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-popup-order',
  templateUrl: './popup-order.component.html',
  styleUrls: ['./popup-order.component.css']
})
export class PopupOrderComponent implements OnInit {
  @Output()change: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) private data: {OrderID: number},private dataService: DataService, private matDialog:MatDialog,private orderService:OrdersService ,private router: Router) { }

  ngOnInit(): void {
  }

  cancel(){
    let a=location.hash;
    this.matDialog.closeAll();
this.orderService.setUpdateOrder(this.data.OrderID,0,0,0,1,
 null,null,0,null,1,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
  if(a=="#/restaurant")
 {
  this.dataService.orderOpen.next(true);
  this.change.emit();
  this.router.navigate(['restaurant']);
 }
  else
this.router.navigate(['../restaurant']);
     });
  }

  end()
  {
    this.matDialog.closeAll();
    //localStorage.ID=localStorage.ID_CATEGORY;
    this.router.navigate(['../yourOrder']);
  }

  close()
  {
    this.matDialog.closeAll();
  }
}
