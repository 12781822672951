<div class="wrapper" style="font-family: OpenSansHebrewBold">
    <form *ngIf="getNewPassword==false" class="form-signin"  [formGroup]="newPasswordForm"> 
        <div style="text-align: center; margin-top: 40px; font-size: 18px;">
      <span > .sms הזן את הקוד שנשלח אליך ב</span>
      <br>
      <span >הקוד פג תוקף תוך 30 שניות </span>
      </div>
      <div class="d-flex" style="margin-top: 90px;">
        <div style="width: 35px; margin-left: 42px;" >
            <div class="txt-input" style="width: 35px; align-items: center;" >
                <input #code1 type="text" maxlength="1"  formControlName="code1" style="max-width: 35px;margin-top: 5px;font-size: 16px;
                font-weight: normal; text-align: center;" required>
            </div>
            <br>
            <br>
                <div class="txt" style="width: 35px;"></div>
                </div>
                <div style="width: 35px; margin-left: 5%;" >
                    <div class="txt-input" style="width: 35px;">
                        <input #code2 maxlength="1"
                         type="text"  formControlName="code2" style="margin-top: 5px;font-size: 16px;
                        font-weight: normal; text-align: center;" required>
                    </div>
                    <br>
                    <br>
                        <div class="txt" style="width: 35px;"></div>
                        </div>
                        <div style="width:35px; margin-left: 5%;" >
                            <div class="txt-input" style="width: 35px;" >
                                <input maxlength="1" #code3 type="text"  formControlName="code3" style="margin-top: 5px;font-size: 16px;
                                font-weight: normal; text-align: center;" required>
                            </div>
                            <br>
                            <br>
                                <div class="txt" style="width:35px;"></div>
                                </div>
                                <div style="width: 35px; margin-left: 5%;" >
                <div class="txt-input" style="width: 35px;" >
                    <input  type="text"  #code4 maxlength="1" formControlName="code4" style="margin-top: 5px;font-size: 16px;
                    font-weight: normal; text-align: center;" required>
                </div>
                <br>
                <br>
                <div class="txt" style="width: 35px;"></div>
                </div>
            </div>
                <br> 
                <img (click)="reSendSmsCode()" style="position: absolute; left: 43%; margin-top: 5px;" src="assets/images/Left_Arrow.png">
                        <span (click)="reSendSmsCode()" style="color: #334a3c; margin-right: 15px;
                        font-size: 15px;  position: absolute; left: 44%; cursor: pointer;" ><u>{{notGetSendAgain}}</u></span>
                  <br>
                  <br>
                    <div (click)="newPassword()" class="btm" style="background-image: url(assets/images/Signin_Button_1.png);cursor: pointer; margin-top: 25%; cursor: pointer">
                      <p (click)="newPassword()" class="p" style="cursor: pointer; color: #29c066; cursor: pointer; font-size: 14px;" [class.isDisabled]="newPasswordForm.valid">שלח</p>
                    </div>          
    </form>
    </div>
