<div class="container">
                    <div class="body" style="font-family: OpenSansHebrewBold">
                        <img class="align-baseline"  src="assets/images/Popup_Logo.png">
                        <h1 style="color: #41c777; margin-top: 10px;">
                             מדיניות פרטיות ותקנון
                        </h1>
                        <iframe style="height: 205px ; width: 100%;" width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
                      <div  (click)="backPress()" class="button" style="cursor: pointer;">הבנתי אני מאשר</div>
                    </div>
                  </div>

