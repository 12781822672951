import { Component, OnInit } from '@angular/core';
import { Payments } from 'src/app/models/payments';
import { FormGroup ,FormControl ,Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import {MatDatepicker} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { OrdersService } from 'src/app/services/order-service';
import { Payment, UserEditProfile } from 'src/app/models/user';
import { AppProxy } from 'src/app/services/app-proxy';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-gift-card-payment',
  templateUrl: './gift-card-payment.component.html',
  styleUrls: ['./gift-card-payment.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class GiftCardPaymentComponent implements OnInit {
  forFriendImg='assets/images/Pay_Page_Chack_Box_On.png';
  forMeImg='assets/images/Pay_Page_Chack_Box_Off.png';
  isForFriend:boolean=true;
  payments:Payments[];
  paymentForm:FormGroup;
  valid:boolean=false;
  isKeepCard=true;
  sum: number;
  phone:string;
  paymentCardNumber=3;
  keepCardImg='assets/images/Pay_Page_Chack_Box_On.png';
  date = new FormControl(moment());
  userEditProfile:UserEditProfile;
  month:string="";
  year:string="";
  date1:string="";
  token:string=null;
  methodsPayment:Payment=new Payment;

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.year=""+normalizedYear.year();
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.month=""+Number(normalizedMonth.month()+1);
    datepicker.close();
  }

  constructor(private router: Router,private proxy:AppProxy,private userService:UserService) { }

  ngOnInit(): void {
    this.scrollToTop();
    this.createForm();
    // this.userService.getUserCreditCard().subscribe((res:any)=>{
    //   if(res!=null)
    //   {
    // this.methodsPayment=res;
    // this.token=this.methodsPayment.card_token;
    // this.methodsPayment.card_cvv=null;
    // // this.date1= this.methodsPayment.CardDate.replace("/","");;
    // // this.methodsPayment.CardDate = this.methodsPayment.CardDate.slice(0, 2) + "/" + this.methodsPayment.CardDate.slice(2);
    
    // // //this.date1=this.payment.CardDate;
    // // this.date = new FormControl(moment());
    // // this.date = new FormControl(moment(this.methodsPayment.CardDate));

    // this.date = new FormControl(moment(this.methodsPayment.CardDate,"MM/YY"));
    // this.methodsPayment.CardDate = this.methodsPayment.CardDate.slice(0, 2) + "/" + this.methodsPayment.CardDate.slice(2);
    // this.date1=this.methodsPayment.CardDate;
    //   }
    //   else
    //   this.date1="";
    
    // });
  }

  scrollToTop(): void {
    setTimeout(() => window.scroll(0, 0), 0);
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.paymentForm.controls[fieldName].invalid;
  }
  
  forFriend(){
    this.isForFriend=true;
    this.forFriendImg='assets/images/Pay_Page_Chack_Box_On.png';
    this.forMeImg='assets/images/Pay_Page_Chack_Box_Off.png';
    this.paymentCardNumber=3;
  }

  forMe()
  {
    this.isForFriend=false;
    this.forMeImg='assets/images/Pay_Page_Chack_Box_On.png';
    this.forFriendImg='assets/images/Pay_Page_Chack_Box_Off.png';
    this.paymentCardNumber=2;
  }

  backPress()
  {
    window.history.back();
  }

  keepCard(){
    if(this.isKeepCard==true)
    {
      this.isKeepCard=false;
      this.keepCardImg='assets/images/Pay_Page_Chack_Box_Off.png'; 
    }
    else
    {
      this.isKeepCard=true;
      this.keepCardImg='assets/images/Pay_Page_Chack_Box_On.png'; 
    }
      }

      createForm() {
        this.paymentForm = new FormGroup({
          nvCardholderName: new FormControl("", Validators.required),
          nvCardNumber: new FormControl("", Validators.required),
          date: new FormControl(""),
           cvv: new FormControl("",[Validators.required, Validators.minLength(3), Validators.maxLength(3)]),
           identityNumber: new FormControl("",[Validators.required, Validators.minLength(9), Validators.maxLength(9)])
        });
      }
    

      payment()
      {
        if(localStorage.SMS=="")
       this.proxy.showError("חובה להכניס קוד","");
       else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
        this.router.navigate(['../mainHome']);
        else
        {
        this.valid=true;
        if(this.paymentForm.valid)
        {
          if(this.date1=="")
          {
          var last2 = this.year.slice(-2);
          if(this.month.length==1)
          this.month="0"+this.month;
          this.date1=this.month+last2;
          //this.date1=this.paymentForm.value.date.replace("/","");
          }
        //   this.userService.setUserCreditCard(true,this.proxy.md5_(this.proxy.SECRET_KEY+localStorage.USER_ID),this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
        //   this.paymentForm.value.cvv,this.paymentForm.value.identityNumber, this.paymentForm.value.nvCardholderName,this.token,this.isKeepCard,true,localStorage.PRICE_TOTAL).subscribe((res:any)=>{
          
        // let phone=null;
        // if(this.phone!=null && this.phone!=undefined)
        // {
        //    phone=this.phone;
        //    this.userService.setGiftCard(this.sum,phone);
        // }
        //    if(phone==null)
        //    {
        //     this.userService.getUserDetails(1).subscribe((res:any)=>{
        //       this.userEditProfile=res;
        //       this.userService.setGiftCard(this.sum,this.userEditProfile.userPhone);
        //    });
        //   }
        
        // });
        }}
        //this.router.navigate(['../delivery']);   
      }
}
