<div style="min-height: 580px;">
    <div class="d-flex" style="margin-top: 10px; font-family: OpenSansHebrewBold">
    <div class=" d-flex m-auto " style="width: 74.5%; direction: rtl; font-weight: bold; font-size: 18px;">
        <span class="col-6" style="text-align: right;" >ההזמנה שלך</span>
        <span  class="col-6" >סה"כ {{totalPrice}} ₪</span>
    </div>
    </div>
    <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex" style="margin-top: 10px; font-family: OpenSansHebrewBold">
    <div class=" d-flex m-auto " style="width: 74.5%; direction: rtl; font-weight: bold; font-size: 18px;">
        <span class="col-6" style="text-align: right;" >דמי משלוח</span>
        <span  class="col-6" >{{deliveryPrice}} ₪</span>
    </div>
    </div>
    <div class="d-flex"  style="margin-top: 10px; margin-bottom: 10px;" >
        <img class="m-auto" style="width: 73%; " src="assets/images/Pay_Page_Line.png">
    </div>
    <!-- <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto " style="width: 57.5%; direction: rtl; text-align: right;font-family: OpenSansHebrewBold">
            <div class="col-5" style="font-size: 14px;">
                <img src="assets/images/Pay_Page_Number_Box_Green.png" />
                <div class="caption post-content">
                    <span style="color: white;">1</span>
                </div>
                <span style="font-weight: bold;">משלוח לכתובת שלי</span>
                </div>
        <div class="align-self-center">
            <img (click)="myAddress()" style="cursor: pointer;background-size: contain; " [src]="myAddressImg">
        </div>
    </div> -->

    <!-- <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto " style="font-family: OpenSansHebrewBold; width: 59%; direction: rtl; text-align: right;">
        <div style="font-size: 14px; padding-right: 6.5rem !important;">
           
            <span style="font-weight: bold;">{{myAddressText}}</span>
            </div>
    </div> -->
    <!-- <div *ngIf="isDelivery==1 && byGiftCard==false" class="d-flex m-auto " style="font-family: OpenSansHebrewBold; width: 59%; direction: rtl; text-align: right;padding: .35rem 0rem;">
        <div class="col-5"  style="font-size: 14px; padding-right: 6.5rem !important;"> 
            <span style="font-weight: bold;">משלוח לכתובת אחרת</span>
            </div>
    <div>
        <img (click)="anotherAddress()" style="cursor: pointer;background-size: contain;" [src]="anotherAddressImg">
    </div>
    </div>
    <div *ngIf="isAnotherAddress && byGiftCard==false" class="container" style="margin-bottom: 5px; font-size: 14px;font-family: OpenSansHebrewRegular">
        <form class="form-signin" [formGroup]="addressForm" >
        <div class="bg-img">
            <div >
           <div class="d-flex">     
    <span style="padding-right: 2px;">רחוב כולל מספר בית</span>
           </div>
    <div class="d-flex">     
        <div class="map-container" >
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>   
          <input #search id="pac-input" style="margin-top: 5px;"  [(ngModel)]="address.nvStreet" matInput formControlName="street" name="nvStreet"  [ngClass]="!isValidInputAddress('street') ? 'input-street' : 'input-street-error' "  type="text" required>       
    </div> 

    </div>
        <div>
        </div>
        <div>
            <div class="d-flex" style="margin-bottom: 5px;">
                <span class="col-4" style="padding-right: 2px;">כניסה</span>
                <span class="col-4" >קומה</span>
                <span class="col-4">דירה</span>
                </div>
                <div class="d-flex">
                    <input  matInput formControlName="nvEntry" style="margin-left: 25px;"   class="input-street"  type="text">
                    <input  matInput formControlName="nvfloor" style="margin-left: 25px;" class="input-street" type="number">
                    <input matInput formControlName="nvNumberHoush" class="input-street"  type="number">     
                </div>
                </div>
                <div class="settings_list"></div>
    </div> 
    </form >
    </div> -->
   
    <div class="container" style="height: 40%; font-size: 14px; font-family: OpenSansHebrewRegular">
        <div *ngIf="byGiftCard==false && inCash==true"   class="d-flex m-auto" style="direction: rtl; text-align: right; font-family: OpenSansHebrewBold">
            <div class="col-5" style="font-size: 14px;">
                <!-- <div class="caption post-content">
                    <span style="color: white;">1</span>
                </div> -->
                <span  style="font-weight: bold;">תשלום במזומן</span>
                </div>
        <div  class="align-self-center">
            <img (click)="cashPayment()" style="cursor: pointer;background-size: contain;" [src]="cashPaymentImg">
        </div>
        </div>
       
        <div *ngIf="onCredit==true"  class="d-flex" style="direction: rtl; text-align: right; font-family: OpenSansHebrewBold ; margin-top: 10px;">
            <div class="col-5" style="font-size: 14px;"> 
                <span style="font-weight: bold; " >תשלום באשראי</span>
                </div>
        <div  class="align-self-center">
            <img (click)="creditPayment()" style="cursor: pointer;background-size: contain;" [src]="paymentCreditImg">
        </div>
       
        </div>

        <!-- <div *ngFor="let p of payments ; let ind = index ">
            <div class="d-flex m-auto " style="direction: rtl; text-align: right;padding: .30rem 0rem; font-family: OpenSansHebrewBold">
                <div class="col-5" style="font-size: 14px; padding-right: 5rem !important;">  
                    <span style="font-weight: bold;">{{p.text}}</span>
                    </div>
                <div class="align-self-center">
                <img (click)="paymentsBy(ind)" style="cursor: pointer;background-size: contain;" [src]="p.imageNumber==1 ? paymentsOn : paymentsOff">
                </div>
                </div>
        </div> -->
        <mat-spinner *ngIf="showProgress==true" class="mat-spinner-color" style=" justify-content: center; 
        align-items: center; margin:0 auto;" [diameter]="70" mode="indeterminate"></mat-spinner>
        <div>
           
        <form  class="form-signin" [formGroup]="paymentForm" style="font-family: OpenSansHebrewRegular; margin-right: 3px;">
    <div class="bg-img d-flex">
        <div *ngIf="isCreditPayment==true" class="col-6" style="margin-right: 7px;">
        <div >
       <div class="d-flex">     
    <span style="margin-bottom: 5px; padding-right: 2px;">שם בעל הכרטיס</span>
       </div>
       <div class="d-flex">
        <input autocomplete="off"  matInput formControlName="nvCardholderName" [ngClass]="!isValidInput('nvCardholderName') ? 'input-street' : 'input-street-error' " type="text" required>
    </div>
    </div>
    <div>
    <div class="d-flex" style="margin-bottom: 5px; margin-top: 5px;">
    <span class="col-6" style=" padding-right: 2px;">מספר הכרטיס</span>
    </div>
    
    <div class="d-flex"> 
        <input autocomplete="off"  matInput formControlName="nvCardNumber" [ngClass]=" !isValidInput('nvCardNumber') ? 'input-street' : 'input-street-error' " required>
    </div>
    </div>
    <div style="margin-top: 5px;">
        <div class="d-flex">
            <span class="col-6" style="padding-right: 2px;">תוקף</span>
            <span>cvv</span>
            </div>
            <!-- <div style="display: flex">   
                <mat-form-field appearance="outline" style="height: 40px !important;">
                    <input autocomplete="off" matInput [matDatepicker]="dp" (click)="dp.open()" class="box2" [formControl]="date"  >
                
                    <mat-datepicker #dp
                                    startView="multi-year"
                                    (yearSelected)="chosenYearHandler($event)"
                                    (monthSelected)="chosenMonthHandler($event, dp)"
                                    panelClass="example-month-picker">
                    </mat-datepicker>
                  </mat-form-field>
                  <input class="box1" style="margin-right: 3px;" maxlength="3" autocomplete="off" matInput name="methodsPayment.card_cvv" [ngClass]="!isValidInput('cvv') ? 'box2' : 'box2Error' "  required formControlName="cvv"/> 
                </div>                          -->

                <div style="display: flex">   
                    <div class="d-flex">
                        <input #year inputmode="numeric" pattern="[0-9]*" type="tel" placeholder="yy" style="margin-right: 3px;" (click)="clearYear()"  
                     [ngClass]=" !isValidInput('year') ? 'box2' : 'box2Error' " formControlName="year"/> 
                      <input #month inputmode="numeric" pattern="[0-9]*" type="tel" placeholder="MM" style="margin-right: 3px;"
                      [ngClass]=" !isValidInput('month') ? 'box2' : 'box2Error' " formControlName="month" (click)="clearMonth()"/> 
                      </div>
                      <input #cvv inputmode="numeric" pattern="[0-9]*" type="tel" style="margin-right: 3px;" 
                      name="methodsPayment.card_cvv" [ngClass]=" !isValidInput('cvv') ? 'box2' : 'box2Error' " required formControlName="cvv"/> 
                    </div> 
</div>
<div class="d-flex">     
<span style="margin-bottom: 5px; padding-right: 2px;">מספר תעודת זהות של בעל הכרטיס</span>
   </div>
   <div class="d-flex">
    <input #identity autocomplete="off"  matInput formControlName="identityNumber" [ngClass]="!isValidInput('identityNumber') ? 'input-street' : 'input-street-error' " type="text" required>
</div>  
   
    </div>
    <br>
    <span *ngIf="paymentForm.valid==false"  style="color: #ff8585; bottom: -40px; position: absolute;">יש להזין את כל פרטי החובה</span>
    <div class="col-6" style="margin-right: 7px;">
        <div >
       <div class="d-flex">     
    <span style="margin-bottom: 5px; padding-right: 2px;">שם המזמין</span>
       </div>
       <div class="d-flex">
        <input matInput formControlName="nvOrderName" [ngClass]="!isValidInput('nvOrderName') ? 'input-street' : 'input-street-error' " required>
    </div>
    </div>
    <div *ngIf="IsDelivery==1">
    <div  class="d-flex" style="margin-bottom: 5px;
    margin-top: 5px;">
    <span class="col-6" style=" padding-right: 2px;">כתובת</span>
    </div>
  
    
    <div class="d-flex">     
        <div class="map-container" >
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>   
          <input *ngIf="IsDelivery==1" #search id="pac-input" matInput formControlName="nvStreet" [ngClass]="!isValidInput('nvStreet') ? 'input-street' : 'input-street-error' " required> 
          <input *ngIf="IsDelivery==0" #search id="pac-input" matInput formControlName="nvStreet" class="input-street">             
        </div> 
    </div>
    <div *ngIf="IsDelivery==1">
        <div class="d-flex" style="margin-bottom: 5px; margin-top: 5px;">
            <span class="col-4" style="padding-right: 2px;">כניסה</span>
            <span class="col-4">קומה</span>
            <span class="col-4">דירה</span>
            </div>
            <div class="d-flex">
                <input  matInput formControlName="nvEntry" style="margin-left: 25px;"   class="input-street"  type="text">
                <input  matInput formControlName="nvfloor" style="margin-left: 25px;" class="input-street" type="number">
                <input matInput formControlName="nvNumberHoush" class="input-street"  type="number">     
            </div>
            </div>
    <div class="d-flex" style="margin-top: 5px;">     
        <span style="margin-bottom: 5px; padding-right: 2px;">טלפון</span>
           </div>
           <div class="d-flex">

            <input matInput minlength="10" maxlength="10" formControlName="nvPhone"[ngClass]="!isValidInput('nvPhone') ? 'input-street' : 'input-street-error'" type="tel" required >
        </div>
<div class="d-flex" style="margin-top: 5px;">     
<span style="margin-bottom: 5px; padding-right: 2px;">אימייל</span>
   </div>
   <div class="d-flex">
    <input matInput formControlName="nvEmail" [ngClass]="!isValidInput('nvEmail') ? 'input-street' : 'input-street-error' " type="email">
</div>  

<div *ngIf="isCreditPayment==true" class="d-flex" style="direction: rtl; text-align: right; font-family: OpenSansHebrewBold ; margin-top: 10px;">
    <div class="col-5" style="font-size: 14px;"> 
        <span style="font-weight: bold; " >תשלום מאובטח</span>
        </div>
<div  class="align-self-center">
    <img (click)="isCreditSecureClick()" style="cursor: pointer;background-size: contain;" [src]="isCreditSecureImg">
</div>

</div>
    </div>
    </div>
    </form> 
    </div>
        <div (click)="payment()" class="keep col-4" style="position: relative; right: 30%;" >
            <img src="assets/images/Delivery_Popup_Ok_Button.png">               
            <span class="btn" style="width: auto; font-size: 14px; font-family: OpenSansHebrewRegular">תשלום</span>
          </div>
    </div>
    </div>
    

    
    <!-- <div class="lds-dual-ring"></div> -->
  
    