import { MapsAPILoader ,AgmMap , AgmPolygon ,GoogleMapsAPIWrapper} from '@agm/core';
//import { MapsAPILoader ,AgmMap, MouseEvent ,PolyMouseEvent , AgmPolygon ,GoogleMapsAPIWrapper ,LatLngLiteral} from '@agm/core';
import { Component, OnInit ,ViewChild ,ElementRef, NgZone, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormControl, Validators ,FormBuilder } from '@angular/forms';
import { Location } from 'src/app/models/location';
//import * as L from "leaflet";
import { Address } from 'src/app/models/user';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  locations:Location[]=[];
  title = 'firstApp';
  @ViewChild('AgmMap') agmMap: AgmMap;
  latitude: number;
  longitude: number;
  lat_=37.56;
  long=37.23;
  lat: string;
  lng: string;
  zoom = 10;
  Address:Address;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('map')
  public mapElementRef: ElementRef;
  @ViewChild(AgmMap) map: any;
 markers = {};
 bounds = null;
// add marker to object
 posi = new google.maps.LatLng(53.801279, -1.548567);
 name = 'Vince';
 address: string="";
  geocoder;
  myLatlng;
  marker;
  image = "/assets/images/Map_Point_Web.png";
 latlng;
 currentLocation :string="";
 //paths : Array<LatLngLiteral> = [];
polygon: any;

options : any = {
  lat          : 33.5362475,
  lng          : -111.9267386,
  zoom         : 10,
  fillColor    : '#29c066 ',
  draggable    : true,
  editable     : true,
  visible      : true,
  fillOpacity  : 0.7,
  clickable    : true,
};

clicked(clickEvent)
  {
    this.lat_ = clickEvent.feature.f.lat;
    this.long = clickEvent.feature.f.long;
    console.log(clickEvent);
  };
  // delete(clickEvent)
  // {
  //   console.log(clickEvent);
  //   console.log(clickEvent.coords.lat);
  // }

  onPolyClick($event: any) {
    console.log("polyClicked: ", $event.latLng.lat(), $event.latLng.lng());
    this.latitude = $event.latLng.lat(),  
    this.longitude = $event.latLng.lng()  ;
    let that = this;
    this.apiloader.load().then(() => {  
    let geocoder = new google.maps.Geocoder;  
    let latlng = {  
        lat: this.latitude,  
        lng: this.longitude  
    };  
    geocoder.geocode({  
        'location': latlng  
    }, function(results) {  
        if (results[0]) {  
            localStorage.ADDRESS = results[0].formatted_address;  
            that.Address.nvStreet = results[0].formatted_address;
            console.log( results[0].address_components);  
            console.log( results[0].formatted_address); 
            that.lat_ =  $event.latLng.lat();
            that.long =$event.latLng.lng();
            that.lat = "" +$event.latLng.lat();
            that.lng =""+$event.latLng.lng();
            that.zoom= 12;
        } else {  
            console.log('Not found');  
        }  
    });  
});
 }
  
  constructor(private dataService:DataService, private fb: FormBuilder,private router: Router,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone ,private apiloader: MapsAPILoader) {
    this.Address=new Address;
   }

    initMap() {
      var image = "url(/assets/images/Map_Point_Web.png)";
      this.myLatlng = { lat: this.lat_, lng:this.long };
      this.map = new google.maps.Map(document.getElementById("map"), {
        zoom: 4,
        center: this.myLatlng,
      });
  
  
      // map.addListener("center_changed", () => {
      //   // 3 seconds after the center of the map has changed, pan back to the
      //   // marker.
      //   window.setTimeout(() => {
      //     map.panTo(marker.getPosition());
      //   }, 3000);
      // });
      // marker.addListener("click", () => {
      //  // alert(marker.getMap.arguments.);
      //   map.setZoom(8);
      //   map.setCenter(marker.getPosition());
      // });
    

      google.maps.event.addListener(this.map, 'click', function(event) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'location': event.latlng },  (results, status) =>{
            if (status !== google.maps.GeocoderStatus.OK) {
             // alert(status);
            //  this.Address.streets = results[0].formatted_address;
              
            }
            // This is checking to see if the Geoeode Status is OK before proceeding
            if (status == google.maps.GeocoderStatus.OK) {
                console.log(results);
                var address = (results[0].formatted_address);
                this.Address.streets =address;
                alert(this.Address.streets);
            }
        });
       // this.getReverseGeocodingData(event.latlng);

        //this.getCurrentLocation(event.latLng);
        //alert(event.latLng);
    });
    }

 displayLocation(latitude,longitude){
    var request = new XMLHttpRequest();

    var method = 'GET';
    var url = 'http://maps.googleapis.com/maps/api/geocode/json?latlng='+latitude+','+longitude+'&sensor=true';
    var async = true;

    request.open(method, url, async);
    request.onreadystatechange = function(){
      if(request.readyState == 4 && request.status == 200){
        var data = JSON.parse(request.responseText);
        var address = data.results[0];
        alert(address);
        document.write(address.formatted_address);
      }
    };
    request.send();
  }

  select()
  {
    this.router.navigate(["../securePayment"])
  }

//   mapClicked($event: MouseEvent) {  
//     this.latitude = $event.coords.lat,  
//         this.longitude = $event.coords.lng  ;
//         let that = this;
//     this.apiloader.load().then(() => {  
//         let geocoder = new google.maps.Geocoder;  
//         let latlng = {  
//             lat: this.latitude,  
//             lng: this.longitude  
//         };  
//         geocoder.geocode({  
//             'location': latlng  
//         }, function(results) {  
//             if (results[0]) {  
//                 localStorage.ADDRESS = results[0].formatted_address;  
//                 that.Address.nvStreet = results[0].formatted_address;
//                 console.log( results[0].address_components);  
//                 console.log( results[0].formatted_address); 
//                 that.lat_ = $event.coords.lat;
//                 that.long =$event.coords.lng;
//                 that.lat = ""+$event.coords.lat;
//                 that.lng =""+$event.coords.lng;
//                 that.zoom= 12;
//             } else {  
//                 console.log('Not found');  
//             }  
//         });  
//     });
// }  

  ngOnInit() {
    const triangleCoords = [
      { lat: 29.49051836, lng: 34.90316749 },
      { lat: 29.49377521, lng: 34.90693331 },
      { lat: 29.49670745, lng: 34.91141796 },
      { lat: 29.49930344, lng: 34.91566658 },
      { lat: 29.50281445, lng: 34.91922855 },
      { lat: 29.5060639, lng: 34.92193222 },
      { lat: 29.50946264, lng:	34.92450714 },
      { lat: 29.51375758, lng:	34.92738247 },
      { lat: 29.51723075, lng:	34.93038654 },
      { lat: 29.5208345, lng:	34.93364811 },
      { lat: 29.52412072, lng:	34.93688822 },
      { lat: 29.52766821, lng:	34.94014978 },
      { lat: 29.53129026, lng:	34.9429822 },
      { lat: 29.53499853, lng:	34.94593263 },
      { lat: 29.53888168, lng:	34.94921565 },
      { lat: 29.54231665, lng:	34.95279908 },
      { lat: 29.54766256, lng:	34.95566368 },
      { lat: 29.54633719, lng:	34.96122122 },
      { lat: 29.54402242, lng:	34.96851683 },
      { lat: 29.54109156, lng:	34.97604847 },
      { lat: 29.54680387, lng:	34.97737885 },
      { lat: 29.55195588, lng:	34.97770071 },
      { lat: 29.556473,   lng:	34.97782946 },
      { lat: 29.56091527, lng:	34.97785091 },
      { lat: 29.56523135, lng:	34.97787237 },
      { lat: 29.56979921, lng:	34.97789383 },
      { lat: 29.57459546, lng:	34.97808695 }, 
      { lat: 29.57927953, lng:	34.97832298 },
      { lat: 29.58372079, lng:	34.97900963 },
      { lat: 29.5878633,  lng:	34.98046875 },
      { lat: 29.59301321, lng:	34.98252869 },
      { lat: 29.59865729, lng:	34.98437405 },
      { lat: 29.60383464, lng:	34.9864769 },
      { lat: 29.60935685, lng:	34.9887085 },
      { lat: 29.61507463, lng:	34.99072552 },
      { lat: 29.62305846, lng:	34.99364376 },
      { lat: 29.6329068,  lng:	34.99690533 },
      { lat: 29.64300594, lng:	35.00553131 },
      { lat: 29.65628342, lng:	35.00741959 },
      { lat: 29.67015577, lng:	35.00862122 },
      { lat: 29.68387707, lng:	35.01050949 },
      { lat: 29.70147339, lng:	35.01274109 },
      { lat: 29.71516247, lng:	35.01544476 },
      { lat: 29.72649251, lng:	35.01870632 },
      { lat: 29.7407278,  lng:	35.02265453 },
      { lat: 29.76382788, lng:	35.02857685 },
      { lat: 29.78249966, lng:	35.0329113 },
      { lat: 29.79802113, lng:	35.03612995 },
      { lat: 29.81150154, lng:	35.03956318 },
      { lat: 29.83641874, lng:	35.04939079 },
      { lat: 29.8627719,  lng:	35.05969048 },
      { lat: 29.88569498, lng:	35.07084846 },
      { lat: 29.91188633, lng:	35.07376671 },
      { lat: 29.92959135, lng:	35.07308006 },
      { lat: 29.9455083,  lng:	35.0729084 },
      { lat: 29.95469751, lng:	35.07301569 },
      { lat: 29.96660921, lng:	35.08681297 },
      { lat: 29.98051292, lng:	35.08621216 },
      { lat: 29.98152588, lng:	35.093894 },
      { lat: 29.99446113, lng:	35.09492397 },
      { lat: 30.00281426, lng:	35.07514 },
      { lat: 30.01478058, lng:	35.06964684 },
      { lat: 30.02094898, lng:	35.06578445 },
      { lat: 30.0286218,  lng:	35.0600338 },
      { lat: 30.0356067,  lng:	35.05454063 },
      { lat: 30.04309264,  lng:	35.05342484 },
      { lat: 30.04918499,  lng:	35.04896164 },
      { lat: 30.05393974,  lng:	35.04364014 },
      { lat: 30.05483122,  lng:	35.03673077 },
      { lat: 30.0551516,   lng:	35.03027201 },
      { lat: 30.05539769,  lng:	35.02385616 },
      { lat: 30.05680919,  lng:	35.0172472 },
      { lat: 30.06441423,  lng:	35.01634598 },
      { lat: 30.07319784,  lng:	35.01626015 },
      { lat: 30.08567553,  lng:	35.01694679 },
      { lat: 30.096815,    lng:	35.01849174 },
      { lat: 30.10513165,  lng:	35.02055168 },
      { lat: 30.11374458,  lng:	35.02243996 },
      { lat: 30.12295069,  lng:	35.02449989 },
      { lat: 30.12384155,  lng:	35.00767708 },
      { lat: 30.12428698,  lng:	34.99257088 },
      { lat: 30.11550792,  lng:	34.9917984  },
      { lat: 30.10679304,  lng:	34.99081135 },
      { lat: 30.09951613,  lng:	34.99021053 },
      { lat: 30.09048422,  lng:	34.97943878 },
      { lat: 30.07771004,  lng:	34.96639252 },
      { lat: 30.06523134,  lng:	34.95609283 },
      { lat: 30.05185956,  lng:	34.94510651 },
      { lat: 30.04164379,  lng:	34.93360519 },
      { lat: 30.03569959,  lng:	34.92399216 },
      { lat: 30.02707985,  lng:	34.91643906 },
      { lat: 30.01697301,  lng:	34.91231918 },
      { lat: 30.00121616,  lng:	34.91025925 },
      { lat: 29.99021462,  lng:	34.90545273 },
      { lat: 29.97921185,  lng:	34.90030289 },
      { lat: 29.96910013,  lng:	34.89412308 },
      { lat: 29.95750012,  lng:	34.88794327 },
      { lat: 29.94738619,  lng:	34.88142014 },
      { lat: 29.93846127,  lng:	34.87455368 },
      { lat: 29.93042817,  lng:	34.86700058 },
      { lat: 29.93042817,  lng:	34.85429764 },
      { lat: 29.92953556,  lng:	34.84331131 },
      { lat: 29.92902417,  lng:	34.834342 },
      { lat: 29.92865224,  lng:	34.82696056 },
      { lat: 29.92125997,  lng:	34.82331276 },
      { lat: 29.91024957,  lng:	34.82365608 },
      { lat: 29.90087489,  lng:	34.8231411 },
      { lat: 29.88837395,  lng:	34.8231411 },
      { lat: 29.87869953,  lng:	34.82228279 },
      { lat: 29.86589808,  lng:	34.82245445 },
      { lat: 29.85488157,  lng:	34.82211113 },
      { lat: 29.84639505,  lng:	34.82073784 },
      { lat: 29.83433401,  lng:	34.82331276 },
      { lat: 29.82152687,  lng:	34.82743263 },
      { lat: 29.80886704,  lng:	34.83086586 },
      { lat: 29.79680148,  lng:	34.83567238 },
      { lat: 29.78249966,  lng:	34.84219551 },
      { lat: 29.77177196,  lng:	34.84786034 },
      { lat: 29.76089409,  lng:	34.85180855 },
      { lat: 29.75120732,  lng:	34.85249519 },
      { lat: 29.74461699,  lng:	34.85028505 },
      { lat: 29.74223226,  lng:	34.84951258 },
      { lat: 29.7330654,   lng:	34.8509717 },
      { lat: 29.72184791,  lng:	34.85217333 },
      { lat: 29.71279101,  lng:	34.85363245 },
      { lat: 29.70067659,  lng:	34.85530615 },
      { lat: 29.69105857,  lng:	34.85642195 },
      { lat: 29.68688302,  lng:	34.85753775 },
      { lat: 29.67916525,  lng:	34.86152887 },
      { lat: 29.67267739,  lng:	34.86453295 },
      { lat: 29.66536872,  lng:	34.8683095 },
      { lat: 29.6611921,   lng:	34.86981153 },
      { lat: 29.65500144,  lng:	34.87264395 },
      { lat: 29.64743041,  lng:	34.87693548 },
      { lat: 29.64355143,  lng:	34.87912416 },
      { lat: 29.63273427,  lng:	34.8786521 },
      { lat: 29.62411701,  lng:	34.87792253 },
      { lat: 29.6185583,   lng:	34.87680674 },
      { lat: 29.61326046,  lng:	34.87650633 },
      { lat: 29.60870856,  lng:	34.87337351 },
      { lat: 29.6048281,   lng:	34.8708415 },
      { lat: 29.60064897,  lng:	34.86792326 },
      { lat: 29.59680551,  lng:	34.86697912 },
      { lat: 29.59326044,  lng:	34.87062693 },
      { lat: 29.58844641,  lng:	34.87637758 },
      { lat: 29.58363215,  lng:	34.881742 },
      { lat: 29.5804598,   lng:	34.88204241 },
      { lat: 29.57639167,  lng:	34.88191366 },
      { lat: 29.57086765,  lng:	34.88187075 },
      { lat: 29.56664979,  lng:	34.88496065 },
      { lat: 29.5611859,   lng:	34.89090443 },
      { lat: 29.55737827,  lng:	34.90077496 },
      { lat: 29.55182988,  lng:	34.90139723 },
      { lat: 29.54693921,  lng:	34.90221262 },
      { lat: 29.54208563,  lng:	34.90641832 },
      { lat: 29.54111489,  lng:	34.91410017 },
      { lat: 29.54126424,  lng:	34.91800547 },
      { lat: 29.53954675,  lng:	34.92233992 },
      { lat: 29.53711981,  lng:	34.92637396 },
      { lat: 29.53499153,  lng:	34.93027925 },
      { lat: 29.53342329,  lng:	34.93255377 },
      { lat: 29.52998802,  lng:	34.93045092 },
      { lat: 29.52637992,  lng:	34.92794037 },
      { lat: 29.52290707,  lng:	34.92540836 },
      { lat: 29.52050308,  lng:	34.92319822 },
      { lat: 29.51822506,  lng:	34.92083788 },
      { lat: 29.51579762,  lng:	34.91886377 },
      { lat: 29.5128846,   lng:	34.91628885 },
      { lat: 29.50978477,  lng:	34.91298437 },
      { lat: 29.50634869,  lng:	34.90959406 },
      { lat: 29.50365951,  lng:	34.90701914 },
      { lat: 29.50156787,  lng:	34.90341425 },
      { lat: 9.50074614,   lng:	34.90109682 },
      { lat: 29.49633859,  lng:	34.90289927 },
      { lat: 29.49346237,  lng:	34.90414381 },
      { lat: 29.49160166,  lng:	34.90301728 },
    ];
    this.dataService.showSearch.next(false);
    this.changByFood.emit();
    this.dataService.byFood.next(true);
    this.changByFood.emit();
    this.lat_=triangleCoords[25].lat;
    this.long=triangleCoords[25].lng;
    const bermudaTriangle = new google.maps.Polygon({
      paths: triangleCoords,
      strokeWeight: 3,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });
    // for(let i=0;i<triangleCoords.length;i++)
    // {
    // this.paths.push(triangleCoords[i]);
    // }
    var southWest = new google.maps.LatLng( 29.490725, 34.732479);
    var northEast = new google.maps.LatLng( 30.123217, 35.144384 );
    var bangaloreBounds = new google.maps.LatLngBounds( southWest, northEast );
  
    bermudaTriangle.setMap(this.map);
    this.mapsAPILoader.load().then(() => { 
      var options= {
        componentRestrictions: {country: "IL" },
        bounds: bangaloreBounds,
        strictBounds: true ,
  
       }; 
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,options);

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.lat_ = place.geometry.location.lat();
          this.long =place.geometry.location.lng();
          this.lat = ""+(place.geometry.location.lat());
          this.lng =""+(place.geometry.location.lng());
          this.myLatlng = { lat: this.lat_, lng:this.long };
          this.address = (""+place.name);
          localStorage.ADDRESS=place.formatted_address;
          this.zoom = 12;
          this.map.center= this.myLatlng ;
        }); 
      });
    });
    
  }

   getReverseGeocodingData(latlng) {
    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'location': latlng },  (results, status) =>{
        if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == google.maps.GeocoderStatus.OK) {
            console.log(results);
            var address = (results[0].formatted_address);
        }
    });
}
  
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          localStorage.ADDRESS=this.address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
}

getPolygonCoordinates(draggablePolygon) {
  const len = draggablePolygon.getPath().getLength();
  const polyArrayLatLng = [];
  for (let i = 0; i < len; i++) {
    const vertex = draggablePolygon.getPath().getAt(i);
    const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
    polyArrayLatLng.push(vertexLatLng);
  }
  console.log(polyArrayLatLng);
}

onMapReady(map) {
  this.map = map;
}

initDrawingManager(map: any) {
  const options = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ["polygon"]
    },
    polygonOptions: {
      draggable: true,
      editable: true
    },
    drawingMode: google.maps.drawing.OverlayType.POLYGON
  };
}
}
