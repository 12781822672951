import { Component, OnInit ,Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/order-service';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';

@Component({
  selector: 'app-popup-login',
  templateUrl: './popup-login.component.html',
  styleUrls: ['./popup-login.component.css']
})
export class PopupLoginComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private data: {OrderID: number},private matDialog:MatDialog,private orderService:OrdersService ,private router: Router) { }

  ngOnInit(): void {
  }

  cancel(){
    this.matDialog.closeAll();
  }

  end()
  {
    this.matDialog.closeAll();
    this.router.navigate(['../login']);
  }

  close()
  {
    this.matDialog.closeAll();
  }

}