import { Component, OnInit } from '@angular/core';
import { GiftCards } from 'src/app/models/giftCard';
import { UserEditProfile } from 'src/app/models/user';
import { UserService } from 'src/app/services/user-service';
import { Router } from '@angular/router';
import { MyOrder, Order } from 'src/app/models/order';
import { OrdersService } from 'src/app/services/order-service';
import { AppProxy } from 'src/app/services/app-proxy';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupGiftcardComponent } from '../popup-giftcard/popup-giftcard.component';

@Component({
  selector: 'app-gift-card-sucsess',
  templateUrl: './gift-card-sucsess.component.html',
  styleUrls: ['./gift-card-sucsess.component.css']
})
export class GiftCardSucsessComponent implements OnInit {
 userEditProfile:UserEditProfile;
 giftCards:GiftCards[];
 sum:string;
 name:string;
 code:string="123456"
 order:MyOrder;

  constructor(private matDialog:MatDialog,private userService:UserService ,private router: Router ,private orderService:OrdersService,private proxy:AppProxy) { }

  ngOnInit(): void {
  //   this.userService.getUserDetails(1).subscribe((res:any)=>{
  //     this.userEditProfile=res;
  //     this.name=this.userService.giftCardFor;
  //     this.userService.getGiftCards().subscribe((res:any)=>{
  //       this.giftCards=res;
  //       this.sum=this.giftCards[0].Price+" ₪";
  //       this.code=this.giftCards[0].Number;
  //     })
  //  });  
  }
  clickGiftCard()
  {
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
     // this.router.navigate(['../restaurantCategory']);
      if(this.order.OrderID!=0) 
      {
        let price=Number(this.order.TotalPrice);
        if(this.order.IsDelivery==1)
        price+=Number(this.order.DeliveryPrice);
        let allPrice=Number(price);
        let status=2;
        if(price>Number(this.giftCards[0].Price))
        {
          price=Number(this.giftCards[0].Price)
          status=1;
        }
        this.orderService.setUpdateOrder(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,
          null,0,status,null,1,null,null,null,1,Number(this.code),Number(price),null,null).subscribe((res:any)=>{
            localStorage.MENU_ID=0;
            this.orderService.getOrderDetails(false).subscribe((res:any)=>{
              this.order=res;
            if(Number(allPrice)<=Number(this.giftCards[0].Price)|| Number(this.order.Balance)<=0)
            this.router.navigate(['../endOrder']);
            else
           {
           
            let iPay=Number(Number(allPrice)-Number(this.order.Balance));

         //   this.router.navigate(['../securePayment']); 
          //  this.proxy.showError("אין לך מספיק כסף בכרטיס עליך להשלים את מחיר ההזמנה בצורה אחרת ",""); 
          this.matDialog.open(PopupGiftcardComponent, {
            data: { price:  allPrice ,price2:Number(iPay) , payBy:"גיפט קארד"},
          });
           }});

      });}
      else
    this.router.navigate(['../mainHome']);
  });}
}
