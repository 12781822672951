import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  stars:number[]=[1,2,3,4,5];
  orderFinal:boolean=true;
starFull='assets/images/Star_Full.png';
starEmpty='assets/images/Star_Empty.png';

  constructor(private router: Router){}

  ngOnInit(): void {
  }

  star()
  {

  }

  Continued()
  {
    this.router.navigate(['../mainHome']); 
  }

}
