import { Component, OnInit ,Input ,HostListener ,Output ,EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, CategoryItem} from 'src/app/models/category';
import { ChangeFood} from 'src/app/models/restaurant'
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-restaurant-category',
  templateUrl: './restaurant-category.component.html',
  styleUrls: ['./restaurant-category.component.css']
})
export class RestaurantCategoryComponent implements OnInit {
  @Input()items: CategoryItem[];
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  buffer: CategoryItem[] = [];
  loading: boolean;
  restaurantsItem:CategoryItem[]=[];
  restaurantsItemAll:CategoryItem[]=[];
  category:Category;
  changeFood:ChangeFood[];
  id:number=-1;
  text:string;
  categoryName="";
  num=0;
  page:number=1;
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  categoryId:number;
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.backPress();
  // }

  backPress()
{
  this.bussinssService.restaurantCategoryList=null;
} 

  onScrollDown() {
    this.appendItems();
    this.direction = "down";
  }

  onUp() {
    this.sum += 20;
    this.prependItems();
    this.direction = "up";
  }

  prependItems() {
    this.addItems();
  }

  appendItems() {
    this.addItems();
  }

  addItems() {
    this.page=Number(this.page)+1;
    if(this.id>-1)
    {
      this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
        for(let i=0;i<res.length;i++)
        {
        this.restaurantsItem.push(res[i]);
        }
    });
  }
  else
  {
    this.bussinssService.getMenuBySearch(this.text,this.page).subscribe((res:any[])=>{
      for(let i=0;i<res.length;i++)
      {
      this.restaurantsItem.push(res[i]);
      }
    });
  }
  }

  constructor(private router: Router,private proxy:AppProxy, private route: ActivatedRoute,private dataService:DataService, private bussinssService:BussinssService) { }

  ngOnInit(): void {
    this.bussinssService.restaurant=null;
    this.scrollToTop();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    this.text = this.dataService.search.getValue() || {};
    this.dataService.search.subscribe(data=>{
      this.text=data;
  if(this.text!="")
    {
      this.bussinssService.getMenuBySearch(this.text,this.page).subscribe((res:any[])=>{
        this.restaurantsItem=res;
        this.bussinssService.setGlobalSearch(this.text);
        this.categoryName=this.text;
        this.category={
          "Name": this.categoryName,
          "ID": 33,
          "categoryItems":this.restaurantsItem,
          "isCheck":true,
          "categoryType":3
        }
  
      });
    }
    });
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    this.page=1;

    if(localStorage.TEXT_SEARCH!=null)
     this.text=localStorage.TEXT_SEARCH;
     if(localStorage.ID_CATEGORY!="0")
     this.id=localStorage.ID_CATEGORY;
   if(this.id>-1)
    {
    this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
      this.restaurantsItem=res;
      if(this.restaurantsItem!=null && this.restaurantsItem.length>0)
      {
      this.categoryName=this.restaurantsItem[0].Category;
      this.category={
        "Name": this.restaurantsItem[0].Category,
        "ID": 33,
        "categoryItems":this.restaurantsItem,
        "isCheck":true,
        "categoryType":3
      }
    }
    else
    this.proxy.showError("לא נמצאו עסקים בקטגוריה זו","");
    });
  }
  else{
    this.bussinssService.getMenuBySearch(this.text,this.page).subscribe((res:any[])=>{
      this.restaurantsItem=res;
      if(this.restaurantsItem!=null && this.restaurantsItem.length>0)
      {
      this.bussinssService.setGlobalSearch(this.text);
      if(this.bussinssService.categoryName!=null)
      this.categoryName=this.bussinssService.categoryName;
      this.category={
        "Name": this.categoryName,
        "ID": 33,
        "categoryItems":this.restaurantsItem,
        "isCheck":true,
        "categoryType":3
      }
    }
    });
  }
  } 

  scrollToTop(): void {
    setTimeout(() => window.scroll(0, 0), 0);
  }

  onClickCategory(id:number){
   }
}
