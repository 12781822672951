import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable(
{ providedIn: "root"}
)
export class DataService {
    public state = new BehaviorSubject<any>("");
    public search=new BehaviorSubject<any>("");
    public price=new BehaviorSubject<any>("0 - פריטים");
    public show=new BehaviorSubject<any>(false);
    public show400=new BehaviorSubject<any>(false);
    public byFood=new BehaviorSubject<any>(false);
    public showSearch=new BehaviorSubject<any>(false);
    public categoryId=new BehaviorSubject<any>("");
    public deleteOrder =new BehaviorSubject<any>("");
    public orderOpen =new BehaviorSubject<any>(false);
    public endToppings = new BehaviorSubject<any>(true);
    public text=new BehaviorSubject<any>("");
    public notEndToppings;
}