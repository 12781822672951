import { Component, OnInit, EventEmitter ,ViewChild ,ElementRef, Output } from '@angular/core';
import { FormGroup,FormControl,Validators,FormBuilder} from '@angular/forms';
import { Router } from '@angular/router'
import { UserService } from 'src/app/services/user-service';
import { CustomValidators } from 'ng2-validation';
import { filter } from "rxjs/operators";
import { AppProxy } from 'src/app/services/app-proxy';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  contactForm:FormGroup;
  valid:boolean=false;
  @ViewChild("name") nameElement: ElementRef;
  @ViewChild("email") emailElement: ElementRef;
  @ViewChild("phone") phoneElement: ElementRef;
  @ViewChild("message") messageElement: ElementRef;
  errorMail:string;
  errorPhone:string;

  constructor(private dataService:DataService,private router: Router,private userService:UserService,private fb: FormBuilder ,private proxy: AppProxy){}

  ngOnInit(): void {
    this.dataService.byFood.next(false);
    this.changByFood.emit();
    this.createForm();
  }

  limitLines(event: InputEvent, maxLines: number) {
    let text = (event.target as HTMLTextAreaElement).value;
    if (text.length > 0) {
      const lineCount = 1 + text.replace(/[^\n]/g, '').length;
      if (lineCount > maxLines) {
        const textArray = text.split('\n');
        const newText = textArray.reduce((result, line, lineNum, array) => {
          if (lineNum < maxLines) {
            return result.concat('\n').concat(line);
          }
          return result.concat(line);
        });
        (event.target as HTMLTextAreaElement).value = newText;
      }
    }
  }

  createForm() {
    this.contactForm = this.fb.group({ // create nested formgroup to pass to child
      nvName: [, [Validators.required]],
      nvEmail: new FormControl("", [Validators.required, Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
     // nvPhone: [, [CustomValidators.phone, Validators.required]],
      nvPhone: new FormControl("", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),
      nvMessage: [, [Validators.required]],
  });
  this.contactForm.get("nvName").valueChanges
  .pipe(filter((value: string) => value.length === 1))
  .subscribe(() => this.emailElement.nativeElement.focus());

this.contactForm.get("nvEmail").valueChanges
  .pipe(filter((value: string) => value.length === 1))
  .subscribe(() => this.phoneElement.nativeElement.focus());

  this.contactForm.get("nvPhone").valueChanges
  .pipe(filter((value: string) => value.length === 1))
  .subscribe(() => this.messageElement.nativeElement.focus());
}


isValidInput(fieldName): boolean {
  if(this.valid==true)
  return this.contactForm.controls[fieldName].invalid;
}

isValidInputMail(fieldName): boolean {
  if(this.valid==true)
  {
  if(this.contactForm.controls[fieldName].value == undefined || this.contactForm.controls[fieldName].value == "")
  this.errorMail="שדה חובה"
  else
  this.errorMail="כתובת מייל אינה תקינה";
  return this.contactForm.controls[fieldName].invalid;
  }
}

isValidInputPhone(fieldName): boolean {
  if(this.valid==true)
  {
  if(this.contactForm.controls[fieldName].value == undefined || this.contactForm.controls[fieldName].value == "")
  this.errorPhone="שדה חובה"
  else
  this.errorPhone="עליך להכניס מספר טלפון שמתחיל בספרות  05xxxxxxxx";
 
  return this.contactForm.controls[fieldName].invalid;
  }
}
  
  send(){
    if(localStorage.ACCESS_TOKEN  == "null")
    this.router.navigate(['../mainHome']);
    else
    {
    this.valid=true;
    if(this.contactForm.valid==true)
    {
    this.userService.setContactRequest(this.contactForm.value.nvName,this.contactForm.value.nvEmail,this.contactForm.value.nvPhone,
      this.contactForm.value.nvMessage);
      this.proxy.showSucceeded(",תודה על פנייתך  \n  לבא לי בקליק  \n  נציג יחזור אליך בהקדם","");
    }
  }
  }
}
