<div style="min-height: 478px;">
    <app-top-header-green style="font-family: OpenSansHebrewBold;" customTitle={{categoryName}} ></app-top-header-green>
           <div class="d-flex flex-wrap m-auto" style=" font-family: OpenSansHebrewRegular; margin-top: 10px; width: 75%; direction: rtl;">
                   <div class="d-flex flex-wrap" style="font-family: OpenSansHebrewRegular; direction: rtl;"
         infinite-scroll
         [infiniteScrollDistance]="scrollDistance"
         [infiniteScrollUpDistance]="scrollUpDistance"
         [infiniteScrollThrottle]="throttle"
         (scrolled)="onScrollDown()"
         (scrolledUp)="onUp()">
                      <div *ngFor="let i of restaurantsItem; let ind = index" style="margin-top: 10px; margin-right: 27px;">
                 <app-main-list-item  (change)="ngOnInit()" (click)="onClickCategory(i.RstaurantID)"  style="cursor: pointer;" [categoryItem]="i" [category]="category" [indexList]="ind" [restaurantCategory]="8"></app-main-list-item >
                   </div>
               </div>
               </div>