import { Component, OnInit ,ViewChild ,ElementRef} from '@angular/core';
import { FormGroup ,FormControl ,Validators} from '@angular/forms';
import { Payment } from 'src/app/models/user';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import { AppProxy } from 'src/app/services/app-proxy';
import {MatDatepicker} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';


const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PaymentInfoComponent implements OnInit {
  @ViewChild('picker') datePickerElement = MatDatepicker;
  paymentForm:FormGroup;
  payment:Payment=new Payment;
  valid:boolean=false;
  isKeepCard=true;
  keepCardImg='assets/images/Pay_Page_Chack_Box_On.png';
  date = new FormControl(moment());
  month:string="";
  year:string="";
  date1:string="";
  token:string=null;
  @ViewChild('dp')
  public dateElementRef: ElementRef;
  isAnotherAddress:boolean=true;

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.year=""+normalizedYear.year();
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.month=""+Number(normalizedMonth.month()+1);
    datepicker.close();
  }

  constructor(private router: Router,private userService:UserService,private proxy:AppProxy) {}

  setUserCreditCard()
  {
      var last2 = this.year.slice(-2);
      if(this.month.length==1)
      this.month="0"+this.month;
      if(this.date1=="")
      this.date1=this.month+last2;
      //if(!this.paymentForm.value.CardNumber.startsWith("x"))
      // this.userService.setUserCreditCard(true,this.proxy.md5_(this.proxy.SECRET_KEY+localStorage.USER_ID),this.paymentForm.value.CardNumber,this.date1.replace("/",""),
      // this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.UserName,null,true,false,0).subscribe((res:any)=>{
      //   window.history.back();
      // });
      //else
     // this.proxy.showError("אין אפשרות לשנות  \n רק חלק מפרטי האשראי  \n עליך למחוק  \n את כרטיס האשראי  \n ולשמור מחדש", "");
  }

   keepCard(){
    if(this.isKeepCard==true)
    {
      this.isKeepCard=false;
      this.keepCardImg='assets/images/Pay_Page_Chack_Box_Off.png'; 
    }
    else
    {
      this.isKeepCard=true;
      this.keepCardImg='assets/images/Pay_Page_Chack_Box_On.png'; 
    }
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.paymentForm.controls[fieldName].invalid;
  }

  createForm() {
    this.paymentForm = new FormGroup({
      UserName: new FormControl("", Validators.required),
      CardNumber: new FormControl("", Validators.required),
       date: new FormControl(""),
        cvv: new FormControl("", [Validators.required,Validators.minLength(3), Validators.maxLength(3)])
    });
  }

  keep()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
    this.valid=true;
    if(this.paymentForm.value.CardDate!=null)
    var dateText = this.paymentForm.value.CardDate.replace(/<[^>]*>/g, '');
    this.setUserCreditCard();
    // this.userService.setUserCreditCard(true,this.proxy.md5_(this.proxy.SECRET_KEY+localStorage.USER_ID),this.paymentForm.value.CardNumber,this.paymentForm.value.date.replace("/",""),
    // this.paymentForm.value.UserName,this.token,true,false,0).subscribe((res:any)=>{
    //   window.history.back();
    // });
  }
  }

  remove(){
  this.userService.DeleteCreditCard();
  }

  ngOnInit(): void {
    this.createForm();
    // this.userService.getUserCreditCard().subscribe((res:any)=>{
    //   if(res!=null)
    //   {
    // this.payment=res;
    // this.token=this.payment.card_token;
    // this.payment.card_cvv=null;
    // this.date = new FormControl(moment(this.payment.CardDate,"MM/YY"));
    // this.paymentForm.setValue({date:this.date});
    // this.payment.CardDate = this.payment.CardDate.slice(0, 2) + "/" + this.payment.CardDate.slice(2);
    //  this.date1=this.payment.CardDate;
    //   }
    //   else
    //   this.date1="";
    // });
  }
}
