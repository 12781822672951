import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
import { LoginComponent } from '../login/login.component';
import { UserService } from 'src/app/services/user-service';
import { AppProxy } from 'src/app/services/app-proxy';
import { DataService } from 'src/app/services/data.service';
import { CreditCardService } from 'src/app/services/creditCard-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  confirm = null;
  outOrIn="התנתק"

  constructor(private router: Router,private creaditCardService:CreditCardService,private proxy:AppProxy,private dataService:DataService ,private matDialog:MatDialog,private userService:UserService) { }


  openDialog() {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(TermsOfUseComponent, dialogConfig);
  }

  openAppleStore()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
   
  }

  orderHistory()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    {
    this.router.navigate(['../orderHistory']);
    }
  }

  shippingHistory(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../shippingHistory']);
  }

  paymentInfo(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../paymentInfo']);
  }

  reservedRestaurants(){
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['../reservedRestaurants']);
  }

  editProfil()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    this.router.navigate(['/register']);
  }

  giftCard()
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.proxy.showError("אינך רשום למערכת","");
    else
    {
      this.creaditCardService.IsPaymentByGiftCard=false;
    this.router.navigate(['/giftCard']);
    }
  }

  logOut(){
    if(localStorage.ACCESS_TOKEN  != "null")
  {
    let reset = confirm("האם אתה בטוח שברצונך להתנתק מהאתר ");
    if (reset)
    {
      this.dataService.state.next("התחבר");
      localStorage.ACCESS_TOKEN = "null";
      this.router.navigate(["../mainHome"])
    }
  }
  else{
    this.router.navigate(["../mainHome"])
  }
}

 openDialogLogin() {
  this.userService.LoginOBJ=false;
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(LoginComponent, { disableClose: true });
  }

  ngOnInit(): void {
    this.outOrIn = this.dataService.state.getValue() || {};
    this.dataService.state.subscribe(data=>{
      this.outOrIn=data;
    });
  }
}
