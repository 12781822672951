 <div style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold;">
    <form class="form-signin" [formGroup]="registerForm"> 
      <div class="form-group">
        <img (click)="close()" src="assets/images/Popup_1_Close_Button.png" />
        <div style="text-align: center;">
        <br> 
        <img src="assets/images/Signin_Popup_Logo.png" />
      </div>
        <div style="margin-top: 10px;">
            <div class="txt-input" >
                <img style="position: absolute; top: 0; margin-top: 5px;" src="assets/images/Signin_Icon_1.png"/>
                  <input style="margin-right:20px ;height: 21px;" matInput formControlName="userName" placeholder="שם משתמש" required>
                </div>
            <br>
            <br>
                <div class="txt" ></div>
                <span *ngIf="isValidInput('userFullName')" style="color: #ff8585; font-size: 8px;">שדה חובה</span>
                </div>
                <br> 
                <div>
                    <div class="txt-input" >
                        <img style="position: absolute; top: 0; margin-top: 5px;" src="assets/images/Signin_Icon_2.png"/>
                          <input style="margin-right:20px; height: 21px;"  matInput formControlName="userPassword" placeholder="סיסמא" type="password" required>                     
                    </div>
                    <br>
                    <br>
                        <div class="txt"></div>
                        <span *ngIf="isValidInput('userPassword')" style="color: #ff8585; font-size: 8px;">שדה חובה</span>
                        </div>  
                        <p (click)="forgotPassword()" style="cursor: pointer; color: white; font-size: 13px;
                        text-align: left; cursor: pointer;">שכחתי סיסמא</p>
                  <br>
                  <br>
                    <div (click)="continue()" class="keep" [class.isDisabled]="registerForm.valid" style="position: relative;">
                      <img  src="assets/images/Signin_Button_1.png">               
                      <span  class="centered" style="color: #29c066;">המשך</span>
                    </div>

                  </div>          
    </form>
  </div>
