
<div style="min-height: 478px;">
    <!-- <div class="registerbtn_" style="font-family: OpenSansHebrewBold">
    <h1 style="cursor: pointer; position: absolute;
    right: 40%; margin-top: 15px; color: white;" >Gift Card</h1>
    </div> -->
    <!-- <div style="width: 73%;"> -->
        <!-- <div class="keep align-self-center" style="direction: rtl;">
            <img src="assets/images/Bali_Gift_Card.png">               
            <span class="btn" style="color: black;">{{sum}}  ₪</span>
          </div> -->
    <!-- </div>               -->

    <div style="display: flex; justify-content: center; height: 320px; cursor: pointer;" (click)="clickGiftCard()">

   <div style="position: absolute;"> 
          <img style="width: 480px;" src="assets/images/Bali_Gift_Card.png"> 
           <span  class="barCode">{{code}}</span>        
          <span class="btn" style="direction: rtl;">{{sum}}</span>
  </div>
</div>

<!-- <div class=" d-flex" style="justify-content: center; direction: rtl; float: center;"> -->
  <div class="keep align-self-center" style="justify-content: center; direction: rtl; float: center;">
    <span class="btn"  style="left: 50%; color: #41c777; position: relative;">גיפט קארד על סך {{sum}}</span>
    <span class="btn"  style=" left: 50%;color: #41c777; position: relative;">נשלח ל{{name}}</span>         
    </div>
  <!-- </div> -->

  </div>

    
