import { Component, OnInit ,ViewChild ,ElementRef, NgZone } from '@angular/core';
import { SignUp, User, UserEditProfile } from 'src/app/models/user'
import { FormGroup, FormControl, Validators ,FormBuilder ,ValidatorFn } from '@angular/forms';
import { UserService} from '../../services/user-service';
import { AppProxy } from 'src/app/services/app-proxy';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
 import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  levelDelivery:number=0;
  user:User;
  valid:boolean=false;
  public registerForm: FormGroup;
  keepEnabled='assets/images/Delivery_Popup_Ok_Button.png';
  keepUnEnabled='assets/images/Delivery_Popup_Cancel_Button.png';
  isEditProfile=false;
  signUp:SignUp;
  name = 'Angular 4';
  url=null;
  file:File;
  width;
  height;
  fileToUpload: File = null;
  userEditProfile:UserEditProfile;
  private sanitizer: DomSanitizer
  image_data;
  title = 'FileUploadProject';
  errorPhone="";
  errorMail="";
  errorPassword="";
  errorPasswordAgain="";
  ImageBaseData:string | ArrayBuffer=null;
  fileList: FileList;
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;
    latitude: number;
    longitude: number;
     formattedaddress=" ";
     options={
       componentRestrictions:{
         country:["isr"]
       }
     };
     lat: string;
  lng: string;
  zoom = 1;
  private geoCoder;
  address_: string;
  @ViewChild('search')
  public searchElementRef: ElementRef;

    TermsUse()
    {
      const dialogConfig = new MatDialogConfig();
    this.matDialog.open(TermsOfUseComponent, dialogConfig);
    }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = e =>
      {
        var img = new Image();
        img.onload = () => {
            this.width = img.width;
            this.height = img.height;
            //console.log('height: '+this.height);
            //console.log('width: '+this.width);
        };
        
         this.url = reader.result;
         img.src = this.url;
         let  loadedImage = e.currentTarget;
      }
  }
}

 getImageDimenstion(imgUrl){
  let img = new Image(); 
  img.src = imgUrl;
  img.onload = function (event) {
  } 
}
 
  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,private http: HttpClient,private userService: UserService,private location: PlatformLocation,private proxy:AppProxy,private router: Router,
    private matDialog:MatDialog,private fb: FormBuilder, private dataService:DataService ,private httpClient:HttpClient) { 
    this.user = new User(); 
  }

  openDialog() {
    if(this.userService.LoginOBJ==false)
    {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(LoginComponent, { disableClose: true });
    }
  }

  logOut()
  {
    localStorage.ACCESS_TOKEN=null;
    let reset = confirm("האם אתה בטוח שברצונך להתנתק מהאתר ");
    if (reset)
    {
      this.dataService.state.next("התחבר");
      localStorage.ACCESS_TOKEN = null;
      this.router.navigate(["../mainHome"])
  }
  else
    this.dataService.state.next("התנתק");
  }

  keep()
  {
    this.valid=true;
    if(this.registerForm.valid)
    {
     const formData = new FormData();
     if(this.user.userPassword!=null && this.user.userPassword!=undefined)
     {
     formData.append('signature', this.proxy.md5_(this.proxy.SECRET_KEY+this.user.userEmail));
     formData.append('userEmail', this.user.userEmail);
     formData.append('userPassword', this.proxy.md5_(this.proxy.SECRET_KEY+this.user.userPassword));
     formData.append('userFullName', this.user.userFullName);
     let address=this.user.userAddress;
     if(this.user.userEnter!=null && this.user.userEnter!='')
     address+=" כניסה: "+this.user.userEnter
     if(this.user.userFloor!=null)
     address+=" קומה: "+this.user.userFloor;
     if(this.user.userNumberHoush!=null)
     address+=" דירה: "+this.user.userNumberHoush
     formData.append('userAddress', address);
     formData.append('userPhone', this.user.userPhone);
     formData.append('ImageFile',this.file);
     formData.append('Latitude',this.user.userLatitude+"");
     formData.append('Longitude',this.user.userLongitude+"");
     this.userService.register(formData).subscribe((res:any)=>{
      this.dataService.state.next("התנתק");
      let token=res.responseMessage;
      this.proxy.ACCESS_TOKEN=token;
      localStorage.ACCESS_TOKEN = token;
      this.matDialog.closeAll();
      // this.userService.getUserDetails(1).subscribe((res:any)=>{
      //  this.userEditProfile=res;
      //  this.userService.setUserPhone(this.userEditProfile.userPhone).subscribe((res:any)=>{
      //  });
      // });
      });
     }
     else
     {
     formData.append('signature',this.proxy.md5_(this.proxy.SECRET_KEY+this.user.IDUser));
     formData.append('userFullName', this.user.userFullName);
     formData.append('UserPhone', this.user.userPhone);
     formData.append('userEmail', this.user.userEmail);
     let address=this.user.userAddress;
     if(this.registerForm.value.userEnter!=null && this.registerForm.value.userEnter!="")
     address+=" כניסה: "+this.registerForm.value.userEnter
     if(this.registerForm.value.userFloor!=null && this.registerForm.value.userFloor!="")
     address+=" קומה: "+this.registerForm.value.userFloor;
     if(this.registerForm.value.userNumberHoush!=null && this.registerForm.value.userNumberHoush!="")
     address+=" דירה: "+this.registerForm.value.userNumberHoush
     formData.append('userAddress', address);
     formData.append('ImageFile',this.file);
     formData.append('Latitude',this.user.userLatitude+"");
     formData.append('Longitude',this.user.userLongitude+"");
     this.userService.setUserDetails(formData).subscribe((res:any)=>{
      if(this.userEditProfile.userAddress!=this.user.userAddress || this.userEditProfile.userEmail!=this.user.userEmail
        || this.userEditProfile.userPhone!=this.user.userPhone || address!=this.userEditProfile.userAddress)
      {
        localStorage.SMS="";
        this.userService.setUserPhone(this.user.userPhone).subscribe((res:any)=>{
         });
      }
      else
    {
      this.proxy.showSucceeded("התעדכן נתוני המשתמש","");
        this.router.navigate(["../mainHome"]);
    }
      });
     }
}
}

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.registerForm.controls[fieldName].invalid;
  }

  isValidInputPhone(fieldName): boolean {
    if(this.valid==true)
    {
    if(this.registerForm.controls[fieldName].value == undefined || this.registerForm.controls[fieldName].value == "")
    this.errorPhone="שדה חובה"
    else
    this.errorPhone="עליך להכניס מספר טלפון שמתחיל בספרות  05xxxxxxxx";
   
    return this.registerForm.controls[fieldName].invalid;
    }
  }

  isValidInputMail(fieldName): boolean {
    if(this.valid==true)
    {
    if(this.registerForm.controls[fieldName].value == undefined || this.registerForm.controls[fieldName].value == "")
    this.errorMail="שדה חובה"
    else
    this.errorMail="כתובת מייל אינה תקינה";
    return this.registerForm.controls[fieldName].invalid;
    }
  }

  isValidInputPassword(fieldName): boolean {
    if(this.valid==true)
    {
    if(this.registerForm.controls[fieldName].value == undefined || this.registerForm.controls[fieldName].value == "")
    this.errorPassword="שדה חובה"
    else
    this.errorPassword="סיסמא צריכה להיות בין 4 ל-20 ספרות";
    return this.registerForm.controls[fieldName].invalid;
    }
  }

  isValidInputPasswordAgain(fieldName): boolean {
    if(this.isEditProfile==false)
    if(this.valid==true)
    {
    if(this.registerForm.controls[fieldName].value == undefined || this.registerForm.controls[fieldName].value == "")
    this.errorPasswordAgain="שדה חובה"
    else if(this.checkPasswords()==false)
    this.errorPasswordAgain="סיסמאות אינן זהות";
    return this.registerForm.controls[fieldName].invalid;
    }
  }

  checkPasswords()
  {
  let pass = this.registerForm.get('userPassword').value;
  let confirmPass = this.registerForm.get('userPasswordAgain').value
  return pass === confirmPass
  }

  createForm() {
if(this.isEditProfile==false)
    {
    this.registerForm = this.fb.group({ 
  userFullName: new FormControl("", Validators.required),
  userEmail: new FormControl("", [Validators.required, Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
  userPassword: new FormControl("", [
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(20),
  ]),
  userPasswordAgain: new FormControl("", [
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(20),
  ]),

  userPhone: new FormControl("", [Validators.required,Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]),
  userAddress: new FormControl("", Validators.required),
  userEnter: new FormControl(""),
  userFloor: new FormControl(""),
  userNumberHoush: new FormControl("")
}, { 
  validator: this.ConfirmedValidator('userPassword', 'userPasswordAgain')
});
    }
    else{
      this.registerForm = new FormGroup({
        userFullName: new FormControl("", Validators.required),
        userEmail: new FormControl("", [Validators.required, Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
       userPhone: new FormControl("", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),

        userAddress: new FormControl("", Validators.required),
        userEnter: new FormControl(""),
        userFloor: new FormControl(""),
        userNumberHoush: new FormControl("")
      });
    }   
}

ConfirmedValidator(controlName: string, matchingControlName: string){
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}

  ngOnInit(): void {
    this.createForm();
    this.mapsAPILoader.load().then(() => { 
      var options = {
        componentRestrictions: {country: "IL"}
       };
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,options);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.user.userLatitude = (place.geometry.location.lat());
          this.user.userLongitude =(place.geometry.location.lng());
          this.user.userAddress=place.name+" , "+place.vicinity;
          this.zoom = 12;
        }); 
      });
    });

    if(localStorage.ACCESS_TOKEN  != "null")
    {
  //   this.userService.getUserDetails(1).subscribe((res:any)=>{
  //     this.userEditProfile=res;
  //   if(this.userEditProfile!=null)
  //   {
  //     this.user.ImageFile=this.userEditProfile.ImageFile;
  //     this.user.IDUser=this.userEditProfile.IDUser;
  //     this.user.userFullName=this.userEditProfile.userFullName;
  //     this.user.userPhone=this.userEditProfile.userPhone;
  //     this.user.userAddress=this.userEditProfile.userAddress;
  //     this.user.userEmail=this.userEditProfile.userEmail;
  //     this.user.userLatitude = this.userEditProfile.Latitude;
  //     this.user.userLongitude =this.userEditProfile.Longitude;
  //     this.isEditProfile=true;
  //     if(this.userEditProfile.ImageFile!="" && this.userEditProfile.ImageFile!=null)
  //     this.url=this.userEditProfile.ImageFile;
  //   }
  //   this.createForm();
  // });
}
  }}

export class FileUplodVM{
  ImageBaseData: string;
}
