<div style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold;">
    <form class="form-signin"> 
      <div class="form-group">
      <div style="text-align: center; color: #41c777; font-size: 25px; margin-top: 25px;">הזמנה פתוחה</div>
      <br>
      <br>
      <div style="padding: 15px; text-align: center; color: black; font-size: 18px;">יש לך הזמנה פתוחה </div>
        <div style="padding: 15px; text-align: center; color: black;
        font-size: 18px;">?האם אתה מעוניין להמשיך לתשלום או להתחיל מחדש</div>
                <br> 
                  <div  class="btm" style="font-size: 15px;">
                    <p (click)="end()"  class="p" style="cursor: pointer;color: #41c777; font-weight: bold ;">המשך לתשלום</p>
                    <p (click)="cancel()" class="p" style="right: 62%; cursor: pointer; color: black; font-weight: bold ;">להתחיל מחדש</p>
                  </div>              
                  </div>         
    </form>
  </div>





