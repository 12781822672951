import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginComponent } from '../app/components/login/login.component' 
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showFooter:boolean=true;
  login:boolean=true;
  static priceTotal="פריטים-0"
  static price=0;

  // constructor( private location: Location) { }

  constructor(private router: Router,private dataService: DataService) { }
 // isMainHome = true
  // ngOnInit() {
  //   debugger
  //   if(this.location.pathname =='/')
  //     this.isMainHome = true
  //   else
  //     this.isMainHome = false
  //    
  // }
  ngOnInit() {
    //localStorage.ACCESS_TOKEN  ="56134DF2-C779-49A5-A8EA-90693C611268"
   // localStorage.ACCESS_TOKEN  = "null";
    let a=location.hash;
    // if(localStorage.PAGE!="")
    // {
    //   this.router.navigate(["../"+localStorage.PAGE]);  
    // }
    // else
     if(a=="#/mainHome" || a=="#/"  || a=="")
  {
   // this.dataService.state.next("התחבר");
    this.router.navigate(["../mainHome"]);
  }
    
  // this.bussinssService.getLastMenu();
    else
  {
    let b=a.split('/',2);
    this.router.navigate(["../"+b[1]]);
  }
  }
}
