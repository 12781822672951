import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Search } from 'src/app/models/restaurant';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
@Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
searchList:Search[];
  
constructor(private router: Router, private dataService:DataService, private bussinssService:BussinssService) { }

  ngOnInit(): void {
    this.bussinssService.getGlobalSearch().subscribe((res:any)=>{
      this.searchList=res;
    });
    this.dataService.search.next("");
    this.changByFood.emit();
    this.dataService.byFood.next(false);
    this.changByFood.emit();
  };

  category(c:string){
    localStorage.TEXT_SEARCH=c;
    localStorage.TEXT=c;
    localStorage.ID="0";
    this.router.navigate(['../restaurantCategory']);
  }
}
