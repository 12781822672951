import { Component, OnInit ,EventEmitter  ,Output ,HostListener ,NgZone, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router ,ActivatedRoute, NavigationEnd} from '@angular/router';
import { fromEvent, Observable, Subscription } from "rxjs";
import { CategoryItem,Category,} from 'src/app/models/category';
import { AdditonOrder, MyOrder, OrderAdding, OrderHistory } from 'src/app/models/order';
import { ChangeFood, ToppingsItem, ToppingsItemFood ,Restaurant ,RestaurantItem} from 'src/app/models/restaurant';
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { BussinssService } from 'src/app/services/bussinss-service';
import { PlatformLocation ,LocationStrategy} from '@angular/common';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-topping2',
  templateUrl: './topping2.component.html',
  styleUrls: ['./topping2.component.css']
})
export class Topping2Component implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()header: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('list')public listElementRef: ElementRef;
  @ViewChild('inputas',{static: true}) inputas: ElementRef;

  buttonText="המשך לתשלום"
  continued="המשך"
  continuedNum=0;
  selected:any="לחץ לבחירה";
  filtered :any;
  totalItems:number=0;
  totalAmount:number=0;
  totalFoodAmount:number=0;
  toppings:OrderAdding[]=[];
  toppingsSelect:OrderAdding[];
  toppingsPage:OrderAdding[]=[];
  categores:Category[];
  categoresItem:CategoryItem[];
  restaurantItem:RestaurantItem[];
  toppingsItemFood:ToppingsItemFood[];
  toppingsItemFood1:ToppingsItemFood[];
  toppingsItemFood2:ToppingsItemFood[];
  toppingsItemFood3:ToppingsItemFood[];
  toppingsItemFood4:ToppingsItemFood[];
  toppingsItemFood5:ToppingsItemFood[];
  toppingsItemFood6:ToppingsItemFood[];
  toppingsItemFood7:ToppingsItemFood[];
  toppingsItemFood8:ToppingsItemFood[];
  toppingsItemFood9:ToppingsItemFood[];
  toppingsItem:ToppingsItem[];
  toppingsItem1:ToppingsItem[];
  toppingsItem2:ToppingsItem[];
  toppingsItem3:ToppingsItem[];
  toppingsItem4:ToppingsItem[];
  toppingsItem5:ToppingsItem[];
  toppingsItem6:ToppingsItem[];
  toppingsItem7:ToppingsItem[];
  restaurant:Restaurant;
  ordersToShow:OrderHistory[]= new Array();
  orders:OrderHistory[]= new Array();
  foodName:string;
  foodDescription:string;
  ImageFood:string;
  position=4;
  public positionPage=1;
  selectedItem="";
  pos1:number;
  posi2:number;
  private selectOption: any;
  priceTotal="0-פריטים";
  clickToPay="לחץ לתשלום - ";
  priceAdding=0;
  order:MyOrder;
  Image="";
  Image400="";
  heightAdding:number;
  heightAdding2:number;
  additonOrder:AdditonOrder;
  AdditionList;
  AdditionList2;
  countNumber;
  TypeID;
  TypeID2=null;
  TypeID3=null;
  TypeID4=null;
  id=0;
  num=4;
  scrollList:number=4;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  pointOff="assets/images/point_off.png";
  pointOn="assets/images/point_on.png";
  checkboxOn="assets/images/chack_box_on.png";
  checkboxOff="assets/images/chack_box_off.png"
  listLength:number;
  full=false;
  innerWidth: any;
  innerHeight: any;
  innerHeight2: any;
  inviteName:string="";
  inviteName2:string="";
   loc=-1;
   mySubscription;
   key;
  renderer: Renderer2;
  top=0;
  height1=0;
  height2=0;
  margintop1=0;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
 this.back();
  }

  constructor(private zone: NgZone, private locationStrategy: LocationStrategy,private router: Router ,private dataService:DataService,location: PlatformLocation,private orderService:OrdersService,private bussinssService: BussinssService,private proxy: AppProxy,private route: ActivatedRoute) 
  {  
//   history.pushState(null, null, window.location.href);  
//    this.locationStrategy.onPopState(() => {
//   history.pushState(null, null, window.location.href);
// });
// this.router.routeReuseStrategy.shouldReuseRoute = () => false;

}

  ngOnInit(): void {
    this.preventBackButton()
    this.position=4;
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.dataService.notEndToppings=true;
   // this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=this.orderService.order;
      console.log("getOrderDetails10","getOrderDetails");   
     if(this.order.list.length==0)
     this.router.navigate(['../restaurantCategory']);
     else
     {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
      this.innerHeight = Number(this.innerHeight-100);
      this.innerHeight2 = Number(this.innerHeight-294);
      localStorage.PAGE_BACK="";
      this.dataService.byFood.next(false);
      this.changByFood.emit();
      this.dataService.show.next(false);
      this.changeShowPrice.emit();
      window.scroll(0,0);
      this.priceAdding=0;
      this.posi2=localStorage.position;
      this.pos1=localStorage.pos;
      // this.pos1= this.route.snapshot.params.pos;
      // this.posi2= this.route.snapshot.params.position;
      this.loc=-1;
      for(let y=0;y<this.order.list.length;y++)
      {
      if(this.order.list[y].MenuID==localStorage.MENU_ID)
      {
      this.loc=y;
      y=this.order.list.length;
      }
      }
      if(Number(this.posi2)>1)
      this.loc=Number(this.loc)+Number(this.posi2)-1;
      if(this.order.list[this.loc].InviteName!=null && this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].listAddition.length>0)
      this.inviteName=this.order.list[this.loc].InviteName;
      if(this.order.list[this.loc]!=null && this.order.list[this.loc].listAddition!=null && this.order.list[this.loc].listAddition.length>0)
      this.full=true;
      this.totalAmount=this.order.list[this.loc].Price;
      this.totalFoodAmount=this.order.list[this.loc].Price;
   for(let w=0;w<this.order.list[this.loc].listAddition.length;w++)
   {
    for(let a=0;a<this.order.list[this.loc].listAddition[w].listA.length;a++)
    {
    this.totalAmount=Number(this.totalAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
   }
  }
      
      
 if(this.orderService.restaurant!=null)
   {
 this.restaurant=this.orderService.restaurant;
 if(this.restaurant.LongImage!=null)
 this.Image=this.restaurant.LongImage;
   }
  {
    this.orderService.getOrderAdditon1(localStorage.MENU_ID,Number(this.posi2),this.pos1,this.posi2).subscribe((res:any)=>{
      this.additonOrder=res;
      if(this.additonOrder.ImgFile!=null)
      this.Image400=this.order.list[this.loc].BigImgFile;
      this.orderService.orderAddingList=this.additonOrder.list;
      this.toppings=this.additonOrder.list;
      for(let j=0;j<this.toppings.length;j++)
      {
        for(let m=0;m<this.toppings[j].listA.length;m++)
        if(this.toppings[j].listA[m].Price!=null && this.toppings[j].listA[m].Price>0)
        this.toppings[j].listA[m].PriceText='(+ '+ '₪' + this.toppings[j].listA[m].Price +')' ;
      }
      if(this.toppings!=null)
         if(this.toppings.length<=this.num)
         {
       //   this.foodElementRef.nativeElement.focus();
         this.continued="סיום";
         this.continuedNum=1;
     
         // this.foodElementRef.nativeElement.focus;
         }
         else
         {
         this.continued="המשך";
         this.continuedNum=0;
         }
      this.listLength=Number(this.toppings.length/2);
      if(Number(Number(this.toppings.length/2)%2)!=0)
      this.listLength=Number(Number(this.listLength)+1)-(Number(this.toppings.length/2)%2);
      this.foodName=this.additonOrder.Name;
      if(this.additonOrder.Description!="")
      this.foodDescription=this.additonOrder.Description;
      else
      this.foodDescription=this.additonOrder.Ingredients;
      this.ImageFood=this.additonOrder.ImgFile;
      let pos;
      if(this.toppings!=null && this.toppings.length>0 )
      {
        // for(let i=0;i<this.order.list.length;i++)
        // {
        //   if(this.order.list[i].MenuID==localStorage.MENU_ID)
        //   pos=i;
        // }
        this.toppingsPage.push(this.toppings[0]);
        this.height1=this.toppingsPage[0].listA.length;
       // this.toppingsPage[0].listA[0].Food="eee rrr ggg hhh ggg fff  hfghfg rgretg rtrrttr b fff fffff ff rr ffdr"
       // this.toppingsPage2.push(this.toppings[0]);
        if(this.toppings.length>1)
      {
        this.toppingsPage.push(this.toppings[1]);
        this.height1+=this.toppingsPage[1].listA.length;
        //this.toppingsPage2.push(this.toppings[1]);
      }
      if(this.num>2)
      {
        if(this.toppings.length>2)
        {
        this.toppingsPage.push(this.toppings[2]);
        this.height2=this.toppingsPage[2].listA.length;
        }
        if(this.toppings.length>3)
        {
        this.toppingsPage.push(this.toppings[3]);
        this.height2+=this.toppingsPage[3].listA.length;
        }
       
        if(this.height2>this.height1)
          this.margintop1=(this.height2)*10;
      }
        if(this.full==true)
        {
for(let m=0;m<this.toppingsPage.length;m++)
{
  for(let q=0;q<this.toppingsPage[m].listA.length;q++)
  {
    this.toppingsPage[m].listA[q].PriceTotal=this.toppingsPage[m].listA[q].Price;
    let count= Number(Number(this.toppingsPage[m].listA[q].CountChoose)- Number(this.toppingsPage[m].CountAdditionFree))
    this.toppingsPage[m].listA[q].PriceTotal=Number(Number(this.toppingsPage[m].listA[q].Price)*Number(count))
    if(this.toppingsPage[m].CountChoose==null || this.toppingsPage[m].CountChoose==undefined)
    this.toppingsPage[m].CountChoose=0;
    this.toppingsPage[m].CountChoose+=this.toppingsPage[m].listA[q].CountChoose;
    if(this.toppingsPage[m]!=null && this.toppingsPage[m].listA!=null && this.toppingsPage[m].listA[q].Price>0  && this.toppingsPage[m].listA[q].CountChoose>0)
{
    //if(this.toppingsPage[posi].listA[i].CountChoose>this.toppingsPage[posi].Count)
   // this.toppingsPage[posi].listA[i].CountChoose=this.toppingsPage[posi].Count;
// this.totalAmount=Number(this.totalAmount)+Number(this.toppingsPage[m].listA[q].Price);
// this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.toppingsPage[m].listA[q].Price);
if(this.toppingsPage[m].listA[q].PriceTotal==null)
this.toppingsPage[this.mySubscription].listA[q].PriceTotal=Number(this.toppingsPage[m].listA[q].Price);
else
{
if(this.toppingsPage[m].listA[q].PriceTotal==-1)
this.toppingsPage[m].listA[q].PriceTotal=0;
this.toppingsPage[m].listA[q].PriceTotal+=Number(this.toppingsPage[m].listA[q].Price);
}
  }
  }

  
}
      }
  
        this.position=this.toppingsPage.length;
       // this.position2=this.toppingsPage2.length;
        this.AdditionList="";
        this.AdditionList2="";
        // for(let j=0;j<this.toppingsPage.length;j++)
        // {
        //   for(let y=0;y<this.toppingsPage[j].listSelect.length;y++)
        //   for(let q=0;q<this.toppingsPage[j].listSelect[y].listA.length;q++)
        //   {
        //     if(this.toppingsPage[j].listSelect[y].listA[q].IsChoose==1)
        //     {
        //       this.toppingsPage[j].listSelect[y].Text=this.toppingsPage[j].listSelect[y].listA[q].Food;
        //      // this.toppings[j].listSelect[y].Select=true;
        //       this.selectItem(j,y,this.toppingsPage[j].listSelect[y].listA[q].Food,this.toppings[j].Type,q,this.toppings[j].TypeID);
        //     }
        //   }
        // }
        this.TypeID=this.toppingsPage[0].TypeID;
if(this.toppingsPage.length>1)
this.TypeID2=this.toppingsPage[1].TypeID;
if(this.toppingsPage.length>2)
this.TypeID3=this.toppingsPage[2].TypeID;
if(this.toppingsPage.length>3)
this.TypeID4=this.toppingsPage[3].TypeID;
     //  if(this.toppings[0]!=null)
        // && this.toppings[0].listSelect!=null && this.toppings[0].listSelect.length>0)

   this.heightAdding=0;
   this.heightAdding2=0;
 


   //if(this.toppings!=null)
  //  if(this.toppings.length<=this.num)
  //  this.continued="סיום";
   for(let j=0;j<this.toppingsPage.length;j++)
   this.toppingsPage[j].CountChoose=0;
  //  for(let i=0;i<this.toppingsPage[0].listSelect.length;i++)
  //  {
  //  if(this.heightAdding<Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48 && Number(this.heightAdding)<450)
  //      this.heightAdding=Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48;
  //      if(this.toppingsPage.length>1)
  //      if(this.heightAdding2<Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48 && Number(this.heightAdding2)<450)
  //      this.heightAdding2=Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48;
  //      if(Number(this.heightAdding)>450)
  //      this.heightAdding=450;
  //      if(Number(this.heightAdding2)>450)
  //      this.heightAdding2=450;
  //     }
    }
    
         if(this.restaurant==undefined)
    // this.bussinssService.getRestaurantDetails(this.order.RestaurantID,false,false,false,null,null).subscribe((res:any)=>{
     // console.log("getRestaurantDetails10","getRestaurantDetails");
      this.restaurant=this.bussinssService.restaurant;
       this.restaurantItem=this.restaurant.MenuList;
       for(let i=0; i<this.order.list.length;i++)
       {
         if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
         this.id=this.order.list[i].ID;
       }
   //  });
    
  });
  }
  if(this.restaurantItem!=null)
 for(let j=0;j<this.restaurantItem.length;j++)
 if(this.restaurantItem[j].Amount>0)
    {
    this.totalItems+=this.restaurantItem[j].Amount;
    this.totalAmount+=Number(this.totalItems)* Number(this.restaurantItem[j].Price);
    }
    
   if(this.toppings!=null && this.toppings.length>0 )
   {
this.toppingsPage.push(this.toppings[0]);
if(this.toppings.length>1)
{
this.toppingsPage.push(this.toppings[1]);
}
if(this.toppings.length>2)
this.toppingsPage.push(this.toppings[2]);
if(this.toppings.length>3)
this.toppingsPage.push(this.toppings[3]);
this.heightAdding=0;
for(let j=0;j<this.toppingsPage.length;j++)
{
if(this.heightAdding<Number(Number(this.toppingsPage[j].Count)+2)*48 && Number(this.heightAdding)<450)
    this.heightAdding=Number(Number(this.toppingsPage[j].Count)+2)*48;
    if(Number(this.heightAdding)>450)
    this.heightAdding=450;
    if(Number(this.heightAdding2)>450)
    this.heightAdding2=450;
    this.toppingsPage[j].CountChoose=0;
   }}
  if(this.toppings!=null)

    this.categores=[
      {
        "Name": "אחרונות",
        "ID": 47,
        "categoryItems":this.categoresItem,
        isCheck:true,
        categoryType:0,
      },
      {
        "Name": "סלטים",
        "ID": 47,
        "categoryItems":this.categoresItem,
        isCheck:true,
        categoryType:1,  
      },
      { 
        "Name": "ראשונות",
        "ID": 1,
        "categoryItems":this.categoresItem,
        isCheck:false,
        categoryType:2,
      },  
    ];
    if(this.bussinssService.restaurant!=null)
    this.restaurant=this.bussinssService.restaurant;
    if(this.restaurant!=null && this.restaurant.LongImage!=null)
    this.Image=this.restaurant.LongImage;
  }
 // });
  }

  clickList(pos:number,position:number,toppingText:string,newValue)
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
    let posi=pos;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    for(let i=0;i< this.toppings.length;i++)
    {
      if(this.toppings[i].Type!=null)
       if(this.toppings[i].Type==this.toppingsPage[pos].Type)
       {
       posi=i;
       break
       }
      }
        let OrderAddingItem=this.toppings[posi].listSelect[position];
      //  if(OrderAddingItem.Text!="לחץ לבחירה" && OrderAddingItem.Text!=null && OrderAddingItem.Text!="")
        // for(let p=0;p<this.toppings[posi].listSelect[position].listA.length;p++)
        // {
        //      let orderAddingItemFood=this.toppings[posi].listSelect[position].listA[p];
        //      if(orderAddingItemFood.Food==OrderAddingItem.Text)
        //      {
        //       this.priceAdding=Number(orderAddingItemFood.Price);
        //      }
        // }
      }
  }

  selectItem_(pos:number,position:number,toppingText:string,Type:string,inde:number,TypeID:number)
  {
    if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
    let posi=pos;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    for(let i=0;i<this.toppings.length;i++)
    {
    if(this.toppings[i].Type==Type)
                  posi=i;
            }

    this.toppings[posi].listSelect[position].Text=toppingText;
   // if(inde!=null)
    //this.toppings[posi].listSelect[position].listA[inde].IsChoose=1;
    this.toppings[posi].listSelect[position].Select=true
    if(this.order!=null && this.order.list!=null)
    for(let i=0; i<this.order.list.length;i++)
    {
      if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
      this.id=this.order.list[i].ID;
    }
             let pos_=Number(this.posi2);
          let y=0;
          this.AdditionList ="";
          this.AdditionList2="";
          if(this.toppingsPage.length>0)
          for(let i=0;i<2;i++)
          if(this.toppingsPage.length>i)
             // for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
              {
                //  let OrderAddingItem=this.toppingsPage[i].Text;
                  //if(OrderAddingItem!="לחץ לבחירה")
                  for(let p=0;p<this.toppingsPage[i].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listA[p];
                       if (orderAddingItemFood.IsChoose==1)
                       {
                            y+=1;
                            if(y>1)
                                this.AdditionList +=",";
                            this.AdditionList += orderAddingItemFood.ID;
                            for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                            {
                              this.AdditionList +=",";
                              this.AdditionList += orderAddingItemFood.ID;
                            }
                            p=this.toppingsPage[i].listA.length;
                      }
                   }
                  }
                   y=0;
                  if(this.toppingsPage.length>2)
          for(let i=2;i<4;i++)
          if(this.toppingsPage.length>i)
            //  for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
              {
                 // let OrderAddingItem=this.toppingsPage[i].listSelect[j].Text;
                //  if(OrderAddingItem!="לחץ לבחירה")
                  for(let p=0;p<this.toppingsPage[i].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listA[p];     
                       if (orderAddingItemFood.IsChoose==1){
                            y+=1;
                            if(y>1)
                                this.AdditionList2 +=",";
                            this.AdditionList2 += orderAddingItemFood.ID;
                            for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                            {
                              this.AdditionList2 +=",";
                              this.AdditionList2 += orderAddingItemFood.ID;
                            }
                            p=this.toppingsPage[i].listA.length;
                      }
                   }
                  }
          let a:number=1;
          pos_=Number(pos_);
          if(this.toppingsPage.length==1)
          this.TypeID2=null;
         // this.TypeID4=null;
          if(posi>1)
          {
            if(posi%2==0)
            this.TypeID3=TypeID;
            else
            this.TypeID4=TypeID;
          }
          else
          if(posi%2==0)
          this.TypeID=TypeID;
          else
          this.TypeID2=TypeID;
          }
  }

  selectItem(pos:number,position:number,IsChoose:boolean)
  {
    let posi=pos;
    let TypeID=this.toppingsPage[pos].TypeID;
    if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
      let posi=pos;
      for(let i=0;i<this.toppings.length;i++)
      {
      if(this.toppings[i].TypeID==this.toppingsPage[pos].TypeID)
                    posi=i;
              }
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
   
if(IsChoose==true)
{
if(this.toppings[posi].listA[position].IsChoose==1)
{
this.toppings[posi].listA[position].IsChoose=0;
let count=this.toppings[posi].listA[position].CountChoose;

this.toppingsPage[pos].CountChoose-=this.toppings[posi].listA[position].CountChoose;
if(this.toppings[posi].IsFree==1 && count>=this.toppingsPage[pos].CountAdditionFree && this.toppingsPage[pos].CountChoose<this.toppingsPage[pos].CountAdditionFree)
{
  this.totalAmount-=Number(Number(this.toppings[posi].listA[position].Price)*count);
this.totalFoodAmount-=Number(Number(this.toppings[posi].listA[position].Price)*count);
if(this.totalAmount<this.order.list[this.loc].Price)
{
  this.totalAmount=this.order.list[this.loc].Price;
  this.totalFoodAmount=this.order.list[this.loc].Price;
}
}
if(this.toppings[posi].listA[position].Price>0 && (this.toppingsPage[pos].CountAdditionFree<=this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0))
{
  if(this.toppingsPage[pos].CountAdditionFree>0)
  {
this.toppings[posi].listA[position].PriceTotal=0;
  }
this.totalAmount-=Number(Number(this.toppings[posi].listA[position].Price)*count);
this.totalFoodAmount-=Number(Number(this.toppings[posi].listA[position].Price)*count);
}

this.toppings[posi].listA[position].CountChoose=0; 
}
else{
  this.toppingsPage[pos].CountChoose=0;
  for(let j=0;j<this.toppings.length;j++)
  {
  if(this.toppings[j].TypeID==this.toppingsPage[pos].TypeID)
                posi=j;
          }
          for(let m=0;m<this.toppings[posi].listA.length;m++)
          this.toppingsPage[pos].CountChoose+=this.toppingsPage[pos].listA[m].CountChoose;
          if(this.toppingsPage[pos].Count==1)
          {
            for(let m=0;m<this.toppingsPage[pos].listA.length;m++)
            {
              if(this.toppingsPage[pos].listA[m].IsChoose==1)
              {
          
              if(this.toppingsPage[pos].listA[m].Price>0)
              {
                this.totalAmount-=Number(this.toppingsPage[pos].listA[m].Price);
                this.totalFoodAmount-=Number(this.toppingsPage[pos].listA[m].Price);
              }
              }
              this.toppingsPage[pos].listA[m].IsChoose=0;
            }
            this.toppingsPage[pos].listA[position].IsChoose=1;
            this.toppingsPage[pos].listA[position].CountChoose=1;
            if(this.toppingsPage[pos].listA[position].Price>0 && (this.toppingsPage[pos].CountAdditionFree<=this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0 ) )
{
this.totalAmount=Number(this.totalAmount)+Number(this.toppingsPage[pos].listA[position].Price);
this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.toppingsPage[pos].listA[position].Price);
if(this.toppingsPage[pos].listA[position].PriceTotal==null)
this.toppingsPage[pos].listA[position].PriceTotal=Number(this.toppingsPage[pos].listA[position].Price);
else
{
  if(this.toppingsPage[pos].listA[position].PriceTotal==-1)
  this.toppingsPage[pos].listA[position].PriceTotal=0;
this.toppingsPage[pos].listA[position].PriceTotal+=Number(this.toppingsPage[pos].listA[position].Price);
}
          }
          this.toppingsPage[pos].CountChoose+=1;
          }
          else if(this.toppingsPage[pos].CountChoose==0 || (this.toppingsPage[pos].CountChoose<this.toppingsPage[pos].Count && this.toppingsPage[pos].listA!=null))
          {
           
this.toppingsPage[pos].listA[position].CountChoose+=1;
this.toppingsPage[pos].listA[position].IsChoose=1;
if(this.toppingsPage[pos].listA[position].Price>0 && (this.toppingsPage[pos].CountAdditionFree<=this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0 ) )
{
this.totalAmount=Number(this.totalAmount)+Number(this.toppingsPage[pos].listA[position].Price);
this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.toppingsPage[pos].listA[position].Price);
if(this.toppingsPage[pos].listA[position].PriceTotal==null)
this.toppingsPage[pos].listA[position].PriceTotal=Number(this.toppingsPage[pos].listA[position].Price);
else
{
  if(this.toppingsPage[pos].listA[position].PriceTotal==-1)
  this.toppingsPage[pos].listA[position].PriceTotal=0;
this.toppingsPage[pos].listA[position].PriceTotal+=Number(this.toppingsPage[pos].listA[position].Price);
}
          }
          this.toppingsPage[pos].CountChoose+=1;
        }
        else
        this.proxy.showError(" לא ניתן לבחור תוספת \n  נוספת","");
}
}
}
    if(this.order!=null && this.order.list!=null)
    for(let i=0; i<this.order.list.length;i++)
    {
      if(this.order.list[i]!=null && this.restaurant!=null)
      if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
      this.id=this.order.list[i].ID;
    }
             let pos_=Number(this.posi2);
          let y=0;
          this.AdditionList ="";
          this.AdditionList2="";
          if(this.toppingsPage.length>0)
          for(let i=0;i<2;i++)
          if(this.toppingsPage.length>i)
              {
                  for(let p=0;p<this.toppingsPage[i].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listA[p];
                      if (orderAddingItemFood.IsChoose==1)
                       {
                            y+=1;
                            if(y>1)
                                this.AdditionList +=",";
                            this.AdditionList += orderAddingItemFood.ID;
                            for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                            {
                              this.AdditionList +=",";
                              this.AdditionList += orderAddingItemFood.ID;
                            }
                      }
                   }
                  }
                   y=0;
                  if(this.toppingsPage.length>2)
          for(let i=2;i<4;i++)
          if(this.toppingsPage.length>i)
              {
                  for(let p=0;p<this.toppingsPage[i].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listA[p];     
                      if (orderAddingItemFood.IsChoose==1) {
                            y+=1;
                            if(y>1)
                                this.AdditionList2 +=",";
                            this.AdditionList2 += orderAddingItemFood.ID;
                             for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                            {
                              this.AdditionList2 +=",";
                              this.AdditionList2 += orderAddingItemFood.ID;
                            }
                      }
                   }
  //    if(this.TypeID2==null)
  //    this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
  //      this.getOrderDetails2();
  //    });
  //    else
  //    this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
  //      this.getOrderDetails2();
  //    });
  //  }
  //  else
  //  this.getOrderDetails2();
                  }
  //    if(this.TypeID2==null)
  //    this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
  //      this.getOrderDetails2();
  //    });
  //    else
  //    this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
  //      this.getOrderDetails2();
  //    });
  //  }
  //  else
  //  this.getOrderDetails2();
          let a:number=1;
          pos_=Number(pos_);
          if(this.toppingsPage.length==1)
          this.TypeID2=null;
          if(posi>1)
          {
            if(posi%2==0)
            this.TypeID3=TypeID;
            else
            this.TypeID4=TypeID;
          }
          else
          if(posi%2==0)
          this.TypeID=TypeID;
          else
          this.TypeID2=TypeID;     
  }

  plus(pos,i:number)
   {
    let posi=pos;
    this.toppingsPage[pos].CountChoose=0;
    for(let j=0;j<this.toppings.length;j++)
    {
    if(this.toppings[j].TypeID==this.toppingsPage[pos].TypeID)
                  posi=j;
            }
            for(let m=0;m<this.toppings[posi].listA.length;m++)
            this.toppingsPage[pos].CountChoose+=this.toppingsPage[pos].listA[m].CountChoose;
            if(this.toppingsPage[pos].CountChoose==0 || (this.toppingsPage[pos].CountChoose<this.toppingsPage[pos].Count && this.toppingsPage[pos].listA!=null))
            {
             
  this.toppingsPage[pos].listA[i].CountChoose+=1;
  this.toppingsPage[pos].listA[i].IsChoose=1;
  if(this.toppingsPage[pos].listA[i].Price>0 && (this.toppingsPage[pos].CountAdditionFree<=this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0 ) )
  {
  this.totalAmount=Number(this.totalAmount)+Number(this.toppingsPage[pos].listA[i].Price);
  this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.toppingsPage[pos].listA[i].Price);
  if(this.toppingsPage[pos].listA[i].PriceTotal==null)
  this.toppingsPage[pos].listA[i].PriceTotal=Number(this.toppingsPage[pos].listA[i].Price);
  else
  {
    if(this.toppingsPage[pos].listA[i].PriceTotal==-1)
    this.toppingsPage[pos].listA[i].PriceTotal=0;
  this.toppingsPage[pos].listA[i].PriceTotal+=Number(this.toppingsPage[pos].listA[i].Price);
  }
            }
            this.toppingsPage[pos].CountChoose+=1;
          }
          else
          this.proxy.showError(" לא ניתן לבחור תוספת \n  נוספת","");
  }

  minus(pos,i:number)
  {
    let posi=pos;
    for(let j=0;j<this.toppings.length;j++)
    {
    if(this.toppings[j].TypeID==this.toppingsPage[pos].TypeID)
                  posi=j;
            }
            if(this.toppingsPage[pos].listA[i].CountChoose>0)
            {
    this.toppingsPage[pos].listA[i].CountChoose-=1;
    if(this.toppingsPage[pos].listA[i].CountChoose<=0)
    this.toppings[pos].listA[i].IsChoose=0;
    if(this.toppingsPage[pos].listA[i].Price>0 && (this.toppingsPage[pos].CountAdditionFree<this.toppingsPage[pos].CountChoose || this.toppingsPage[pos].CountAdditionFree==0 || this.toppingsPage[pos].CountChoose==0) )
    {
      this.toppingsPage[pos].CountChoose-=1;
    this.totalAmount-=Number(this.toppingsPage[pos].listA[i].Price);
    this.totalFoodAmount-=Number(this.toppingsPage[pos].listA[i].Price);
    this.toppingsPage[pos].listA[i].PriceTotal-=Number(this.toppingsPage[pos].listA[i].Price);
    if(this.toppingsPage[pos].listA[i].CountChoose<=0)
    this.toppings[posi].listA[i].IsChoose=0;
            }
            else
            {
              this.toppingsPage[pos].CountChoose-=1;
            this.toppingsPage[pos].listA[i].PriceTotal=0;
            }
          }
  }

  changeOnFood(i,j,o)
  {
    // for(let p=0;p<this.toppings[i].listSelect[j].listA.length;p++)
    // {
    //   this.toppings[i].listSelect[j].listA[p].Select=false;
    // }
    // this.toppings[i].listSelect[j].listA[o].Select=true;
    // this.toppings[i].listSelect[j].Text=this.toppings[i].listSelect[j].listA[o].Food;
    this.toppings[i].listSelect[j].Select=false;
  }

  selectClick(i,j){ 
    for(let p=0;p<this.toppings[i].listSelect.length;p++)
    {
      this.toppings[i].listSelect[p].Select=false;
    }
    this.toppings[i].listSelect[j].Select=true;
  }

  payment(){
    this.dataService.show.next(false);
     this.changeShowPrice.emit();
    this.router.navigate(["../yourOrder"])
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }
  
  backPress()
  {
  if(this.toppingsPage.length>4)
  {
    this.positionPage=1;
  this.toppingsPage=[];
  let p;
  if(this.num==4)
  p=this.position-4;
  else
  p=this.position-2;
  this.position=p;
  //let p2;
  //p2=this.position2-2;
  //this.position2=p2;
  for(let j=0;j<4;j++)
  this.toppingsPage.push(this.orderService.restaurant.MenuList[this.pos1].ChangeFood[this.posi2].toppings[j]);
  }
  else
  if(this.selectRequired()==true)
    this.router.navigate(['/restaurant']);
    else
    this.proxy.showError("לא בחרת את כל \n שדות החובה","");
  }

  back()
  {
    if(this.toppings.length>this.num && this.positionPage>1)
    {
      this.positionPage-=1;
      this.toppingsPage=[];
      let p;
  if(this.position==this.toppings.length)
  {
    if(this.position%2==0)
    this.position-=6;
    else
    this.position-=5;
  }
      this.continuedNum=0;
      if(this.orderService.restaurant==null)
      this.orderService.restaurant=this.restaurant;
      for(let j=0;j<this.num;j++)
      this.toppingsPage.push(this.toppings[this.position+j]);
      this.continued="המשך"  
      this.listElementRef.nativeElement.scrollTo( 0, 0 );
  }
    else
  {
    let pos=this.order.list.length;
    pos=Number(Number(pos)-1);
    if(this.order.list.length>0)
    {
    let amount =this.order.list[pos].Count;
    amount=Number(Number(amount)-1);
    }
    this.totalItems=0;
    let p:number=0;
    if(this.order.list[pos]!=null && this.order.list[pos].Price!=null)
    p=this.order.list[pos].Price;
    this.totalAmount=(Number(this.totalAmount)- Number(p));
    let count=0;
    for(let i=0;i<this.order.list.length;i++)
    {
      if(this.order.list[i].MenuID==this.order.list[pos].MenuID)
       count+=1;
    }
    count-=1;
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null && this.orderService.restaurant.MenuList.length>this.pos1)
    this.orderService.restaurant.MenuList[this.pos1].Rate=count;
    // this.order.list[pos].Rate=this.order.list[pos].Count;
    // this.order.list[pos].Rate-=1;
  // this.totalAmount-= this.restaurantItem[i].Price;
  if(this.totalAmount>0)
  for(let i=0;i<this.order.list.length;i++)
  {
    if(Number(this.order.list[i].Rate)>0)
    this.totalItems+=Number(this.order.list[i].Rate);
  }
  // if(this.totalItems>0)
  // {
  //  this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
  //  localStorage.PRICE_TOTAL=this.priceTotal;
  //  this.dataService.price.next(this.priceTotal);
  //  this.changePrice.emit();
  // }
  //  else
  //  {
  //  this.totalAmount=0;
  //  this.totalFoodAmount=0;
  //  this.priceTotal="0 - פריטים";
  //  localStorage.PRICE=0;
  //  localStorage.PRICE_TOTAL=this.priceTotal;
  //  this.dataService.price.next(this.priceTotal);
  //  this.changePrice.emit();
  //  }
   localStorage.PRICE_TOTAL=this.priceTotal;

    let location:number;
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null
       && this.orderService.restaurant.MenuList.length>this.pos1 && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null)
    location = this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length-1;
    location-=1;
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null)
    this.orderService.restaurant.MenuList[this.pos1].ChangeFood.splice(this.posi2,1);
for(let i=0;i<this.order.list.length;i++)
{
  if(this.order.list[i].MenuID==this.orderService.restaurant.MenuList[this.pos1].ID)
  pos=i;
}
      if(this.order.list!=null && this.order.list.length!=0 && localStorage.edit=="false")
      {
    this.orderService.addToBagOrder(this.order.list[pos].ID,this.order.OrderID,this.order.list[pos].MenuNumber).subscribe((res:any)=>{
      if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length>location)
    delete this.orderService.restaurant.MenuList[this.pos1].ChangeFood[location]; 
    if(this.orderService.restaurant!=null &&  this.orderService.restaurant.MenuList!=null && this.orderService.restaurant.MenuList.length>this.pos1 && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null &&this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length>0 )
    this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length=location;
   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    console.log("getOrderDetails11","getOrderDetails");
    this.order=res;
    if(Number(this.order.TotalPrice)>0)
      {

        //naomi ניסיון לשנות
    this.priceTotal=this.clickToPay+" "+this.order.TotalPrice+" ₪";
    this.dataService.notEndToppings=false;
      }
    else
    {
       this.priceTotal="0 - פריטים";
       localStorage.PRICE=0;
    }
    localStorage.PRICE_TOTAL=this.priceTotal;

    //this.dataService.price.next(this.priceTotal);
   // this.changePrice.emit();
 // this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    //this.order=res;
      this.router.navigate(['../restaurant']);
   });
    });
  }
    else
    this.router.navigate(['../restaurant']);
    }
  }

  isBack()
  {
    this.router.navigate(['../restaurant']);
  }

  selectRequired2():boolean
  {
    let selectRequired=true;
    let requiredCount=0;
    for(let j=0;j<this.toppingsPage.length;j++)
       if(this.toppingsPage[j].IsRequired>0 )
       {
       selectRequired=true;
       requiredCount=0;
  
         for(let m=0;m<this.toppingsPage[j].listA.length;m++)
         if(this.toppingsPage[j].listA[m].IsChoose==1)
         requiredCount+=this.toppingsPage[j].listA[m].CountChoose; 
      
       if(this.toppingsPage[j]!=undefined)
       if(requiredCount<this.toppingsPage[j].RequiredCount)
       {
       selectRequired=false;
       j=this.toppingsPage.length;
       }
     }
     return selectRequired;
  }

  selectRequired():boolean
  {
    let selectRequired=true;

   let requiredCount=0;
    for(let j=0;j<this.toppingsPage.length;j++)
       if(this.toppingsPage[j].IsRequired>0 )
       {
       selectRequired=true;
       requiredCount=0;
  
         for(let m=0;m<this.toppingsPage[j].listA.length;m++)
         if(this.toppingsPage[j].listA[m].IsChoose==1)
         requiredCount+=this.toppingsPage[j].listA[m].CountChoose; 
      
       if(this.toppingsPage[j]!=undefined)
       if(requiredCount<this.toppingsPage[j].RequiredCount)
       {
       selectRequired=false;
       j=this.toppingsPage.length;
       }
    }
     return selectRequired;
  }

  continue()
  {
    if(this.id==0)
    for(let i=0; i<this.order.list.length;i++)
    {
      if(this.order.list[i].MenuID==localStorage.MENU_ID)
      this.id=this.order.list[i].ID;
    }
    // if(this.num==2)
    // {
    //  this.continue2();
    // }
    // else
    {
    this.heightAdding=0;
    this.heightAdding2=0;
    this.continued="סיום";
    this.continuedNum=1;
  if(this.selectRequired()==true)
  {
  if(this.toppings.length>this.position)
  {
    this.positionPage=2;
    let AdditionList = "";
    let AdditionList2="";
    let y=0;
    if(this.toppingsPage.length>0)
    for(let i=0;i<2;i++)
    if(this.toppingsPage.length>i)
            for(let p=0;p<this.toppingsPage[i].listA.length;p++)
            {
                 let orderAddingItemFood=this.toppingsPage[i].listA[p];
                 if (orderAddingItemFood.IsChoose==1)
                {
                      y+=1;
                      if(y>1)
                          AdditionList +=",";
                      AdditionList += orderAddingItemFood.ID;
                      if(orderAddingItemFood.CountChoose>1)
                      for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                      {
                        AdditionList +=",";
                        AdditionList += orderAddingItemFood.ID;
                      }
                }
            this.TypeID=this.toppingsPage[0].TypeID;
            if(this.toppingsPage.length>1)
            this.TypeID2=this.toppingsPage[1].TypeID;
            else
            this.TypeID2=null;
            }
             y=0;
            if(this.toppingsPage.length>2)
    for(let i=2;i<4;i++)
    if(this.toppingsPage.length>i)
            for(let p=0;p<this.toppingsPage[i].listA.length;p++)
            {
                 let orderAddingItemFood=this.toppingsPage[i].listA[p];
             if (orderAddingItemFood.IsChoose==1)
                 {
                      y+=1;
                      if(y>1)
                          AdditionList2 +=",";
                      AdditionList2 += orderAddingItemFood.ID;
                      if(orderAddingItemFood.CountChoose>1)
                      for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                      {
                        AdditionList2 +=",";
                        AdditionList2 += orderAddingItemFood.ID;
                      }
             }
            }
            this.TypeID3=this.toppingsPage[2].TypeID;
            if(this.toppingsPage.length>3)
            this.TypeID4=this.toppingsPage[3].TypeID;
      for(let i=0; i<this.orderService.order.list.length;i++)
      {
        if(this.orderService.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
        this.id=this.orderService.order.list[i].ID;
      }

      if(this.id==0 || this.id==null)
      this.id=Number(localStorage.MENU_ID)
      if(AdditionList!="")
      this.AdditionList=AdditionList;
      if(AdditionList2!="")
        this.AdditionList2=AdditionList2;
{

  if(this.TypeID2==null)
  this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
    if((this.inviteName!="" && this.inviteName!=null )|| (this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].InviteName!=null))
    {
   this.orderService.setInviteName(this.id,Number(this.posi2),this.inviteName).subscribe((res:any)=>{
   this.getOrderDetails(false);
   });
  }
  else
  this.getOrderDetails(false);
  });
  else
  this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
    if(this.toppings.length>3 && this.AdditionList2!="")
    {
    this.orderService.setOrderAddition2(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2),this.TypeID4).subscribe((res:any)=>{
      if((this.inviteName!="" && this.inviteName!=null )|| (this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].InviteName!=null))
      {
     this.orderService.setInviteName(this.id,Number(this.posi2),this.inviteName).subscribe((res:any)=>{
     this.getOrderDetails2(false);
     });
    }
    else
    this.getOrderDetails2(false);
  });
}
  else if(this.toppings.length>2 && this.AdditionList2!="")
  {
  this.orderService.setOrderAddition(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2)).subscribe((res:any)=>{
    if((this.inviteName!="" && this.inviteName!=null )|| (this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].InviteName!=null))
    {
   this.orderService.setInviteName(this.id,Number(this.posi2),this.inviteName).subscribe((res:any)=>{
   this.getOrderDetails2(false);
   });
  }
  else
  this.getOrderDetails2(false);
  });
}
  else 
  if((this.inviteName!="" && this.inviteName!=null )|| (this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].InviteName!=null))
  {
 this.orderService.setInviteName(this.id,Number(this.posi2),this.inviteName).subscribe((res:any)=>{
 this.getOrderDetails(false);
 });
}
else
this.getOrderDetails(false);
});
}
  }
  else{
    
    let AdditionList = "";
    let AdditionList2 = "";
    let y=0;
    if(this.toppingsPage.length>0)
    for(let i=0;i<2;i++)
    if(this.toppingsPage.length>i)
            for(let p=0;p<this.toppingsPage[i].listA.length;p++)
            {
                 let orderAddingItemFood=this.toppingsPage[i].listA[p];
            if (orderAddingItemFood.IsChoose==1)
              {
                      y+=1;
                      if(y>1)
                          AdditionList +=",";
                      AdditionList += orderAddingItemFood.ID;
                      if(orderAddingItemFood.CountChoose>1)
                      for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                      {
                        AdditionList +=",";
                        AdditionList += orderAddingItemFood.ID;
                      }
               }
           }
           this.TypeID=this.toppingsPage[0].TypeID;
            if(this.toppingsPage.length>1)
            this.TypeID2=this.toppingsPage[1].TypeID;
            else
            this.TypeID2=null;
             y=0;
            if(this.toppingsPage.length>2)
    for(let i=2;i<4;i++)
    if(this.toppingsPage.length>i)
            for(let p=0;p<this.toppingsPage[i].listA.length;p++)
            {
                 let orderAddingItemFood=this.toppingsPage[i].listA[p];
            if (orderAddingItemFood.IsChoose==1)
           {
                      y+=1;
                      if(y>1)
                          AdditionList2 +=",";
                      AdditionList2 += orderAddingItemFood.ID;
                      if(orderAddingItemFood.CountChoose>1)
                      for(let m=1;m<orderAddingItemFood.CountChoose;m++)
                      {
                        AdditionList2 +=",";
                        AdditionList2 += orderAddingItemFood.ID;
                      }
                }
             }
             if(this.toppingsPage[2]!=null)
             {
             this.TypeID3=this.toppingsPage[2].TypeID;
            if(this.toppingsPage.length>3)
            this.TypeID4=this.toppingsPage[3].TypeID;
            } 
          for(let i=0; i<this.orderService.order.list.length;i++)
      {
        if(this.orderService.order.list[i]!=null && this.restaurant!=null)
        if(this.orderService.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
        this.id=this.orderService.order.list[i].ID;
      }
  
      if(this.id==0 || this.id==null)
      this.id=Number(localStorage.MENU_ID)
            {
              this.AdditionList=AdditionList;
              if(AdditionList2!="")
              this.AdditionList2=AdditionList2;   
              if(this.TypeID2==null)
              this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
                if((this.inviteName!="" && this.inviteName!=null )|| (this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].InviteName!=null))
                {
                this.orderService.setInviteName(this.id,Number(this.posi2),this.inviteName).subscribe((res:any)=>{
                this.getOrderDetails(true);
               });
              }
              else
              this.getOrderDetails(true);
              });
              else
              this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
                if(this.toppingsPage.length>3 )
                this.orderService.setOrderAddition2(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2),this.TypeID4).subscribe((res:any)=>{
                  if((this.inviteName!="" && this.inviteName!=null )|| (this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].InviteName!=null))
    {
   this.orderService.setInviteName(this.id,Number(this.posi2),this.inviteName).subscribe((res:any)=>{
   this.getOrderDetails(true);
   });
  }
  else
  this.getOrderDetails(true);
              });
              else if(this.toppingsPage.length>2 && this.AdditionList2!="")
              this.orderService.setOrderAddition(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2)).subscribe((res:any)=>{
                if((this.inviteName!="" && this.inviteName!=null )|| (this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].InviteName!=null))
                {
               this.orderService.setInviteName(this.id,Number(this.posi2),this.inviteName).subscribe((res:any)=>{
                this.getOrderDetails(true);
                });
              }
              else
              this.getOrderDetails(true);
              });
              else 
              if((this.inviteName!="" && this.inviteName!=null )|| (this.order.list[this.loc].InviteName!="" && this.order.list[this.loc].InviteName!=null))
              {
             this.orderService.setInviteName(this.id,Number(this.posi2),this.inviteName).subscribe((res:any)=>{
             this.getOrderDetails(true);
             });
            }
            else
            this.getOrderDetails(true);
            });
            }
        }
}
else
{
this.proxy.showError("לא בחרת את כל \n שדות החובה","");
this.heightAdding=0;
this.heightAdding2=0;
for(let j=0;j<this.toppingsPage.length;j++)
{
  for(let q=0;q<this.toppingsPage[j].listA.length;q++)
  {
    if(this.toppingsPage[j].listA[q].IsChoose==1)
      this.selectItem(j,q,false);
  }
}
this.TypeID=this.toppingsPage[0].TypeID;
if(this.toppingsPage.length>1)
this.TypeID2=this.toppingsPage[1].TypeID;
else
this.TypeID2=null;
}
    }
   }

   getOrderDetails(back:boolean)
{
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
    console.log("getOrderDetails13",this.order.TotalPrice+"");
    console.log("getOrderDetails14",this.totalAmount);
    this.totalFoodAmount=Number(this.order.list[this.loc].Price);
    console.log("getOrderDetails15",this.totalFoodAmount);
    let price=Number(Number(this.order.TotalPrice)+Number(Number(this.totalAmount)-Number(this.totalFoodAmount)));
    //this.orderService.order.TotalPrice=this.totalAmount+"";
   // this.order=this.orderService.order;

   // this.totalAmount=Number(this.order.TotalPrice);
   price=Number(this.orderService.order.TotalPrice);


    console.log("getOrderDetails16",price);
    //this.orderService.order.TotalPrice=price+"";
    localStorage.PRICE=price;
    this.priceTotal=this.clickToPay+price+" ₪";
    this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();
    for(let w=0;w<this.order.list[this.loc].listAddition.length;w++)
    {
     for(let a=0;a<this.order.list[this.loc].listAddition[w].listA.length;a++)
     {
   
 this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
    }
    }
 

    if(this.toppings.length>4 && this.toppings.length!=this.position)
  {
    //this.foodElementRef.nativeElement.focus();
    this.continued="סיום"
    this.continuedNum=1;
   
   this.orderService.getOrderAdditon1(localStorage.MENU_ID,Number(this.posi2),this.pos1,this.posi2).subscribe((res:any)=>{
    this.additonOrder=res;
    this.toppings=this.orderService.orderAddingList;
    for(let j=0;j<this.toppings.length;j++)
    {
      for(let m=0;m<this.toppings[j].listA.length;m++)
      if(this.toppings[j].listA[m].Price!=null && this.toppings[j].listA[m].Price>0)
      this.toppings[j].listA[m].PriceText='(+ '+ '₪' + this.toppings[j].listA[m].Price +')' ;
    }
  this.toppingsPage=[];
    this.toppingsPage.push(this.toppings[4]);
    // this.toppingsPage2=[];
    // this.toppingsPage2.push(this.toppings[1]);
    // this.position2=3;
    this.position=5;
    if(this.toppings.length>=5)
    {
    
      if(this.toppings[5]!=undefined)
      {
    this.toppingsPage.push(this.toppings[this.position]);
    this.position=6;
      }
    if(this.toppings.length>=6)
    {
      if(this.toppings[6]!=undefined)
      {
    this.toppingsPage.push(this.toppings[this.position]);
    this.position=7;
      }
    }
    if(this.full==true)
    {
for(let m=0;m<this.toppingsPage.length;m++)
{
for(let q=0;q<this.toppingsPage[m].listA.length;q++)
{
this.toppingsPage[m].listA[q].PriceTotal=this.toppingsPage[m].listA[q].Price;
let count= Number(Number(this.toppingsPage[m].listA[q].CountChoose)- Number(this.toppingsPage[m].CountAdditionFree))
this.toppingsPage[m].listA[q].PriceTotal=Number(Number(this.toppingsPage[m].listA[q].Price)*Number(count))
if(this.toppingsPage[m].CountChoose==null || this.toppingsPage[m].CountChoose==undefined)
this.toppingsPage[m].CountChoose=0;
this.toppingsPage[m].CountChoose+=this.toppingsPage[m].listA[q].CountChoose;
}
}
  }
    }
  if(this.toppingsPage.length>0)
  this.TypeID=this.toppingsPage[0].TypeID;
  if(this.toppingsPage.length>1)
  this.TypeID2=this.toppingsPage[1].TypeID;
  else
  this.TypeID2=null;
  });
}
  else
  {
    this.dataService.notEndToppings=false;
  this.router.navigate(['../restaurant']);
  }
  });
}

getOrderDetails2(back:boolean)
{
  this.listElementRef.nativeElement.scrollTo( 0, 0 );
  this.TypeID=this.toppingsPage[0].TypeID;
  if(this.toppingsPage.length>1)
  this.TypeID2=this.toppingsPage[1].TypeID;
  else
  this.TypeID2=null;
  //this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    console.log("getOrderDetails14","getOrderDetails");
    this.order=this.orderService.order;
    for(let j=0;j<this.toppingsPage.length;j++)
    {
      for(let q=0;q<this.toppingsPage[j].listA.length;q++)
      {
        if(this.toppingsPage[j].listA[q].IsChoose==1)
          this.selectItem(j,q,false);
      }
    }
    this.totalAmount=Number(this.order.TotalPrice);
    this.totalFoodAmount=Number(this.order.list[this.loc].Price);
    for(let w=0;w<this.order.list[this.loc].listAddition.length;w++)
    {
     for(let a=0;a<this.order.list[this.loc].listAddition[w].listA.length;a++)
     {
   
 this.totalFoodAmount=Number(this.totalFoodAmount)+Number(this.order.list[this.loc].listAddition[w].listA[a].Price);
    }
    }
    localStorage.PRICE=this.totalAmount;
    this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
    this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();
   this.orderService.getOrderAdditon1(localStorage.MENU_ID,Number(this.posi2),this.pos1,this.posi2).subscribe((res:any)=>{
    this.additonOrder=res;
    this.toppings=this.orderService.orderAddingList;
    for(let j=0;j<this.toppings.length;j++)
    {
      for(let m=0;m<this.toppings[j].listA.length;m++)
      if(this.toppings[j].listA[m].Price!=null && this.toppings[j].listA[m].Price>0)
      this.toppings[j].listA[m].PriceText='(+ '+ '₪' + this.toppings[j].listA[m].Price +')' ;
    }
  this.toppingsPage=[];
    this.toppingsPage.push(this.toppings[4]);
    this.position=5;
    if(this.toppings.length>=5)
    {
      if(this.toppings[5]!=undefined)
      {
    this.toppingsPage.push(this.toppings[this.position]);
    this.position=6;
      }
    if(this.toppings.length>=6)
    {
      if(this.toppings[6]!=undefined)
      {
    this.toppingsPage.push(this.toppings[this.position]);
    this.position=7;
      }
    }
    this.listElementRef.nativeElement.scrollTo( 0, 0 );
    if(this.full==true)
    {
for(let m=0;m<this.toppingsPage.length;m++)
{
for(let q=0;q<this.toppingsPage[m].listA.length;q++)
{
this.toppingsPage[m].listA[q].PriceTotal=this.toppingsPage[m].listA[q].Price;
let count= Number(Number(this.toppingsPage[m].listA[q].CountChoose)- Number(this.toppingsPage[m].CountAdditionFree))
this.toppingsPage[m].listA[q].PriceTotal=Number(Number(this.toppingsPage[m].listA[q].Price)*Number(count))
if(this.toppingsPage[m].CountChoose==null || this.toppingsPage[m].CountChoose==undefined)
this.toppingsPage[m].CountChoose=0;
this.toppingsPage[m].CountChoose+=this.toppingsPage[m].listA[q].CountChoose;
}
}
  }
    if(this.position>=this.toppings.length)
    {
    this.continued="סיום"
    this.continuedNum=1;
    }
    else
    {
      this.dataService.notEndToppings=false;
    this.router.navigate(['../restaurant']);
    }
    }
  if(this.toppingsPage.length>0)
  this.TypeID=this.toppingsPage[0].TypeID;
  if(this.toppingsPage.length>1)
  this.TypeID2=this.toppingsPage[1].TypeID;
  else
  this.TypeID2=null;
  });
 // });
}
}