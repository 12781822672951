<div *ngIf="categoryType==1" class="d-flex" [ngClass]="indexList!=0 ? 'marginText' : null" style="font-family: OpenSansHebrewRegular;
  font-weight: bold;text-align: right; margin-top: 15px;cursor: pointer;" (click)="onClickCategory(1)">
    <div class="m-auto"  >
      <div class="image1">
      <div style="overflow:hidden ; height: 80%;">
        <img style="height: 100%; width: 100% ; background-size: cover; background-size: 100% 100% ;background-repeat: no-repeat;  border-radius: 16px 16px 0px 0px;" [src]="categoryItem.ImageFile" >
        <img *ngIf="categoryItem.IsFreeShipping==1" src="assets/images/Delivery_Free_Stiker_Desktop_Web.png" class="float-sm-left" style="top: -380px;
        position: relative;">
      </div>
        <div style="width: auto; font-size: 13px; margin-right: 5px; margin-top: 25px;">   
    <span style="font-size: 1.75rem; font-weight: 550; margin-right: 15px; font-family: OpenSansHebrewBold;    margin-top: 4px;">{{categoryItem.Name}}</span>
    <span style="margin-top: 2px; margin-right: 4px; font-size: 1.6rem; font-weight: 540; ">- {{categoryItem.Category}}</span>
    <div *ngIf="categoryType==1" class="d-flex card-subtitle btn" style="margin-right: 2px; direction: rtl; position: relative; margin-top: 20px; display: flex; justify-content: flex-start;">
  <span style="font-size: 15px; font-family: OpenSansHebrewBold; margin-top: 4px; color: black;">{{categoryItem.Header}}</span>
  </div>
    </div>     
  <div >
  </div> 
  </div>
      </div>
  </div>
  <div *ngIf="category.categoryType==2" class="d-flex" [ngClass]="indexList!=0 ? 'marginText' : null" style="cursor: pointer;  font-weight: bold; text-align: right; margin-top: 15px;" (click)="onClickCategory(2)">
    <div class="m-auto"  >
      <div class="image2">
      <img style="width: 260px; height: 165px; border-radius: 10px 10px 0px 0px;" [src]="categoryItem.ImageFile" >
      <img *ngIf="categoryItem.IsFreeShipping==1" src="assets/images/Delivery_Free_Stiker_Desktop_Web.png" style="position: absolute;
      top: 0; left: 0;">
      <div class="card-deck">
        <div style="width: 100%;">
    <div style="font-size: 15px; margin-right: 25px;">{{categoryItem.Category}}</div>
    <div style="margin-top: 4px;  margin-right: 25px;" >{{categoryItem.CountRate}} מקומות</div>
    </div>    
  </div>
  <div >
  </div> 
  </div>
      </div>
  </div>
  
  
      
  
