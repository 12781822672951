import { Component, OnInit ,Inject, Output, EventEmitter } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/order-service';
import {MatDialog } from '@angular/material/dialog';
import { Router} from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AppProxy } from 'src/app/services/app-proxy';

@Component({
  selector: 'app-popup-creditSecure',
  templateUrl: './popup-creditSecure.component.html',
  styleUrls: ['./popup-creditSecure.component.css']
})
export class PopupCreditSecureComponent implements OnInit {

  linkForSecure:string="";
  @Output()change: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(  private proxy:AppProxy ,@Inject(MAT_DIALOG_DATA) private data: {linkForSecure: string}, private matDialog:MatDialog , private clipboard: Clipboard ) { }

  ngOnInit(): void {
    this.linkForSecure = this.data.linkForSecure
  }
  copyMessage(){
    // const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    // selBox.value = this.linkForSecure;
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // let b = document.execCommand('copy' , true , 'copy');
    // document.body.removeChild(selBox);
this.proxy.showHTMLMessage("copy" , "")
    this.clipboard.copy(this.linkForSecure);
  }
  cancel(){
  
  }

  end()
  {
    this.matDialog.closeAll();
    //localStorage.ID=localStorage.ID_CATEGORY;
   
  }

  close()
  {
    this.matDialog.closeAll();
  }
}
