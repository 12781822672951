import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HistoryDelivery } from 'src/app/models/order';
import { OrdersService } from 'src/app/services/order-service';

@Component({
  selector: 'app-shipping-history',
  templateUrl: './shipping-history.component.html',
  styleUrls: ['./shipping-history.component.css']
})
export class ShippingHistoryComponent implements OnInit {
  orders:HistoryDelivery[]= new Array();
  filteredItems:HistoryDelivery[]= new Array();
  searchText:String;
  text: string;

  constructor( private router: Router, private route: ActivatedRoute,private orderService:OrdersService) { }

  ngOnInit(): void {
    this.orderService.getHistoryDelivery().subscribe((res:any)=>{
      this.orders=res;
      this.assignCopy();
  });
    this.orderService.getHistoryDelivery().subscribe;
    this.orders==this.orderService.historyDeliveryList;
    this.assignCopy();
    }

    assignCopy(){
      this.filteredItems = Object.assign([], this.orders);
   }
  
    filterItem(value){
      if(!value){
          this.assignCopy();
      } // when nothing has typed
      this.filteredItems = Object.assign([], this.orders).filter(
         item => item.Description.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
   }
}
