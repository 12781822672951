import { OrderAdditon } from "./restaurant";

export class Category {
    Name: string;
    ID: number;
    categoryItems:CategoryItem[];
    isCheck:boolean;
    categoryType:number;
}

export class CategoryItem {
    ID:number;
    RstaurantID:number;
    ImageFile:string;
    Name:string;
    Header:string;
    Category:string;
    Rate:number;
    CountRate:number;
    ImageSize:number;
    IsFevorite:number;
    toppingsList:OrderAdditon[];
    IsFreeShipping:number;
    MinimumOrder:string;
}

export class ListImages{
    runtimeDate:string;
    Image1:string;
    Image2:string;
    Image3:string;
    Image4:string;
    error:boolean;
    responseMessage:string;
}

export class ItemCategory{
    ID:number;
    ImgFile:string;
    Category:string;
    Count:number
}

export class FoodItem{
    price: number;
    foodName: string;
    amount: number;
}

export class AboutRestaurant
{
    aboutRestaurantId:number;
    aboutRestaurantName:string;
    isCheck:number;
}

