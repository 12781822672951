import { Component, OnInit ,EventEmitter  ,Output ,HostListener} from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { CategoryItem,Category,} from 'src/app/models/category';
import { AdditonOrder, MyOrder, OrderAdding, OrderHistory } from 'src/app/models/order';
import { ChangeFood, ToppingsItem, ToppingsItemFood ,Restaurant ,RestaurantItem} from 'src/app/models/restaurant';
import { AppProxy } from 'src/app/services/app-proxy';
import { OrdersService } from 'src/app/services/order-service';
import { BussinssService } from 'src/app/services/bussinss-service';
import { PlatformLocation ,LocationStrategy} from '@angular/common';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-toppings',
  templateUrl: './toppings.component.html',
  styleUrls: ['./toppings.component.css']
})
export class ToppingsComponent implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  buttonText="המשך לתשלום"
  continued="המשך"
  selected:any="ללא בחירה";
  filtered :any;
  totalItems:number=0;
  totalAmount:number=0;
  toppings:OrderAdding[];
  toppingsSelect:OrderAdding[];
  toppingsPage:OrderAdding[]=[];
  categores:Category[];
  categoresItem:CategoryItem[];
  restaurantItem:RestaurantItem[];
  toppingsItemFood:ToppingsItemFood[];
  toppingsItemFood1:ToppingsItemFood[];
  toppingsItemFood2:ToppingsItemFood[];
  toppingsItemFood3:ToppingsItemFood[];
  toppingsItemFood4:ToppingsItemFood[];
  toppingsItemFood5:ToppingsItemFood[];
  toppingsItemFood6:ToppingsItemFood[];
  toppingsItemFood7:ToppingsItemFood[];
  toppingsItemFood8:ToppingsItemFood[];
  toppingsItemFood9:ToppingsItemFood[];
  toppingsItem:ToppingsItem[];
  toppingsItem1:ToppingsItem[];
  toppingsItem2:ToppingsItem[];
  toppingsItem3:ToppingsItem[];
  toppingsItem4:ToppingsItem[];
  toppingsItem5:ToppingsItem[];
  toppingsItem6:ToppingsItem[];
  toppingsItem7:ToppingsItem[];
  restaurant:Restaurant;
  ordersToShow:OrderHistory[]= new Array();
  orders:OrderHistory[]= new Array();
  foodName:string;
  foodDescription:string;
  ImageFood:string;
  position=4;
  public positionPage=2;
  selectedItem="";
  pos1:number;
  posi2:number;
  private selectOption: any;
  priceTotal="0-פריטים";
  clickToPay="לחץ לתשלום - ";
  priceAdding=0;
  order:MyOrder;
  Image="";
  heightAdding:number;
  heightAdding2:number;
  additonOrder:AdditonOrder;
  AdditionList;
  AdditionList2;
  countNumber;
  TypeID;
  TypeID2=null;
  TypeID3=null;
  TypeID4=null;
  id=0;
  totalFoodAmount:number=0;

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.back();
  // }
   
  constructor( private locationStrategy: LocationStrategy,private router: Router ,private dataService:DataService,location: PlatformLocation,private orderService:OrdersService,private bussinssService: BussinssService,private proxy: AppProxy,private route: ActivatedRoute) 
  {  
//   history.pushState(null, null, window.location.href);  
// this.locationStrategy.onPopState(() => {
//   history.pushState(null, null, window.location.href);
// });
}

ngOnInit(): void {
  localStorage.PAGE_BACK="";
  this.dataService.show.next(false);
  this.changeShowPrice.emit();
  window.scroll(0,0);
  this.priceAdding=0;
  this.pos1= this.route.snapshot.params.pos;
  this.posi2= this.route.snapshot.params.position;
  this.posi2=localStorage.position;
  this.pos1=localStorage.pos;
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
if(this.orderService.restaurant!=null)
 {
this.restaurant=this.orderService.restaurant;
if(this.restaurant.LongImage!=null)
this.Image=this.restaurant.LongImage;
 }
{
  this.orderService.getOrderAdditon(localStorage.MENU_ID,Number(this.posi2),this.pos1,this.posi2).subscribe((res:any)=>{
    this.additonOrder=res;
    if(this.additonOrder.ImgFile!=null)
    this.orderService.orderAddingList=this.additonOrder.list;
    this.toppings=this.orderService.orderAddingList;
    let num=4;
            if(this.toppings!=null)
            if(this.toppings.length<=num)
            this.continued="סיום";
            else
            this.continued="המשך";
    this.foodName=this.additonOrder.Name;
    if(this.additonOrder.Description!="")
    this.foodDescription=this.additonOrder.Description;
    else this.foodDescription=this.additonOrder.Ingredients;
    this.ImageFood=this.additonOrder.ImgFile;
    let pos;
    if(this.toppings!=null && this.toppings.length>0 )
    {
      // for(let i=0;i<this.order.list.length;i++)
      // {
      //   if(this.order.list[i].MenuID==localStorage.MENU_ID)
      //   pos=i;
      // }
      this.toppingsPage.push(this.toppings[0]);
      if(this.toppings.length>1)
      this.toppingsPage.push(this.toppings[1]);
      if(this.toppings.length>2)
      this.toppingsPage.push(this.toppings[2]);
      if(this.toppings.length>3)
      this.toppingsPage.push(this.toppings[3]);
      this.position=this.toppingsPage.length;
      this.AdditionList="";
      this.AdditionList2="";
      for(let j=0;j<this.toppingsPage.length;j++)
      {
        for(let y=0;y<this.toppingsPage[j].listSelect.length;y++)
        for(let q=0;q<this.toppingsPage[j].listSelect[y].listA.length;q++)
        {
          if(this.toppingsPage[j].listSelect[y].listA[q].IsChoose==1)
          {
            this.toppingsPage[j].listSelect[y].Text=this.toppingsPage[j].listSelect[y].listA[q].Food;
           // this.toppings[j].listSelect[y].Select=true;
            this.selectItem(j,y,this.toppingsPage[j].listSelect[y].listA[q].Food,this.toppings[j].Type,q,this.toppings[j].TypeID);
          }
        }
      }
      this.TypeID=this.toppingsPage[0].TypeID;
if(this.toppingsPage.length>1)
this.TypeID2=this.toppingsPage[1].TypeID;
if(this.toppingsPage.length>2)
this.TypeID3=this.toppingsPage[2].TypeID;
if(this.toppingsPage.length>3)
this.TypeID4=this.toppingsPage[3].TypeID;
   //  if(this.toppings[0]!=null)
      // && this.toppings[0].listSelect!=null && this.toppings[0].listSelect.length>0)

 this.heightAdding=0;
 this.heightAdding2=0;

// for(let j=0;j<this.toppingsPage.length;j++)
 for(let i=0;i<this.toppingsPage[0].listSelect.length;i++)
 {
 if(this.heightAdding<Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48 && Number(this.heightAdding)<450)
     this.heightAdding=Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48;
     if(this.toppingsPage.length>1 &&this.toppingsPage[1].listSelect[i]!=null &&this.toppingsPage[1].listSelect[i].listA!=null)
     if(this.heightAdding2<Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48 && Number(this.heightAdding2)<450)
     this.heightAdding2=Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48;
     if(Number(this.heightAdding)>450)
     this.heightAdding=450;
     if(Number(this.heightAdding2)>450)
     this.heightAdding2=450;
    }}
   
        this.categores=[
          {
            "Name": "אחרונות",
            "ID": 47,
            "categoryItems":this.categoresItem,
            isCheck:true,
            categoryType:0,
          },
          {
            "Name": "סלטים",
            "ID": 47,
            "categoryItems":this.categoresItem,
            isCheck:true,
            categoryType:1,  
          },
          { 
            "Name": "ראשונות",
            "ID": 1,
            "categoryItems":this.categoresItem,
            isCheck:false,
            categoryType:2,
          },  
        ];
//     this.bussinssService.getRestaurantDetails(this.order.RestaurantID,false,false,false,null,null).subscribe((res:any)=>{
//       this.restaurant=res;
//       this.restaurantItem=this.restaurant.MenuList;
//       if(this.restaurantItem!=null)
//       {
//         if(this.restaurantItem[this.pos1]!=null)
//  if(this.restaurantItem[this.pos1].ChangeFood!=null)
//  if(this.restaurantItem[this.pos1].ChangeFood[this.posi2]!=null)
//  if(this.restaurantItem[this.pos1].ChangeFood[this.posi2].toppings!=null)
//  {
//   this.foodName=this.restaurantItem[this.pos1].ChangeFood[this.posi2].Name;
// this.toppings=this.orderService.restaurant.MenuList[this.pos1].ChangeFood[this.posi2].toppings;
//  }
// else if(this.toppings==null)
// if(this.orderService.orderAddingList!=null && this.orderService.orderAddingList.length>0)
// {
//     this.toppings=this.orderService.orderAddingList;
//     if(this.bussinssService.restaurant!=null && this.bussinssService.restaurant.MenuList!=null)
//     this.restaurantItem=this.bussinssService.restaurant.MenuList;
// }
//       }
//     });
});
}
if(this.restaurantItem!=null)
for(let j=0;j< this.restaurantItem.length;j++)
if(this.restaurantItem[j].Amount>0)
  {
  this.totalItems+=this.restaurantItem[j].Amount;
  this.totalAmount+=Number(this.totalItems)* Number(this.restaurantItem[j].Price);
  this.totalFoodAmount+=Number(this.totalItems)* Number(this.restaurantItem[j].Price);
  }
 if(this.toppings!=null && this.toppings.length>0 )
 {
this.toppingsPage.push(this.toppings[0]);
if(this.toppings.length>1)
this.toppingsPage.push(this.toppings[1]);
if(this.toppings.length>2)
this.toppingsPage.push(this.toppings[2]);
if(this.toppings.length>3)
this.toppingsPage.push(this.toppings[3]);
this.heightAdding=0;
for(let j=0;j<this.toppingsPage.length;j++)
{
if(this.heightAdding<Number(Number(this.toppingsPage[j].Count)+2)*48 && Number(this.heightAdding)<450)
  this.heightAdding=Number(Number(this.toppingsPage[j].Count)+2)*48;
  if(Number(this.heightAdding)>450)
  this.heightAdding=450;
  if(Number(this.heightAdding2)>450)
  this.heightAdding2=450;
 }}
if(this.toppings!=null)
if(this.toppings.length<=4)
this.continued="סיום";
  this.categores=[
    {
      "Name": "אחרונות",
      "ID": 47,
      "categoryItems":this.categoresItem,
      isCheck:true,
      categoryType:0,
    },
    {
      "Name": "סלטים",
      "ID": 47,
      "categoryItems":this.categoresItem,
      isCheck:true,
      categoryType:1,  
    },
    { 
      "Name": "ראשונות",
      "ID": 1,
      "categoryItems":this.categoresItem,
      isCheck:false,
      categoryType:2,
    },  
  ];
  if(this.bussinssService.restaurant!=null)
  this.restaurant=this.bussinssService.restaurant;
  if(this.restaurant.LongImage!=null)
  this.Image=this.restaurant.LongImage;
  if(localStorage.PRICE>0 && localStorage.PRICE!=this.totalAmount)
  this.totalAmount=Number(localStorage.PRICE);
  this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
});
}

  clickList(pos:number,position:number,toppingText:string,newValue)
  {
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
   else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
    let posi=pos;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    for(let i=0;i< this.toppings.length;i++)
    {
      if(this.toppings[i].Type!=null)
       if(this.toppings[i].Type==this.toppingsPage[posi].Type)
       {
       posi=i;
       break
       }
      }
        let OrderAddingItem=this.toppings[posi].listSelect[position];
        if(OrderAddingItem.Text!=null && OrderAddingItem.Text!="" && OrderAddingItem.Text!="ללא בחירה" )
        for(let p=0;p<this.toppings[posi].listSelect[position].listA.length;p++)
        {
             let orderAddingItemFood=this.toppings[posi].listSelect[position].listA[p];
             if(orderAddingItemFood.Food==OrderAddingItem.Text)
             {
              this.priceAdding=Number(orderAddingItemFood.Price);
             }
        }
      }
  }

  selectItem(pos:number,position:number,toppingText:string,Type:string,inde:number,TypeID:number)
  {
    if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
    let posi=pos;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    //if(Type!=undefined)
    for(let i=0;i<this.toppings.length;i++)
    {
    if(this.toppings[i].TypeID==TypeID)
    {
                  posi=i;
    }
            }
          //  else
//if(this.position>4)
//posi=Number(Number(this.position-2)+pos)
if(this.toppings[posi].listSelect!=null)
          {
    this.toppings[posi].listSelect[position].Text=toppingText;
    for(let i=0;i<this.toppings[posi].listSelect[position].listA.length;i++)
    {
    if(this.toppings[posi].listSelect[position].listA[i].CountChoose==0)
    this.toppings[posi].listSelect[position].listA[i].IsChoose=0;
  }
          }

    if(inde!=null)
    this.toppings[posi].listSelect[position].listA[inde].IsChoose=1;
    this.toppings[posi].listSelect[position].Select=true
    if(this.order!=null && this.order.list!=null)
    for(let i=0; i<this.order.list.length;i++)
    {
      if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
      this.id=this.order.list[i].ID;
    }
             let pos_=Number(this.posi2);
          let y=0;
          this.AdditionList ="";
          this.AdditionList2="";
          if(this.toppingsPage.length>0)
          for(let i=0;i<2;i++)
          if(this.toppingsPage.length>i)
              for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
              {
                  let OrderAddingItem=this.toppingsPage[i].listSelect[j].Text;
                  if(OrderAddingItem!="ללא בחירה")
                  for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
                      if (orderAddingItemFood.Food==OrderAddingItem) {
                            y+=1;
                            if(y>1)
                                this.AdditionList +=",";
                            this.AdditionList += orderAddingItemFood.ID;
                            p=this.toppingsPage[i].listSelect[j].listA.length;
                      }
                   }
                  }
                   y=0;
                  if(this.toppingsPage.length>2)
          for(let i=2;i<4;i++)
          if(this.toppingsPage.length>i)
              for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
              {
                  let OrderAddingItem=this.toppingsPage[i].listSelect[j].Text;
                  if(OrderAddingItem!="ללא בחירה")
                  for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
                  {
                       let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];     
                      if (orderAddingItemFood.Food==OrderAddingItem) {
                            y+=1;
                            if(y>1)
                                this.AdditionList2 +=",";
                            this.AdditionList2 += orderAddingItemFood.ID;
                            p=this.toppingsPage[i].listSelect[j].listA.length;
                      }
                   }
                  }
          let a:number=1;
          pos_=Number(pos_);
          if(this.toppingsPage.length==1)
          this.TypeID2=null;
         // this.TypeID4=null;
          if(posi>1)
          {
            if(posi%2==0)
            this.TypeID3=TypeID;
            else
            this.TypeID4=TypeID;
          }
          else
          if(posi%2==0)
          this.TypeID=TypeID;
          else
          this.TypeID2=TypeID;
          }
  }

  changeOnFood(i,j,o)
  {
    for(let p=0;p<this.toppings[i].listSelect[j].listA.length;p++)
    {
      this.toppings[i].listSelect[j].listA[p].Select=false;
    }
    this.toppings[i].listSelect[j].listA[o].Select=true;
    this.toppings[i].listSelect[j].Text=this.toppings[i].listSelect[j].listA[o].Food;
    this.toppings[i].listSelect[j].Select=false;
  }

  selectClick(i,j){ 
    for(let p=0;p<this.toppings[i].listSelect.length;p++)
    {
      this.toppings[i].listSelect[p].Select=false;
    }
    this.toppings[i].listSelect[j].Select=true;
  }

  payment(){
    this.dataService.show.next(false);
     this.changeShowPrice.emit();
    this.router.navigate(["../yourOrder"])
  }

  // preventBackButton() {
  //   history.pushState(null, null, location.href);
  //   this.locationStrategy.onPopState(() => {
  //     history.pushState(null, null, location.href);
  //   })
  // }

  // backPress()
  // {
  // if(this.toppingsPage.length>4)
  // {
  //   this.positionPage=1;
  // this.toppingsPage=[];
  // let p;
  // p=this.position-4;
  // this.position=p;
  // for(let j=0;j<4;j++)
  // this.toppingsPage.push(this.orderService.restaurant.MenuList[this.pos1].ChangeFood[this.posi2].toppings[j]);
  // }
  // else
  // if(this.selectRequired()==true)
  //   this.router.navigate(['/restaurant']);
  //   else
  //   this.proxy.showError("לא בחרת את כל \n שדות החובה","");
  // }

  back()
  {
    if(this.toppings.length>4 && this.positionPage>1)
    {
      this.positionPage=1;
    this.toppingsPage=[];
    let p;
    p=this.position-3;
    this.position=p;
    for(let j=0;j<4;j++)
    this.toppingsPage.push(this.orderService.restaurant.MenuList[this.pos1].ChangeFood[this.posi2].toppings[j]);
    this.continued="המשך"  
  }
    else
  {
    let pos=this.order.list.length;
    pos=Number(Number(pos)-1);
    if(this.order.list.length>0)
    {
    let amount =this.order.list[pos].Count;
    amount=Number(Number(amount)-1);
    }
    this.totalItems=0;
    let p:number=0;
    if(this.order.list[pos]!=null && this.order.list[pos].Price!=null)
    p=this.order.list[pos].Price;
    this.totalAmount=(Number(this.totalAmount)- Number(p));
    let count=0;
    for(let i=0;i<this.order.list.length;i++)
    {
      if(this.order.list[i].MenuID==this.order.list[pos].MenuID)
       count+=1;
    }
    count-=1;
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null && this.orderService.restaurant.MenuList.length>this.pos1)
    this.orderService.restaurant.MenuList[this.pos1].Rate=count;
    // this.order.list[pos].Rate=this.order.list[pos].Count;
    // this.order.list[pos].Rate-=1;
  // this.totalAmount-= this.restaurantItem[i].Price;
  if(this.totalAmount>0)
  for(let i=0;i<this.order.list.length;i++)
  {
    if(Number(this.order.list[i].Rate)>0)
    this.totalItems+=Number(this.order.list[i].Rate);
  }
  if(this.totalItems>0)
  {

  

   this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
   localStorage.PRICE_TOTAL=this.priceTotal;
   this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();
  }
   else
   {
   this.totalAmount=0;
   this.totalFoodAmount=0;
   this.priceTotal="0 - פריטים";
   localStorage.PRICE=0;
   localStorage.PRICE_TOTAL=this.priceTotal;
   this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();
   }
   localStorage.PRICE_TOTAL=this.priceTotal;

    let location:number;
    if(this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null
       && this.orderService.restaurant.MenuList.length>this.pos1 && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null)
    location = this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length-1;
    location-=1;
    if(this.orderService.restaurant.MenuList!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null)
    this.orderService.restaurant.MenuList[this.pos1].ChangeFood.splice(this.posi2,1);
   // this.orderService.getOrderDetails(false).subscribe((res:any)=>{
     // this.order=res;
      if(this.order.list!=null && this.order.list.length!=0 && localStorage.edit=="false")
      
    this.orderService.addToBagOrder_(this.order.list[pos].MenuID,this.restaurant.ID,count).subscribe((res:any)=>{
      if(this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length>location)
    delete this.orderService.restaurant.MenuList[this.pos1].ChangeFood[location]; 
    if(this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null &&this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length>0 )
    this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length=location;
    if(this.orderService.restaurant.MenuList[this.pos1].Rate==0)
    this.orderService.restaurant.MenuList[this.pos1].ChangeFood=null;
      this.router.navigate(['/restaurant']);
    });
   // });
  else
  {
    let priceTotal=this.clickToPay+" "+this.order.TotalPrice+" ₪";
    localStorage.PRICE_TOTAL=this.order.TotalPrice;
    localStorage.PRICE=this.order.TotalPrice;
    this.dataService.price.next(priceTotal);
    this.changePrice.emit();
  this.router.navigate(['/restaurant']);
  }
  //   if(this.selectRequired()==true)
  //    this.router.navigate(['/restaurant']);
  //    else
  //    {
  //    this.proxy.showError("לא בחרת את כל \n שדות החובה","");
  //    if(this.toppings.length>this.position)
  //    {
  //      this.positionPage=1;
  //      let AdditionList = "";
  //      this.AdditionList2="";
  //      let y=0;
  //      for(let i=0;i<this.toppingsPage.length;i++)
  //          for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
  //          {
  //              let OrderAddingItem=this.toppingsPage[i].listSelect[j];
  //              if(OrderAddingItem.Text!="לחץ לבחירה")
  //              for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
  //              {
  //                   let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
  //                if(OrderAddingItem!=null)
                
  //                  if (orderAddingItemFood.Food==OrderAddingItem.Text) {
  //                        y+=1;
  //                        if(y>1)
  //                            AdditionList +=",";
  //                        AdditionList += orderAddingItemFood.ID;
  //                        p=this.toppingsPage[i].listSelect[j].listA.length;
  //                  }
  //               }
  //              }

  //  if(AdditionList!="")
  //  {
  //    if(this.TypeID2==null)
  //    this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
  //      this.getOrderDetails2();
  //    });
  //    else
  //    this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
  //      this.getOrderDetails2();
  //    });
  //  }
  //  else
  //  this.getOrderDetails2();
  //    }
  //    else{
       
  //      let AdditionList = "";
  //      let y=0;
  //      for(let i=0;i<this.toppingsPage.length;i++)
  //          for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
  //          {
  //              let OrderAddingItem=this.toppingsPage[i].listSelect[j];
  //              if(OrderAddingItem.Text!="לחץ לבחירה")
  //              for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
  //              {
  //                   let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
  //                if(OrderAddingItem!=null)
                
  //                  if (orderAddingItemFood.Food==OrderAddingItem.Text) {
  //                        y+=1;
  //                        if(y>1)
  //                            AdditionList +=",";
  //                        AdditionList += orderAddingItemFood.ID;
  //                        p=this.toppingsPage[i].listSelect[j].listA.length;
  //                  }
  //               }
  //              }
  //              let id=0;
  //              for(let i=0; i<this.orderService.order.list.length;i++)
  //              {
  //                if(this.orderService.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
  //                id=this.orderService.order.list[i].ID;
  //              }
  //              if(AdditionList!="")
  //              {
  //              if(this.TypeID2==null)
  //              this.orderService.setOrderAddition(this.id,this.TypeID,AdditionList,Number(this.posi2)).subscribe((res:any)=>{
  //                this.getOrderDetails(false);
  //              });
  //              else
  //              this.orderService.setOrderAddition2(this.id,this.TypeID,AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
  //                this.getOrderDetails(false);
  //              });
  //            }
  //            else
  //            this.getOrderDetails(false);
  //          }
  //    }
    }
  }


  // selectRequired():boolean
  // {
  //   let selectRequired=true;
  //  let requiredCount=0;
  //   for(let j=0;j<this.toppingsPage.length;j++)
  //      if(this.toppingsPage[j].IsRequired>0 )
  //      {
  //      selectRequired=true;
  //      requiredCount=0;
  //        for(let m=0;m<this.toppingsPage[j].listA.length;m++)
  //        if(this.toppingsPage[j].listA[m].IsChoose==1)
  //        requiredCount+=this.toppingsPage[j].listA[m].CountChoose; 
  //      if(this.toppingsPage[j]!=undefined)
  //      if(requiredCount<this.toppingsPage[j].RequiredCount)
  //      {
  //      selectRequired=false;
  //      j=this.toppingsPage.length;
  //      }
  //   }
  //    return selectRequired;
  // }

  selectRequired():boolean
  {
    let selectRequired=true;
    let requiredCount=0;
    for(let j=0;j<this.toppingsPage.length;j++)
       if(this.toppingsPage[j].IsRequired>0 )
       {
       selectRequired=true;
       requiredCount=0;
       if(this.toppingsPage[j].listSelect!=null)
       for(let i=0;i<this.toppingsPage[j].listSelect.length;i++)
       {
         for(let m=0;m<this.toppingsPage[j].listSelect[i].listA.length;m++)
         if(this.toppingsPage[j].listSelect[i].listA[m].IsChoose==1 )
         requiredCount+=1; 
       }
       if(this.toppingsPage[j]!=undefined)
       if(requiredCount<this.toppingsPage[j].RequiredCount)
       {
       selectRequired=false;
       j=this.toppingsPage.length;
       }
     }
     return selectRequired;
  }

  continue()
  {
    this.heightAdding=0;
    this.heightAdding2=0;
    // this.AdditionList="";
    // this.AdditionList2="";
  if(this.selectRequired()==true)
  {
  if(this.toppings.length>this.position)
  {
    this.positionPage=1;
    let AdditionList = "";
    let AdditionList2="";
    let y=0;
    if(this.toppingsPage.length>0)
    for(let i=0;i<2;i++)
    if(this.toppingsPage.length>i)
        for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
        {
            let OrderAddingItem=this.toppingsPage[i].listSelect[j];
            if(OrderAddingItem.Text!="ללא בחירה")
            for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
            {
                 let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
              if(OrderAddingItem!=null)
             
                if (orderAddingItemFood.Food==OrderAddingItem.Text ) {
                      y+=1;
                      if(y>1)
                          AdditionList +=",";
                      AdditionList += orderAddingItemFood.ID;
                      p=this.toppingsPage[i].listSelect[j].listA.length;
                }
             }
            }
             y=0;
            if(this.toppingsPage.length>2)
    for(let i=2;i<4;i++)
    if(this.toppingsPage.length>i)
        for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
        {
            let OrderAddingItem=this.toppingsPage[i].listSelect[j];
            if(OrderAddingItem.Text!="ללא בחירה")
            for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
            {
                 let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
              if(OrderAddingItem!=null)
             
              if (orderAddingItemFood.Food==OrderAddingItem.Text) {
                y+=1;
                      if(y>1)
                          AdditionList2 +=",";
                      AdditionList2 += orderAddingItemFood.ID;
                      p=this.toppingsPage[i].listSelect[j].listA.length;
                }
             }
            }

            for(let i=0;i<this.toppingsPage[0].listSelect.length;i++)
            {
              if(this.toppingsPage[0].listSelect!=null && this.toppingsPage[0].listSelect!=undefined)
            if(this.heightAdding<Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48 && Number(this.heightAdding)<450)
                this.heightAdding=Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48;
                if(this.toppingsPage[1].listSelect!=null && this.toppingsPage[1].listSelect!=undefined && this.toppingsPage[1].listSelect[i]!=null && this.toppingsPage[1].listSelect[i].listA!=null)
                if(this.heightAdding2<Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48 && Number(this.heightAdding)<450)
                this.heightAdding2=Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48;
                if(Number(this.heightAdding)>450)
       this.heightAdding=450;
       if(Number(this.heightAdding2)>450)
       this.heightAdding2=450;
               }
  this.toppingsPage=[];
  this.toppingsPage.push(this.toppings[this.position]);
  this.position+=1;
  for(let p=this.position;p<this.toppings.length;p++)
  //if(this.toppings.length>=this.position)
  {
    this.positionPage=2;
    if(this.toppings[this.position]!=undefined)
    {
  this.toppingsPage.push(this.toppings[this.position]);
  this.position+=1;
    }
  if(this.position>=this.toppings.length)
  this.continued="סיום"
  else
  this.continued="המשך"
  }
 
//if(AdditionList!="")
{
  if(this.position>=this.toppings.length)
  this.continued="סיום"
  else
  this.continued="המשך"
  if(this.TypeID2==null)
  this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
    this.getOrderDetails(true);
  });
  else
  this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
    if(this.toppings.length>3 && this.AdditionList2!="")
    {
    this.orderService.setOrderAddition2(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2),this.TypeID4).subscribe((res:any)=>{
    this.getOrderDetails2();
  });
}
  else if(this.toppings.length>2 && this.AdditionList2!="")
  this.orderService.setOrderAddition(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2)).subscribe((res:any)=>{
    this.getOrderDetails2();
  });
  else 
  this.getOrderDetails2();
});
}
//else
//this.getOrderDetails2();
  }
  else{
    
    let AdditionList = "";
    let AdditionList2 = "";
    let y=0;
    if(this.toppingsPage.length>0)
    for(let i=0;i<2;i++)
    if(this.toppingsPage.length>i)
        for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
        {
            let OrderAddingItem=this.toppingsPage[i].listSelect[j];
            if(OrderAddingItem.Text!="ללא בחירה")
            for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
            {
                 let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
              if(OrderAddingItem!=null)
             
              if (orderAddingItemFood.Food==OrderAddingItem.Text) {
                y+=1;
                      if(y>1)
                          AdditionList +=",";
                      AdditionList += orderAddingItemFood.ID;
                      p=this.toppingsPage[i].listSelect[j].listA.length;
                }
             }
            }
             y=0;
            if(this.toppingsPage.length>2)
    for(let i=2;i<4;i++)
    if(this.toppingsPage.length>i)
        for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
        {
            let OrderAddingItem=this.toppingsPage[i].listSelect[j];
            if(OrderAddingItem.Text!="ללא בחירה")
            for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
            {
                 let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
              if(OrderAddingItem!=null)
              if (orderAddingItemFood.Food==OrderAddingItem.Text) {
                y+=1;
                      if(y>1)
                          AdditionList2 +=",";
                      AdditionList2 += orderAddingItemFood.ID;
                      p=this.toppingsPage[i].listSelect[j].listA.length;
                }
             }
            }

            let id=0;
            for(let i=0; i<this.orderService.order.list.length;i++)
            {
              if(this.orderService.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
              id=this.orderService.order.list[i].ID;
            }
           // if(AdditionList!="")
            {
              if(this.AdditionList=="" || this.AdditionList!=AdditionList && this.AdditionList!="")
              {
              this.AdditionList=AdditionList;
              this.AdditionList2=AdditionList2;
              }
              if(this.TypeID2==null)
              this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
                this.getOrderDetails(true);
              });
              else
              this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
                if(this.toppingsPage.length>3 && this.AdditionList2!="")
                this.orderService.setOrderAddition2(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2),this.TypeID4).subscribe((res:any)=>{
                this.getOrderDetails(true)
              });
              else if(this.toppingsPage.length>2 && this.AdditionList2!="")
              this.orderService.setOrderAddition(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2)).subscribe((res:any)=>{
                this.getOrderDetails(true);
              });
              else 
                this.getOrderDetails(true);
            });
            }
         // else
         // this.getOrderDetails(true);
         if(this.position>=this.toppings.length)
         this.continued="סיום"
         else
         this.continued="המשך"
         window.scroll(0,0);
        }
}
else
{
this.proxy.showError("לא בחרת את כל \n שדות החובה","");
this.heightAdding=0;
this.heightAdding2=0;
for(let i=0;i<this.toppingsPage[0].listSelect.length;i++)
{
if(this.heightAdding<Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48 && Number(this.heightAdding)<2000)
    this.heightAdding=Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48;
    if(this.toppingsPage[1].listSelect[i]!=null && this.toppingsPage[1].listSelect[i].listA!=null)
    if(this.heightAdding2<Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48 && Number(this.heightAdding)<2000)
    this.heightAdding2=Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48;
    if(Number(this.heightAdding)>450)
       this.heightAdding=450;
       if(Number(this.heightAdding2)>450)
       this.heightAdding2=450;
   }
for(let j=0;j<this.toppingsPage.length;j++)
{
  for(let y=0;y<this.toppingsPage[j].listSelect.length;y++)
  for(let q=0;q<this.toppingsPage[j].listSelect[y].listA.length;q++)
  {
    if(this.toppingsPage[j].listSelect[y].listA[q].IsChoose==1)
    {
      this.toppingsPage[j].listSelect[y].Text=this.toppingsPage[j].listSelect[y].listA[q].Food;
      this.selectItem(j,y,this.toppingsPage[j].listSelect[y].listA[q].Food,this.toppings[j].Type,q,this.toppings[j].TypeID);
    }
  }
}
this.TypeID=this.toppingsPage[0].TypeID;
if(this.toppingsPage.length>1)
this.TypeID2=this.toppingsPage[1].TypeID;
else
this.TypeID2=null;
}
   }

   getOrderDetails(back:boolean)
{
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
    this.totalAmount=Number(this.order.TotalPrice);
    localStorage.PRICE=this.totalAmount;
    this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
    this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();
   if(back==true)
   {
   this.router.navigate(['../restaurant']);
   }
  });
}

getOrderDetails2()
{
  this.TypeID=this.toppingsPage[0].TypeID;
  if(this.toppingsPage.length>1)
  this.TypeID2=this.toppingsPage[1].TypeID;
  else
this.TypeID2=null;
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
    for(let j=0;j<this.toppingsPage.length;j++)
    {
      for(let y=0;y<this.toppingsPage[j].listSelect.length;y++)
      for(let q=0;q<this.toppingsPage[j].listSelect[y].listA.length;q++)
      {
        if(this.toppingsPage[j].listSelect[y].listA[q].IsChoose==1)
        {
          this.toppingsPage[j].listSelect[y].Text=this.toppingsPage[j].listSelect[y].listA[q].Food;
          this.selectItem(j,y,this.toppingsPage[j].listSelect[y].listA[q].Food,this.toppingsPage[j].Type,q,this.toppingsPage[j].TypeID);
        }
      }
    }
    this.totalAmount=Number(this.order.TotalPrice);
    localStorage.PRICE=this.totalAmount;
    this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
    this.dataService.price.next(this.priceTotal);
   this.changePrice.emit();
  });
}
}


// import { Component, OnInit ,EventEmitter  ,Output ,HostListener ,NgZone } from '@angular/core';
// import { Router ,ActivatedRoute} from '@angular/router';
// import { fromEvent, Observable, Subscription } from "rxjs";
// import { CategoryItem,Category,} from 'src/app/models/category';
// import { AdditonOrder, MyOrder, OrderAdding, OrderHistory } from 'src/app/models/order';
// import { ChangeFood, ToppingsItem, ToppingsItemFood ,Restaurant ,RestaurantItem} from 'src/app/models/restaurant';
// import { AppProxy } from 'src/app/services/app-proxy';
// import { OrdersService } from 'src/app/services/order-service';
// import { BussinssService } from 'src/app/services/bussinss-service';
// import { PlatformLocation ,LocationStrategy} from '@angular/common';
// import { DataService } from 'src/app/services/data.service';


// @Component({
//   selector: 'app-toppings',
//   templateUrl: './toppings.component.html',
//   styleUrls: ['./toppings.component.css']
// })
// export class ToppingsComponent implements OnInit {
//   @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
//   @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
//   @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
//   buttonText="המשך לתשלום"
//   continued="המשך"
//   selected:any="לחץ לבחירה";
//   filtered :any;
//   totalItems:number=0;
//   totalAmount:number=0;
//   toppings:OrderAdding[];
//   toppingsSelect:OrderAdding[];
//   toppingsPage:OrderAdding[]=[];
//   categores:Category[];
//   categoresItem:CategoryItem[];
//   restaurantItem:RestaurantItem[];
//   toppingsItemFood:ToppingsItemFood[];
//   toppingsItemFood1:ToppingsItemFood[];
//   toppingsItemFood2:ToppingsItemFood[];
//   toppingsItemFood3:ToppingsItemFood[];
//   toppingsItemFood4:ToppingsItemFood[];
//   toppingsItemFood5:ToppingsItemFood[];
//   toppingsItemFood6:ToppingsItemFood[];
//   toppingsItemFood7:ToppingsItemFood[];
//   toppingsItemFood8:ToppingsItemFood[];
//   toppingsItemFood9:ToppingsItemFood[];
//   toppingsItem:ToppingsItem[];
//   toppingsItem1:ToppingsItem[];
//   toppingsItem2:ToppingsItem[];
//   toppingsItem3:ToppingsItem[];
//   toppingsItem4:ToppingsItem[];
//   toppingsItem5:ToppingsItem[];
//   toppingsItem6:ToppingsItem[];
//   toppingsItem7:ToppingsItem[];
//   restaurant:Restaurant;
//   ordersToShow:OrderHistory[]= new Array();
//   orders:OrderHistory[]= new Array();
//   foodName:string;
//   foodDescription:string;
//   ImageFood:string;
//   position=4;
//   public positionPage=2;
//   selectedItem="";
//   pos1:number;
//   posi2:number;
//   private selectOption: any;
//   priceTotal="0-פריטים";
//   clickToPay="לחץ לתשלום - ";
//   priceAdding=0;
//   order:MyOrder;
//   Image="";
//   Image400="";
//   heightAdding:number;
//   heightAdding2:number;
//   additonOrder:AdditonOrder;
//   AdditionList;
//   AdditionList2;
//   countNumber;
//   TypeID;
//   TypeID2=null;
//   TypeID3=null;
//   TypeID4=null;
//   id=0;
//   num=4;
//   resizeObservable$: Observable<Event>
//   resizeSubscription$: Subscription;
//   pointOff="assets/images/point_off.png";
//   pointOn="assets/images/point_on.png";
//   checkboxOn="assets/images/chack_box_on.png";
//   checkboxOff="assets/images/chack_box_off.png"


//   @HostListener('window:popstate', ['$event'])
//   onPopState(event) {
//     this.back();
//   }
   
//   constructor(private zone: NgZone, private locationStrategy: LocationStrategy,private router: Router ,private dataService:DataService,location: PlatformLocation,private orderService:OrdersService,private bussinssService: BussinssService,private proxy: AppProxy,private route: ActivatedRoute) 
//   {  
//   history.pushState(null, null, window.location.href);  
// this.locationStrategy.onPopState(() => {
//   history.pushState(null, null, window.location.href);
// });
// }

//   ngOnInit(): void {
//     this.resizeObservable$ = fromEvent(window, 'resize')
//     this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
//       console.log('event11: ', evt);
//        if(evt.composedPath)
//         this.num=2;
//         else
//         this.num=4;
//         if(this.toppings!=null)
//         if(this.toppings.length<=this.num)
//         this.continued="סיום";
//         else
//         this.continued="המשך";
//     })
//     // Run the code that changes state inside the zone

//     localStorage.PAGE_BACK="";
//     this.dataService.byFood.next(false);
//     this.changByFood.emit();
//     this.dataService.show.next(false);
//     this.changeShowPrice.emit();
//     window.scroll(0,0);
//     this.priceAdding=0;
//     this.pos1= this.route.snapshot.params.pos;
//     this.posi2= this.route.snapshot.params.position;
//     this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//       this.order=res;
//  if(this.orderService.restaurant!=null)
//    {
//  this.restaurant=this.orderService.restaurant;
//  if(this.restaurant.LongImage!=null)
//  this.Image=this.restaurant.LongImage;
//    }
//   {
//     this.orderService.getOrderAdditon(localStorage.MENU_ID,Number(this.posi2),this.pos1,this.posi2).subscribe((res:any)=>{
//       this.additonOrder=res;
//       if(this.additonOrder.ImgFile!=null)
//       this.Image400=this.additonOrder.ImgFile;
//       this.orderService.orderAddingList=this.additonOrder.list;
//       this.toppings=this.orderService.orderAddingList;
//       this.foodName=this.additonOrder.Name;
//       if(this.additonOrder.Description!="")
//       this.foodDescription=this.additonOrder.Description;
//       else
//       this.foodDescription=this.additonOrder.Ingredients;
//       this.ImageFood=this.additonOrder.ImgFile;
//       let pos;
//       if(this.toppings!=null && this.toppings.length>0 )
//       {
//         // for(let i=0;i<this.order.list.length;i++)
//         // {
//         //   if(this.order.list[i].MenuID==localStorage.MENU_ID)
//         //   pos=i;
//         // }
//         this.toppingsPage.push(this.toppings[0]);
//         if(this.toppings.length>1)
//         this.toppingsPage.push(this.toppings[1]);
//         if(this.toppings.length>2)
//         this.toppingsPage.push(this.toppings[2]);
//         if(this.toppings.length>3)
//         this.toppingsPage.push(this.toppings[3]);

  
//         this.position=this.toppingsPage.length;
//         this.AdditionList="";
//         this.AdditionList2="";
//         // for(let j=0;j<this.toppingsPage.length;j++)
//         // {
//         //   for(let y=0;y<this.toppingsPage[j].listSelect.length;y++)
//         //   for(let q=0;q<this.toppingsPage[j].listSelect[y].listA.length;q++)
//         //   {
//         //     if(this.toppingsPage[j].listSelect[y].listA[q].IsChoose==1)
//         //     {
//         //       this.toppingsPage[j].listSelect[y].Text=this.toppingsPage[j].listSelect[y].listA[q].Food;
//         //      // this.toppings[j].listSelect[y].Select=true;
//         //       this.selectItem(j,y,this.toppingsPage[j].listSelect[y].listA[q].Food,this.toppings[j].Type,q,this.toppings[j].TypeID);
//         //     }
//         //   }
//         // }
//         this.TypeID=this.toppingsPage[0].TypeID;
// if(this.toppingsPage.length>1)
// this.TypeID2=this.toppingsPage[1].TypeID;
// if(this.toppingsPage.length>2)
// this.TypeID3=this.toppingsPage[2].TypeID;
// if(this.toppingsPage.length>3)
// this.TypeID4=this.toppingsPage[3].TypeID;
//      //  if(this.toppings[0]!=null)
//         // && this.toppings[0].listSelect!=null && this.toppings[0].listSelect.length>0)

//    this.heightAdding=0;
//    this.heightAdding2=0;
 


//    if(this.toppings!=null)
//    if(this.toppings.length<=this.num)
//    this.continued="סיום";
//   // for(let j=0;j<this.toppingsPage.length;j++)
//   //  for(let i=0;i<this.toppingsPage[0].listSelect.length;i++)
//   //  {
//   //  if(this.heightAdding<Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48 && Number(this.heightAdding)<450)
//   //      this.heightAdding=Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48;
//   //      if(this.toppingsPage.length>1)
//   //      if(this.heightAdding2<Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48 && Number(this.heightAdding2)<450)
//   //      this.heightAdding2=Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48;
//   //      if(Number(this.heightAdding)>450)
//   //      this.heightAdding=450;
//   //      if(Number(this.heightAdding2)>450)
//   //      this.heightAdding2=450;
//   //     }
//     }
     
//           this.categores=[
//             {
//               "Name": "אחרונות",
//               "ID": 47,
//               "categoryItems":this.categoresItem,
//               isCheck:true,
//               categoryType:0,
//             },
//             {
//               "Name": "סלטים",
//               "ID": 47,
//               "categoryItems":this.categoresItem,
//               isCheck:true,
//               categoryType:1,  
//             },
//             { 
//               "Name": "ראשונות",
//               "ID": 1,
//               "categoryItems":this.categoresItem,
//               isCheck:false,
//               categoryType:2,
//             },  
//           ];
// //     this.bussinssService.getRestaurantDetails(this.order.RestaurantID,false,false,false,null,null).subscribe((res:any)=>{
// //       this.restaurant=res;
// //       this.restaurantItem=this.restaurant.MenuList;
// //       if(this.restaurantItem!=null)
// //       {
// //         if(this.restaurantItem[this.pos1]!=null)
// //  if(this.restaurantItem[this.pos1].ChangeFood!=null)
// //  if(this.restaurantItem[this.pos1].ChangeFood[this.posi2]!=null)
// //  if(this.restaurantItem[this.pos1].ChangeFood[this.posi2].toppings!=null)
// //  {
// //   this.foodName=this.restaurantItem[this.pos1].ChangeFood[this.posi2].Name;
// // this.toppings=this.orderService.restaurant.MenuList[this.pos1].ChangeFood[this.posi2].toppings;
// //  }
// // else if(this.toppings==null)
// // if(this.orderService.orderAddingList!=null && this.orderService.orderAddingList.length>0)
// // {
// //     this.toppings=this.orderService.orderAddingList;
// //     if(this.bussinssService.restaurant!=null && this.bussinssService.restaurant.MenuList!=null)
// //     this.restaurantItem=this.bussinssService.restaurant.MenuList;
// // }
// //       }
// //     });
//   });
//   }
//   if(this.restaurantItem!=null)
//  for(let j=0;j< this.restaurantItem.length;j++)
//  if(this.restaurantItem[j].Amount>0)
//     {
//     this.totalItems+=this.restaurantItem[j].Amount;
//     this.totalAmount+=Number(this.totalItems)* Number(this.restaurantItem[j].Price);
//     }
//    if(this.toppings!=null && this.toppings.length>0 )
//    {
// this.toppingsPage.push(this.toppings[0]);
// if(this.toppings.length>1)
// this.toppingsPage.push(this.toppings[1]);
// if(this.toppings.length>2)
// this.toppingsPage.push(this.toppings[2]);
// if(this.toppings.length>3)
// this.toppingsPage.push(this.toppings[3]);
// this.heightAdding=0;
// for(let j=0;j<this.toppingsPage.length;j++)
// {
// if(this.heightAdding<Number(Number(this.toppingsPage[j].Count)+2)*48 && Number(this.heightAdding)<450)
//     this.heightAdding=Number(Number(this.toppingsPage[j].Count)+2)*48;
//     if(Number(this.heightAdding)>450)
//     this.heightAdding=450;
//     if(Number(this.heightAdding2)>450)
//     this.heightAdding2=450;
//    }}
//   if(this.toppings!=null)
// if(this.toppings.length<=4)
// this.continued="סיום";
//     this.categores=[
//       {
//         "Name": "אחרונות",
//         "ID": 47,
//         "categoryItems":this.categoresItem,
//         isCheck:true,
//         categoryType:0,
//       },
//       {
//         "Name": "סלטים",
//         "ID": 47,
//         "categoryItems":this.categoresItem,
//         isCheck:true,
//         categoryType:1,  
//       },
//       { 
//         "Name": "ראשונות",
//         "ID": 1,
//         "categoryItems":this.categoresItem,
//         isCheck:false,
//         categoryType:2,
//       },  
//     ];
//     if(this.bussinssService.restaurant!=null)
//     this.restaurant=this.bussinssService.restaurant;
//     if(this.restaurant.LongImage!=null)
//     this.Image=this.restaurant.LongImage;
//     if(localStorage.PRICE>0 && localStorage.PRICE!=this.totalAmount)
//     this.totalAmount=Number(localStorage.PRICE);
//     this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
//   });
//   }

//   @HostListener("window:resize", [])
//   private onResize() {
//     this.detectScreenSize();
//   }

//   private detectScreenSize() {
//     console.log('size:',window.matchMedia("(max-width: 400px)") )
//     if(window.matchMedia("(max-width: 400px)").matches==true)
//       this.num=2;
//     else
//       this.num=4;
//       console.log('event8: ', this.num)
//       if(this.toppings!=null)
//       if(this.toppings.length<=this.num)
//       this.continued="סיום";
//       else
//       this.continued="המשך";
//   }

//   clickList(pos:number,position:number,toppingText:string,newValue)
//   {
//     if(localStorage.SMS=="")
//    this.proxy.showError("חובה להכניס קוד","");
//    else if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
//     this.router.navigate(['../mainHome']);
//     else
//     {
//     let posi=pos;
//     if(this.orderService.restaurant==null)
//     this.orderService.restaurant=this.restaurant;
//     for(let i=0;i< this.toppings.length;i++)
//     {
//       if(this.toppings[i].Type!=null)
//        if(this.toppings[i].Type==this.toppingsPage[posi].Type)
//        {
//        posi=i;
//        break
//        }
//       }
//         let OrderAddingItem=this.toppings[posi].listSelect[position];
//       //  if(OrderAddingItem.Text!="לחץ לבחירה" && OrderAddingItem.Text!=null && OrderAddingItem.Text!="")
//         // for(let p=0;p<this.toppings[posi].listSelect[position].listA.length;p++)
//         // {
//         //      let orderAddingItemFood=this.toppings[posi].listSelect[position].listA[p];
//         //      if(orderAddingItemFood.Food==OrderAddingItem.Text)
//         //      {
//         //       this.priceAdding=Number(orderAddingItemFood.Price);
//         //      }
//         // }
//       }
//   }

//   selectItem_(pos:number,position:number,toppingText:string,Type:string,inde:number,TypeID:number)
//   {
//     if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
//     this.router.navigate(['../mainHome']);
//     else
//     {
//     let posi=pos;
//     if(this.orderService.restaurant==null)
//     this.orderService.restaurant=this.restaurant;
//     for(let i=0;i<this.toppings.length;i++)
//     {
//     if(this.toppings[i].Type==Type)
//                   posi=i;
//             }

//     this.toppings[posi].listSelect[position].Text=toppingText;
//    // if(inde!=null)
//     //this.toppings[posi].listSelect[position].listA[inde].IsChoose=1;
//     this.toppings[posi].listSelect[position].Select=true
//     if(this.order!=null && this.order.list!=null)
//     for(let i=0; i<this.order.list.length;i++)
//     {
//       if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
//       this.id=this.order.list[i].ID;
//     }
//              let pos_=Number(this.posi2);
//           let y=0;
//           this.AdditionList ="";
//           this.AdditionList2="";
//           if(this.toppingsPage.length>0)
//           for(let i=0;i<2;i++)
//           if(this.toppingsPage.length>i)
//              // for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//               {
//                 //  let OrderAddingItem=this.toppingsPage[i].Text;
//                   //if(OrderAddingItem!="לחץ לבחירה")
//                   for(let p=0;p<this.toppingsPage[i].listA.length;p++)
//                   {
//                        let orderAddingItemFood=this.toppingsPage[i].listA[p];
//                        if (orderAddingItemFood.IsChoose==1)
//                        {
//                             y+=1;
//                             if(y>1)
//                                 this.AdditionList +=",";
//                             this.AdditionList += orderAddingItemFood.ID;
//                             p=this.toppingsPage[i].listA.length;
//                       }
//                    }
//                   }
//                    y=0;
//                   if(this.toppingsPage.length>2)
//           for(let i=2;i<4;i++)
//           if(this.toppingsPage.length>i)
//             //  for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//               {
//                  // let OrderAddingItem=this.toppingsPage[i].listSelect[j].Text;
//                 //  if(OrderAddingItem!="לחץ לבחירה")
//                   for(let p=0;p<this.toppingsPage[i].listA.length;p++)
//                   {
//                        let orderAddingItemFood=this.toppingsPage[i].listA[p];     
//                        if (orderAddingItemFood.IsChoose==1){
//                             y+=1;
//                             if(y>1)
//                                 this.AdditionList2 +=",";
//                             this.AdditionList2 += orderAddingItemFood.ID;
//                             p=this.toppingsPage[i].listA.length;
//                       }
//                    }
//                   }
//           let a:number=1;
//           pos_=Number(pos_);
//           if(this.toppingsPage.length==1)
//           this.TypeID2=null;
//          // this.TypeID4=null;
//           if(posi>1)
//           {
//             if(posi%2==0)
//             this.TypeID3=TypeID;
//             else
//             this.TypeID4=TypeID;
//           }
//           else
//           if(posi%2==0)
//           this.TypeID=TypeID;
//           else
//           this.TypeID2=TypeID;
//           }
//   }

//   selectItem(pos:number,position:number,IsChoose:boolean)
//   {
//     let TypeID=this.toppingsPage[pos].TypeID;
//     if(localStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
//     this.router.navigate(['../mainHome']);
//     else
//     {
//       let posi=pos;
//       for(let i=0;i<this.toppings.length;i++)
//       {
//       if(this.toppings[i].TypeID==this.toppingsPage[pos].TypeID)
//                     posi=i;
//               }
//     if(this.orderService.restaurant==null)
//     this.orderService.restaurant=this.restaurant;
// if(IsChoose==true)
// {
// if(this.toppings[posi].listA[position].IsChoose==1)
// {
// this.toppings[posi].listA[position].IsChoose=0;
// if(this.toppingsPage[pos].Count>1)
// this.toppings[posi].listA[position].CountChoose=0; 
// if(this.toppings[posi].listA[position].Price>0)
// this.totalAmount-=Number(this.toppings[posi].listA[position].Price);
// //this.toppingsPage[pos].listA[position].IsChoose=0;
// }
// else
// {
//   if(this.toppingsPage[pos].Count==1)
//   {
//     for(let j=0;j<this.toppingsPage[pos].listA.length;j++)
//     {
//      if(this.toppings[posi].listA[j].IsChoose==1)
//       if(this.toppings[posi].listA[j].Price>0)
//       this.totalAmount-=Number(this.toppings[posi].listA[j].Price);
//       this.toppings[posi].listA[j].IsChoose=0;
//      // this.toppingsPage[pos].listA[j].IsChoose=0;
//     }
    
//     this.toppings[posi].listA[position].IsChoose=1;
    
//   }
//   else{
//     let count=0;
//     for(let m=0;m<this.toppings[posi].listA.length;m++)
//       count+=this.toppings[posi].listA[m].CountChoose;
//     if(count<this.toppings[posi].Count)
//     {
//     this.toppings[posi].listA[position].CountChoose+=1; 
//     //this.toppingsPage[pos].listA[position].CountChoose+=1; 
//     this.toppings[posi].listA[position].IsChoose=1;
//   }
  
//   }
//   if(this.toppings[posi].listA[position].Price>0)
//   this.totalAmount+=Number(this.toppings[posi].listA[position].Price);
//     //this.toppingsPage[pos].listA[position].IsChoose=1;
// }
// }
//     if(this.order!=null && this.order.list!=null)
//     for(let i=0; i<this.order.list.length;i++)
//     {
//       if(this.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
//       this.id=this.order.list[i].ID;
//     }
//              let pos_=Number(this.posi2);
//           let y=0;
//           this.AdditionList ="";
//           this.AdditionList2="";
//           if(this.toppingsPage.length>0)
//           for(let i=0;i<2;i++)
//           if(this.toppingsPage.length>i)
//            //   for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//               {
//                  // let OrderAddingItem=this.toppingsPage[i].listSelect[j].Text;
//                   // if(OrderAddingItem!="לחץ לבחירה")
//                   for(let p=0;p<this.toppingsPage[i].listA.length;p++)
//                   {
//                        let orderAddingItemFood=this.toppingsPage[i].listA[p];
//                       if (orderAddingItemFood.IsChoose==1)
//                        {
//                             y+=1;
//                             if(y>1)
//                                 this.AdditionList +=",";
//                             this.AdditionList += orderAddingItemFood.ID;
//                          //   p=this.toppingsPage[i].listA.length;
//                       }
//                    }
//                   }
//                    y=0;
//                   if(this.toppingsPage.length>2)
//           for(let i=2;i<4;i++)
//           if(this.toppingsPage.length>i)
//             //  for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//               {
//                  // let OrderAddingItem=this.toppingsPage[i].listSelect[j].Text;
//                  // if(OrderAddingItem!="לחץ לבחירה")
//                   for(let p=0;p<this.toppingsPage[i].listA.length;p++)
//                   {
//                        let orderAddingItemFood=this.toppingsPage[i].listA[p];     
//                       if (orderAddingItemFood.IsChoose==1) {
//                             y+=1;
//                             if(y>1)
//                                 this.AdditionList2 +=",";
//                             this.AdditionList2 += orderAddingItemFood.ID;
//                            // p=this.toppingsPage[i].listA.length;
//                       }
//                    }
//                   }
//           let a:number=1;
//           pos_=Number(pos_);
//           if(this.toppingsPage.length==1)
//           this.TypeID2=null;
//           //this.TypeID4=null;
//           if(posi>1)
//           {
//             if(posi%2==0)
//             this.TypeID3=TypeID;
//             else
//             this.TypeID4=TypeID;
//           }
//           else
//           if(posi%2==0)
//           this.TypeID=TypeID;
//           else
//           this.TypeID2=TypeID;
//           }
//   }

//   plus(pos,i:number)
//    {
//     let posi=pos;
//     for(let i=0;i<this.toppings.length;i++)
//     {
//     if(this.toppings[i].TypeID==this.toppingsPage[pos].TypeID)
//                   posi=i;
//             }
//             let count=0;
//             for(let m=0;m<this.toppings[posi].listA.length;m++)
//               count+=this.toppings[posi].listA[m].CountChoose;
//             if(count<this.toppings[posi].Count)
//             {
//   this.toppingsPage[posi].listA[i].CountChoose+=1;
//   this.toppings[posi].listA[i].IsChoose=1;
//   if(this.toppingsPage[posi].listA[i].Price>0)
//   this.totalAmount+=Number(this.toppingsPage[posi].listA[i].Price);
//             }
//   }

//   minus(pos,i:number)
//   {
//     let posi=pos;
//     for(let i=0;i<this.toppings.length;i++)
//     {
//     if(this.toppings[i].TypeID==this.toppingsPage[pos].TypeID)
//                   posi=i;
//             }
//     this.toppingsPage[posi].listA[i].CountChoose-=1;
//     if(this.toppingsPage[posi].listA[i].Price>0)
//     this.totalAmount-=Number(this.toppingsPage[posi].listA[i].Price);
//     if(this.toppingsPage[posi].listA[i].CountChoose==0)
//     this.toppings[posi].listA[i].IsChoose=0;
//   }

//   changeOnFood(i,j,o)
//   {
//     // for(let p=0;p<this.toppings[i].listSelect[j].listA.length;p++)
//     // {
//     //   this.toppings[i].listSelect[j].listA[p].Select=false;
//     // }
//     // this.toppings[i].listSelect[j].listA[o].Select=true;
//     // this.toppings[i].listSelect[j].Text=this.toppings[i].listSelect[j].listA[o].Food;
//     this.toppings[i].listSelect[j].Select=false;
//   }

//   selectClick(i,j){ 
//     for(let p=0;p<this.toppings[i].listSelect.length;p++)
//     {
//       this.toppings[i].listSelect[p].Select=false;
//     }
//     this.toppings[i].listSelect[j].Select=true;
//   }

//   payment(){
//     this.dataService.show.next(false);
//      this.changeShowPrice.emit();
//     this.router.navigate(["../yourOrder"])
//   }

//   preventBackButton() {
//     history.pushState(null, null, location.href);
//     this.locationStrategy.onPopState(() => {
//       history.pushState(null, null, location.href);
//     })
//   }

//   backPress()
//   {
//   if(this.toppingsPage.length>4)
//   {
//     this.positionPage=1;
//   this.toppingsPage=[];
//   let p;
//   p=this.position-4;
//   this.position=p;
//   for(let j=0;j<4;j++)
//   this.toppingsPage.push(this.orderService.restaurant.MenuList[this.pos1].ChangeFood[this.posi2].toppings[j]);
//   }
//   else
//   if(this.selectRequired()==true)
//     this.router.navigate(['/restaurant']);
//     else
//     this.proxy.showError("לא בחרת את כל \n שדות החובה","");
//   }

//   back()
//   {
//     if(this.toppingsPage.length>4)
//     {
//       this.positionPage=1;
//     this.toppingsPage=[];
//     let p;
//     p=this.position-4;
//     this.position=p;
//     for(let j=0;j<4;j++)
//     this.toppingsPage.push(this.orderService.restaurant.MenuList[this.pos1].ChangeFood[this.posi2].toppings[j]);
//     }
//     else
//   {
//     let pos=this.order.list.length;
//     pos=Number(Number(pos)-1);
//     if(this.order.list.length>0)
//     {
//     let amount =this.order.list[pos].Count;
//     amount=Number(Number(amount)-1);
//     }
//     this.totalItems=0;
//     let p:number=0;
//     p=this.order.list[pos].Price;
//     this.totalAmount=(Number(this.totalAmount)- Number(p));
//     let count=0;
//     for(let i=0;i<this.order.list.length;i++)
//     {
//       if(this.order.list[i].MenuID==this.order.list[pos].MenuID)
//        count+=1;
//     }
//     count-=1;
//     this.orderService.restaurant.MenuList[this.pos1].Rate=count;
//     // this.order.list[pos].Rate=this.order.list[pos].Count;
//     // this.order.list[pos].Rate-=1;
//   // this.totalAmount-= this.restaurantItem[i].Price;
//   if(this.totalAmount>0)
//   for(let i=0;i<this.order.list.length;i++)
//   {
//     if(Number(this.order.list[i].Rate)>0)
//     this.totalItems+=Number(this.order.list[i].Rate);
//   }
//   if(this.totalItems>0)
//   {
//    this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
//    localStorage.PRICE_TOTAL=this.priceTotal;
//    this.dataService.price.next(this.priceTotal);
//    this.changePrice.emit();
//   }
//    else
//    {
//    this.totalAmount=0;
//    this.priceTotal="0 - פריטים";
//    localStorage.PRICE=0;
//    localStorage.PRICE_TOTAL=this.priceTotal;
//    this.dataService.price.next(this.priceTotal);
//    this.changePrice.emit();
//    }
//    localStorage.PRICE_TOTAL=this.priceTotal;

//     let location:number;
//     location = this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length-1;
//     location-=1;
//     this.orderService.restaurant.MenuList[this.pos1].ChangeFood.splice(this.posi2,1);
//     this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//       this.order=res;
//       if(this.order.list!=null && this.order.list!=[] )
//     this.orderService.addToBagOrder_(this.order.list[pos].MenuID,this.restaurant.ID,count).subscribe((res:any)=>{
//       if(this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null && this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length>location)
//     delete this.orderService.restaurant.MenuList[this.pos1].ChangeFood[location]; 
//     if(this.orderService.restaurant.MenuList[this.pos1].ChangeFood!=null &&this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length>0 )
//     this.orderService.restaurant.MenuList[this.pos1].ChangeFood.length=location;
//     if(this.orderService.restaurant.MenuList[this.pos1].Rate==0)
//     this.orderService.restaurant.MenuList[this.pos1].ChangeFood=null;
//       this.router.navigate(['/restaurant']);
//     });
//   });
//   //   if(this.selectRequired()==true)
//   //    this.router.navigate(['/restaurant']);
//   //    else
//   //    {
//   //    this.proxy.showError("לא בחרת את כל \n שדות החובה","");
//   //    if(this.toppings.length>this.position)
//   //    {
//   //      this.positionPage=1;
//   //      let AdditionList = "";
//   //      this.AdditionList2="";
//   //      let y=0;
//   //      for(let i=0;i<this.toppingsPage.length;i++)
//   //          for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//   //          {
//   //              let OrderAddingItem=this.toppingsPage[i].listSelect[j];
//   //              if(OrderAddingItem.Text!="לחץ לבחירה")
//   //              for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
//   //              {
//   //                   let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
//   //                if(OrderAddingItem!=null)
                
//   //                  if (orderAddingItemFood.Food==OrderAddingItem.Text) {
//   //                        y+=1;
//   //                        if(y>1)
//   //                            AdditionList +=",";
//   //                        AdditionList += orderAddingItemFood.ID;
//   //                        p=this.toppingsPage[i].listSelect[j].listA.length;
//   //                  }
//   //               }
//   //              }

//   //  if(AdditionList!="")
//   //  {
//   //    if(this.TypeID2==null)
//   //    this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
//   //      this.getOrderDetails2();
//   //    });
//   //    else
//   //    this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
//   //      this.getOrderDetails2();
//   //    });
//   //  }
//   //  else
//   //  this.getOrderDetails2();
//   //    }
//   //    else{
       
//   //      let AdditionList = "";
//   //      let y=0;
//   //      for(let i=0;i<this.toppingsPage.length;i++)
//   //          for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//   //          {
//   //              let OrderAddingItem=this.toppingsPage[i].listSelect[j];
//   //              if(OrderAddingItem.Text!="לחץ לבחירה")
//   //              for(let p=0;p<this.toppingsPage[i].listSelect[j].listA.length;p++)
//   //              {
//   //                   let orderAddingItemFood=this.toppingsPage[i].listSelect[j].listA[p];
//   //                if(OrderAddingItem!=null)
                
//   //                  if (orderAddingItemFood.Food==OrderAddingItem.Text) {
//   //                        y+=1;
//   //                        if(y>1)
//   //                            AdditionList +=",";
//   //                        AdditionList += orderAddingItemFood.ID;
//   //                        p=this.toppingsPage[i].listSelect[j].listA.length;
//   //                  }
//   //               }
//   //              }
//   //              let id=0;
//   //              for(let i=0; i<this.orderService.order.list.length;i++)
//   //              {
//   //                if(this.orderService.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
//   //                id=this.orderService.order.list[i].ID;
//   //              }
//   //              if(AdditionList!="")
//   //              {
//   //              if(this.TypeID2==null)
//   //              this.orderService.setOrderAddition(this.id,this.TypeID,AdditionList,Number(this.posi2)).subscribe((res:any)=>{
//   //                this.getOrderDetails(false);
//   //              });
//   //              else
//   //              this.orderService.setOrderAddition2(this.id,this.TypeID,AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
//   //                this.getOrderDetails(false);
//   //              });
//   //            }
//   //            else
//   //            this.getOrderDetails(false);
//   //          }
//   //    }
//     }
//   }

//   selectRequired():boolean
//   {
//     let selectRequired=true;
//     let requiredCount=0;
//     for(let j=0;j<this.toppingsPage.length;j++)
//        if(this.toppingsPage[j].IsRequired>0 )
//        {
//        selectRequired=true;
//        requiredCount=0;
  
//          for(let m=0;m<this.toppingsPage[j].listA.length;m++)
//          if(this.toppingsPage[j].listA[m].IsChoose==1)
//          requiredCount+=1; 
      
//        if(this.toppingsPage[j]!=undefined)
//        if(requiredCount<this.toppingsPage[j].RequiredCount)
//        {
//        selectRequired=false;
//        j=this.toppingsPage.length;
//        }
//      }
//      return selectRequired;
//   }

//   continue()
//   {
//     this.heightAdding=0;
//     this.heightAdding2=0;
//   if(this.selectRequired()==true)
//   {
//   if(this.toppings.length>this.position)
//   {
//     this.positionPage=1;
//     let AdditionList = "";
//     let AdditionList2="";
//     let y=0;
//     if(this.toppingsPage.length>0)
//     for(let i=0;i<2;i++)
//     if(this.toppingsPage.length>i)
//        // for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//        // {
//          //   let OrderAddingItem=this.toppingsPage[i].listSelect[j];
//            // if(OrderAddingItem.Text!="לחץ לבחירה")
//             for(let p=0;p<this.toppingsPage[i].listA.length;p++)
//             {
//                  let orderAddingItemFood=this.toppingsPage[i].listA[p];
             
//                  if (orderAddingItemFood.IsChoose==1)
//                 {
//                       y+=1;
//                       if(y>1)
//                           AdditionList +=",";
//                       AdditionList += orderAddingItemFood.ID;
//                      // p=this.toppingsPage[i].listA.length;
//                 }
//             // }
//             }
//              y=0;
//             if(this.toppingsPage.length>2)
//     for(let i=2;i<4;i++)
//     if(this.toppingsPage.length>i)
//       //  for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//         //{
//           //  let OrderAddingItem=this.toppingsPage[i].listSelect[j];
//            // if(OrderAddingItem.Text!="לחץ לבחירה")
//             for(let p=0;p<this.toppingsPage[i].listA.length;p++)
//             {
//                  let orderAddingItemFood=this.toppingsPage[i].listA[p];
//              // if(OrderAddingItem!=null)
             
//              if (orderAddingItemFood.IsChoose==1)
//                  {
//                       y+=1;
//                       if(y>1)
//                           AdditionList2 +=",";
//                       AdditionList2 += orderAddingItemFood.ID;
//                      // p=this.toppingsPage[i].listA.length;
//                 //}
//              }
//             }

//       //       for(let i=0;i<this.toppingsPage[0].listSelect.length;i++)
//       //       {
//       //         if(this.toppingsPage[0].listSelect!=null && this.toppingsPage[0].listSelect!=undefined)
//       //       if(this.heightAdding<Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48 && Number(this.heightAdding)<450)
//       //           this.heightAdding=Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48;
//       //           if(this.toppingsPage[1].listSelect!=null && this.toppingsPage[1].listSelect!=undefined)
//       //           if(this.heightAdding2<Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48 && Number(this.heightAdding)<450)
//       //           this.heightAdding2=Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48;
//       //           if(Number(this.heightAdding)>450)
//       //  this.heightAdding=450;
//       //  if(Number(this.heightAdding2)>450)
//       //  this.heightAdding2=450;
//       //          }
  
 
// if(AdditionList!="")
// {
//   if(this.AdditionList=="")
//   {
//   this.AdditionList=AdditionList;
//   this.AdditionList2=AdditionList2;
//   }
//   if(this.TypeID2==null)
//   this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
//     this.getOrderDetails(true);
//   });
//   else
//   this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
//     if(this.toppings.length>3 && this.AdditionList2!="")
//     this.orderService.setOrderAddition2(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2),this.TypeID4).subscribe((res:any)=>{
//     this.getOrderDetails2(false);
//   });
//   else if(this.toppings.length>2 && this.AdditionList2!="")
//   this.orderService.setOrderAddition(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2)).subscribe((res:any)=>{
//     this.getOrderDetails2(false);
//   });
//   else 
//   this.getOrderDetails(true);
// });
// }
// else
// this.getOrderDetails2(false);
// this.orderService.getOrderAdditon1(localStorage.MENU_ID,Number(this.posi2),this.pos1,this.posi2).subscribe((res:any)=>{
//   this.additonOrder=res;
//   this.toppings=this.orderService.orderAddingList;
// this.toppingsPage=[];
//   this.toppingsPage.push(this.toppings[4]);
//   this.position=5;
//   if(this.toppings.length>=5)
//   {
  
//     if(this.toppings[5]!=undefined)
//     {
//   this.toppingsPage.push(this.toppings[this.position]);
//   this.position=6;
//     }
//   if(this.position>=this.toppings.length)
//   this.continued="סיום"
//   else
//   this.router.navigate(['../restaurant']);
//   }
// if(this.toppingsPage.length>0)
// this.TypeID=this.toppingsPage[0].TypeID;
// if(this.toppingsPage.length>1)
// this.TypeID2=this.toppingsPage[1].TypeID;
// });
//   }
//   else{
    
//     let AdditionList = "";
//     let AdditionList2 = "";
//     let y=0;
//     if(this.toppingsPage.length>0)
//     for(let i=0;i<2;i++)
//     if(this.toppingsPage.length>i)
//       // for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//       // {
//          //  let OrderAddingItem=this.toppingsPage[i].listSelect[j];
//           //  if(OrderAddingItem.Text!="לחץ לבחירה")
//             for(let p=0;p<this.toppingsPage[i].listA.length;p++)
//             {
//                  let orderAddingItemFood=this.toppingsPage[i].listA[p];
//             //  if(OrderAddingItem!=null)
             
//             if (orderAddingItemFood.IsChoose==1)
//               {
//                       y+=1;
//                       if(y>1)
//                           AdditionList +=",";
//                       AdditionList += orderAddingItemFood.ID;
//                      // p=this.toppingsPage[i].listA.length;
//                }
//            //  }
//            }
//              y=0;
//             if(this.toppingsPage.length>2)
//     for(let i=2;i<4;i++)
//     if(this.toppingsPage.length>i)
//       //  for(let j=0;j<this.toppingsPage[i].listSelect.length;j++)
//        // {
//            // let OrderAddingItem=this.toppingsPage[i].listSelect[j];
//             // if(OrderAddingItem.Text!="לחץ לבחירה")
//             for(let p=0;p<this.toppingsPage[i].listA.length;p++)
//             {
//                  let orderAddingItemFood=this.toppingsPage[i].listA[p];
//             //  if(OrderAddingItem!=null)
//             if (orderAddingItemFood.IsChoose==1)
//            {
//                       y+=1;
//                       if(y>1)
//                           AdditionList2 +=",";
//                       AdditionList2 += orderAddingItemFood.ID;
//                     //  p=this.toppingsPage[i].listA.length;
//                 }
//              }
//           //  }

//             let id=0;
//             for(let i=0; i<this.orderService.order.list.length;i++)
//             {
//               if(this.orderService.order.list[i].MenuID==this.restaurant.MenuList[this.pos1].ID)
//               id=this.orderService.order.list[i].ID;
//             }
//             if(AdditionList!="")
//             {
//               if(this.AdditionList=="")
//               {
//               this.AdditionList=AdditionList;
//               this.AdditionList2=AdditionList2;
//               }
//               if(this.TypeID2==null)
//               this.orderService.setOrderAddition(this.id,this.TypeID,this.AdditionList,Number(this.posi2)).subscribe((res:any)=>{
//                 this.getOrderDetails(true);
//               });
//               else
//               this.orderService.setOrderAddition2(this.id,this.TypeID,this.AdditionList,Number(this.posi2),this.TypeID2).subscribe((res:any)=>{
//                 if(this.toppingsPage.length>3 && this.AdditionList2!="")
//                 this.orderService.setOrderAddition2(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2),this.TypeID4).subscribe((res:any)=>{
//                 this.getOrderDetails(true)
//               });
//               else if(this.toppingsPage.length>2 && this.AdditionList2!="")
//               this.orderService.setOrderAddition(this.id,this.TypeID3,this.AdditionList2,Number(this.posi2)).subscribe((res:any)=>{
//                 this.getOrderDetails(true);
//               });
//               else 
//                 this.getOrderDetails(true);
//             });
//             }
//           else
//           this.getOrderDetails(true);
//         }
// }
// else
// {
// this.proxy.showError("לא בחרת את כל \n שדות החובה","");
// this.heightAdding=0;
// this.heightAdding2=0;
// // for(let i=0;i<this.toppingsPage[0].listSelect.length;i++)
// // {
// // if(this.heightAdding<Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48 && Number(this.heightAdding)<2000)
// //     this.heightAdding=Number(Number(this.toppingsPage[0].listSelect[i].listA.length))*48;
// //     if(this.heightAdding2<Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48 && Number(this.heightAdding)<2000)
// //     this.heightAdding2=Number(Number(this.toppingsPage[1].listSelect[i].listA.length))*48;
// //     if(Number(this.heightAdding)>450)
// //        this.heightAdding=450;
// //        if(Number(this.heightAdding2)>450)
// //        this.heightAdding2=450;
// //    }
// for(let j=0;j<this.toppingsPage.length;j++)
// {
//  // for(let y=0;y<this.toppingsPage[j].listSelect.length;y++)
//   for(let q=0;q<this.toppingsPage[j].listA.length;q++)
//   {
//     if(this.toppingsPage[j].listA[q].IsChoose==1)
//     {
//       //this.toppingsPage[j].listSelect[y].Text=this.toppingsPage[j].listSelect[y].listA[q].Food;
//       this.selectItem(j,q,false);
//     }
//   }
// }
// this.TypeID=this.toppingsPage[0].TypeID;
// if(this.toppingsPage.length>1)
// this.TypeID2=this.toppingsPage[1].TypeID;
// }
//    }

//    getOrderDetails(back:boolean)
// {
//   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//     this.order=res;
//     this.totalAmount=Number(this.order.TotalPrice);
//     localStorage.PRICE=this.totalAmount;
//     this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
//     this.dataService.price.next(this.priceTotal);
//    this.changePrice.emit();
//    if(back==true)
//    {
//    this.router.navigate(['../restaurant']);
//    }
//   });
// }

// getOrderDetails2(back:boolean)
// {
//   this.TypeID=this.toppingsPage[0].TypeID;
//   if(this.toppingsPage.length>1)
//   this.TypeID2=this.toppingsPage[1].TypeID;
//   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//     this.order=res;
//     for(let j=0;j<this.toppingsPage.length;j++)
//     {
//      // for(let y=0;y<this.toppingsPage[j].listSelect.length;y++)
//       for(let q=0;q<this.toppingsPage[j].listA.length;q++)
//       {
//         if(this.toppingsPage[j].listA[q].IsChoose==1)
//         {
//          // this.toppingsPage[j].listSelect[y].Text=this.toppingsPage[j].listSelect[y].listA[q].Food;
//           //this.selectItem(j,y,this.toppingsPage[j].listA[q].Food,this.toppingsPage[j].Type,q,this.toppingsPage[j].TypeID);
//           this.selectItem(j,q,false);

//         }
//       }
//     }
//     this.totalAmount=Number(this.order.TotalPrice);
//     localStorage.PRICE=this.totalAmount;
//     this.priceTotal=this.clickToPay+this.totalAmount+" ₪";
//     this.dataService.price.next(this.priceTotal);
//    this.changePrice.emit();
//    if(back==true)
//    this.router.navigate(['../restaurant']);
 
//   });
// }
// }




