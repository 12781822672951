import { Injectable } from '@angular/core';
import { AppProxy, HttpMethod } from "./app-proxy";
import { ServerResponse } from 'http';
import { OrderAdding ,AdditonOrder, OrderAddingItemFood ,OrderHistory, MyOrder, HistoryDelivery} from '../models/order';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ChangeFood, Restaurant, ToppingsItemFood } from '../models/restaurant';
import { UserEditProfile } from '../models/user';
import { PopupOrderComponent } from '../components/popup-order/popup-order.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class OrdersService 
{
orderAddingList:OrderAdding[];
orderHistoryList:OrderHistory[];
historyDeliveryList:HistoryDelivery[];
order:MyOrder;
pos:number=-1;
position:number=-1;
orderDelivery:boolean=false;
deliveryOk:boolean=false;
deliveryCancel:boolean=false;
delivery:boolean=false;
restaurant:Restaurant;

    constructor(private proxy: AppProxy,private router: Router ,private matDialog: MatDialog){}  

    getOrderDetails(nextPage:boolean)
    {
        let url= this.proxy.baseQAUrl+"Order/getOrderDetails";
        return new Observable(observer => {
        this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
        .subscribe(
            result => {
                let res: any = result;
                if (res.error==false)
                 {
                  let resList: MyOrder = res;
                  this.order=resList;   
                  observer.next(this.order)              
                }
              },
            error => console.log('oops', error));
        });
    }

  setOrderAddress(Address:string,Latitude	:string,Longitude:string)
  {
    let url= this.proxy.baseQAUrl+"Order/setOrderAddress?Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude;
   return new Observable(observer => {
   this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
     .subscribe(
       result => {
           let res: any = result;
          // if (res.error==false)
            {
            // let resList: UserEditProfile = res;
             observer.next(res);
        
           }
         //  else this.proxy.showError(res.responseMessage,"");
         },
       error => 
       {
         console.log('oops', error)
        this.proxy.showError("סירוב כרטיס אשראי","");
     });
     
   });
  }

    setOrderPayment(card_mask:string,card_validity:string,card_cvv:string,user_identity:string,invoice_name:string,TotalPrice:number, IsCancel3DS : boolean)
 {

  let url= this.proxy.baseQAUrl+"Order/setOrderPayment?card_mask="+card_mask+"&card_validity="+card_validity+"&card_cvv="+card_cvv+"&user_identity="+user_identity+"&invoice_name="+invoice_name
   +"&TotalPrice="+TotalPrice+"&IsCancel3DS="+IsCancel3DS;
   return new Observable(observer => {
   this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
   .subscribe(
    result => {
      let res: any = result;
      if (!res.error) {
        observer.next(res);
      } else {
        // Handle error response here
        this.proxy.showError(res.responseMessage, "");
        observer.next(res);
      }
    },
    error => {
      // Handle HTTP error here
      console.log('Oops', error);
      this.proxy.showError("Failed to process payment. Please try again later.", "");
     
    }
  );
     
   });
 }
//  addToBagOrder(MenuID:number,RestaurantID:number,Count:number)
//  {
//      let url= this.proxy.baseQAUrl+"Order/addToBagOrder?MenuID="+MenuID+"&RestaurantID="+RestaurantID+
//      "&Count="+Count;
//      return new Observable(observer => {
//      this.proxy.execute<ServerResponse>(url,HttpMethod.Post)
//      .subscribe(
//          result => {
//              let res: any = result;
//              if (res.error==false)
//               {
//                let resList: ServerResponse = res;
//                observer.next(resList);
//              }
//            },
//          error => console.log('oops', error));  
//      });
//  }

check3DS(OrderID:number)
{
  let url= this.proxy.baseQAUrl+"Order/check3DS2?OrderID="+OrderID;
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Post)
  .subscribe(
    result => {
        let res: any = result;
       
          let resList: ServerResponse = res;
          observer.next(resList);
       
      },
    error => console.log('oops', error));  
});
}

 

 addToBagOrder(MenuID:number,OrderID:number,DelNum:number)
    {
        let url= this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&MenuID="+MenuID+"&DelNum="+DelNum;
        return new Observable(observer => {
        this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
        .subscribe(
            result => {
                let res: any = result;
                if (res.error==false)
                 {
                  let resList: ServerResponse = res;
                  observer.next(resList);
                }
              },
            error => console.log('oops', error));  
        });
    }

    addToBagOrder_(MenuID:number,RestaurantID:number,Count:number)
    {
        let url= this.proxy.baseQAUrl+"Order/addToBagOrder?MenuID="+MenuID+"&RestaurantID="+RestaurantID+
        "&Count="+Count;
        return new Observable(observer => {
        this.proxy.execute<ServerResponse>(url,HttpMethod.Post)
        .subscribe(
            result => {
                let res: any = result;
                if (res.error==false)
                 {
                  let resList: ServerResponse = res;
                  observer.next(resList);
                }
                else if(res.responseMessage=="There is another open order")
                {
                  if(this.order.list==null || this.order.list.length==0 )
                  {
                  this.setUpdateOrder(this.order.OrderID,0,0,0,1,
                  null,null,0,null,1,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
                  this.router.navigate(['../restaurant']);
                     });
                  }
                  else
                     this.matDialog.open(PopupOrderComponent, {
                      data: { OrderID: this.order.OrderID },
                    });
                }
              },
            error => console.log('oops', error));  
        });
    }

    setUpdateOrderEmploye(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,MenuID:number
      ,Count:number,Status:number,Note:string,IsAddress:number,Address:string,Latitude:string,Longitude:string,
      IsGiftCard:number,GiftNumber:number,SumGift:number,IsEmploye:number,BudgetCode:string){
        let url= this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&MenuID="+MenuID+"&Count="+Count+"&Status="+Status
      +"&Note="+Note+"&IsAddress="+IsAddress+"&Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude+"&IsGiftCard="+IsGiftCard+"&GiftNumber="+GiftNumber+"&SumGift="+SumGift+"&IsEmploye="+IsEmploye+
      "&BudgetCode="+BudgetCode;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res);
              }
               else if(IsGiftCard==1)
                observer.next(res);
            },
          error => console.log('oops', error));   
      });
    }

    setUpdateOrder3(OrderID:number,UserName:string,UserPhone:string,
      userEmail:string,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number
      ,Count:number,Status:number,Note:string,Address:string,Latitude:string,Longitude:string){
        let url= this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&UserName="+UserName+"&UserPhone="+UserPhone+"&userEmail="+userEmail+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Count="+Count+"&Status="+Status
      +"&Note="+Note+"&Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res);
              }
               else if(res.error==true && Status==2)
               this.proxy.showError("שגיאה בסיום ההזמנה ",""); 
               else  if(res.responseMessage=="the delivery out of bounds")
               observer.next(res);
            },
          error => console.log('oops', error));   
      });
    }

    setUpdateOrder(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,MenuID:number
      ,Count:number,Status:number,Note:string,IsAddress:number,Address:string,Latitude:string,Longitude:string,IsGiftCard:number,GiftNumber:number,SumGift:number,card_mask:string,card_validity:string){
        let url= this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&MenuID="+MenuID+"&Count="+Count+"&Status="+Status
      +"&Note="+Note+"&IsAddress="+IsAddress+"&Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude+"&IsGiftCard="+IsGiftCard+"&GiftNumber="+GiftNumber+"&SumGift="+SumGift+"&card_mask="+card_mask+"&card_validity="+card_validity;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res);
              }
               else if(IsGiftCard==1)
                observer.next(res);
               else if(res.error==true && Status==2)
               this.proxy.showError("שגיאה בסיום ההזמנה ",""); 
               else  if(res.responseMessage=="the delivery out of bounds")
               observer.next(res);
            },
          error => console.log('oops', error));   
      });
    }

    setUpdateOrder1(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,Status:number,Note:string,IsAddress:number){
      let url;
      if(Note!=null)
        url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&Status="+Status
      +"&Note="+Note+"&IsAddress="+IsAddress;
      else
      url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&Status="+Status
      "&IsAddress="+IsAddress;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              observer.next(res);
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                
                if(IsDelivery>0 || IsPickUp>0 || IsSitting>0)
                this.router.navigate(['../securePayment']); 
              }
            //  else if(res.error==true && res.responseMessage=="the delivery out of bounds")
            //  this.proxy.showError("כתובת המשלוח מחוץ לתחום","");
            },
          error => console.log('oops', error));   
      });
    }

    setUpdateOrder2(OrderID:number,UserName:string,UserPhone:string,
      userEmail:string,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,Status:number,Note:string,IsAddress:number){
      let url;
      if(Note!=null)
        url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&UserName="+UserName+"&UserPhone="+UserPhone+"&userEmail="+userEmail+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status
      +"&Note="+Note+"&IsAddress="+IsAddress;
      else
      url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&UserName="+UserName+"&UserPhone="+UserPhone+"&userEmail="+userEmail+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status
      "&IsAddress="+IsAddress;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res);             
              }       
            },
          error => console.log('oops', error));   
      });
    }

    setUpdateOrder4(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,Status:number){
      let url;
     // url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&Status="+Status;
      url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res);             
              }       
            },
          error => console.log('oops', error));   
      });
    }

    setUpdateOrder5(OrderID:number,IsDelivery:number,IsPickUp:number,IsSitting:number,PaymentType:number,Status:number,Address:string,Latitude:string,Longitude:string){
      let url;
      url = this.proxy.baseQAUrl+"Order/setUpdateOrder?OrderID="+OrderID+"&IsDelivery="+IsDelivery+
      "&IsPickUp="+IsPickUp+"&IsSitting="+IsSitting+"&PaymentType="+PaymentType+"&Status="+Status+"&Address="+Address+"&Latitude="+Latitude+"&Longitude="+Longitude;
      return new Observable(observer => {
      this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res);             
              }       
            },
          error => console.log('oops', error));   
      });
    }

    getOrderAdditon(MenuID,MenuNumber,pos,position)
    {
      let url= this.proxy.baseQAUrl+"Order/getOrderAdditon?MenuID="+MenuID+"&MenuNumber="+MenuNumber;
      return new Observable(observer => {
      this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                  let resList: AdditonOrder = res;
                  this.orderAddingList=resList.list;
                  observer.next(resList);
                  let orderAddingItemFood=new OrderAddingItemFood();
                  orderAddingItemFood.ID=0;
                  orderAddingItemFood.Price=0;
                  orderAddingItemFood.Select=false;
                  if(this.restaurant!=null && this.restaurant.MenuList!=null && this.restaurant.MenuList[pos].ChangeFood!=null)
                 {
                  let changeFood=new ChangeFood();
                  if(this.restaurant.MenuList[pos].ChangeFood!=null)
                  {
                  if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Text!=null)
                  changeFood.Text=this.restaurant.MenuList[pos].ChangeFood[position].Text;
                  if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Name!=null)
                  changeFood.Name=this.restaurant.MenuList[pos].ChangeFood[position].Name;
                  let top:OrderAdding[]=[];
                  changeFood.toppings=top;
                  this.restaurant.MenuList[pos].ChangeFood[position]=changeFood;
                  this.restaurant.MenuList[pos].ChangeFood[position].toppings=this.orderAddingList;
                 }}
                 if(this.orderAddingList.length>0)
                 {
                   if(position>=0)
                   {
                    localStorage.position=position;
                    localStorage.pos=pos;
                 if(this.orderAddingList.length==1 && this.orderAddingList[0].listSelect.length>0 )
                 this.router.navigate(['../toppings2']); 
                 else
                 this.router.navigate(['../toppings2']);   
                   }
                 }
                    else if(this.order.list.length>0)
                     this.proxy.showError("אין רשימת תוספות ","");           
              }
            },
          error => console.log('oops', error));
      });  
    }
    // getOrderAdditon(MenuID,MenuNumber,pos,position)
    // {
    //   let url= this.proxy.baseQAUrl+"Order/getOrderAdditon?MenuID="+MenuID+"&MenuNumber="+MenuNumber;
    //   return new Observable(observer => {
    //   this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    //   .subscribe(
    //       result => {
    //           let res: any = result;
    //           if (res.error==false)
    //            {
    //               let resList: AdditonOrder = res;
    //               this.orderAddingList=resList.list;
    //               observer.next(resList);
    //               let orderAddingItemFood=new OrderAddingItemFood();
    //               orderAddingItemFood.ID=0;
    //               orderAddingItemFood.Price=0;
    //               orderAddingItemFood.Select=false;
    //               if(this.restaurant!=null && this.restaurant.MenuList!=null && this.restaurant.MenuList[pos].ChangeFood!=null)
    //              {
    //               let changeFood=new ChangeFood();
    //               if(this.restaurant.MenuList[pos].ChangeFood!=null)
    //               {
    //               if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Text!=null)
    //               changeFood.Text=this.restaurant.MenuList[pos].ChangeFood[position].Text;
    //               if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Name!=null)
    //               changeFood.Name=this.restaurant.MenuList[pos].ChangeFood[position].Name;
    //               let top:OrderAdding[]=[];
    //               changeFood.toppings=top;
    //               this.restaurant.MenuList[pos].ChangeFood[position]=changeFood;
    //               this.restaurant.MenuList[pos].ChangeFood[position].toppings=this.orderAddingList;
    //              }}
    //              if(this.orderAddingList.length>0)
    //              {
    //                if(position>=0)
    //                {
    //              if(this.orderAddingList.length==1 && this.orderAddingList[0].listSelect.length>0 )
    //              this.router.navigate(['../toppings',{pos, position}]); 
    //              else
    //              this.router.navigate(['../toppings',{pos, position}]);   
    //                }
    //              }
    //                 else this.proxy.showError("אין רשימת תוספות ","");           
    //           }
    //         },
    //       error => console.log('oops', error));
    //   });  
    // }

    getOrderAdditon1(MenuID,MenuNumber,pos,position)
    {
      let url= this.proxy.baseQAUrl+"Order/getOrderAdditon1?MenuID="+MenuID+"&MenuNumber="+MenuNumber;
      return new Observable(observer => {
      this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                  let resList: AdditonOrder = res;
                  this.orderAddingList=resList.list;
                  observer.next(resList);
                  let orderAddingItemFood=new OrderAddingItemFood();
                  orderAddingItemFood.ID=0;
                  orderAddingItemFood.Price=0;
                  orderAddingItemFood.Select=false;
                  if(this.restaurant!=null && this.restaurant.MenuList!=null && this.restaurant.MenuList[pos].ChangeFood!=null)
                 {
                  let changeFood=new ChangeFood();
                  if(this.restaurant.MenuList[pos].ChangeFood!=null)
                  {
                  if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Text!=null)
                  changeFood.Text=this.restaurant.MenuList[pos].ChangeFood[position].Text;
                  if(this.restaurant.MenuList[pos].ChangeFood[position]!=null && this.restaurant.MenuList[pos].ChangeFood[position].Name!=null)
                  changeFood.Name=this.restaurant.MenuList[pos].ChangeFood[position].Name;
                  let top:OrderAdding[]=[];
                  changeFood.toppings=top;
                  this.restaurant.MenuList[pos].ChangeFood[position]=changeFood;
                  this.restaurant.MenuList[pos].ChangeFood[position].toppings=this.orderAddingList;
                 }}
                 if(this.orderAddingList.length>0)
                 {
                   if(position>=0)
                   {
                    localStorage.position=position;
                    localStorage.pos=pos;
                 if(this.orderAddingList.length==1 && this.orderAddingList[0].listSelect.length>0 )
                 this.router.navigate(['../toppings2']); 
                 else
                 this.router.navigate(['../toppings2']);   
                   }
                 }
                    else if(this.order.list.length>0)
                     this.proxy.showError("אין רשימת תוספות ","");           
              }
            },
          error => console.log('oops', error));
      });  
    }

    setOrderAddition(ID:number,AdditionType:number,AdditionList:string,CountNumber:number)
    {
      let url= this.proxy.baseQAUrl+"Order/setOrderAddition?ID="+ID+"&AdditionType="+AdditionType+"&AdditionList="+AdditionList+"&CountNumber="+CountNumber;
      if(AdditionList=="")
      url= this.proxy.baseQAUrl+"Order/setOrderAddition?ID="+ID+"&AdditionType="+AdditionType+"&CountNumber="+CountNumber;
      return new Observable(observer => {
      this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res)          
              }
              else this.proxy.showError("בעיה בבחירת תוספת","");
            },
          error => console.log('oops', error));
      }); 
    }

    setOrderAddition2(ID:number,AdditionType:number,AdditionList:string,CountNumber:number,AdditionType2:number)
    {
      let url= this.proxy.baseQAUrl+"Order/setOrderAddition?ID="+ID+"&AdditionType="+AdditionType+"&AdditionList="+AdditionList+"&CountNumber="+CountNumber+"&AdditionType2="+AdditionType2;
      return new Observable(observer => {
      this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res)          
              }
              else this.proxy.showError("בעיה בבחירת תוספת","");
            },
          error => console.log('oops', error));
      }); 
    }

    sendConfirmationSms(OrderID:number , TotalPrice : number  = 0)
{
  let paymentType  = localStorage.paymentBy==1 ? 0 : 1
  let url= this.proxy.baseQAUrl+"Order/sendConfirmationSms?OrderID="+OrderID +"&TotalPayment=" + TotalPrice+ "&PaymentType="+ paymentType;;
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
          observer.next(res);
          if (res.error==false && res.responseMessage=="ok")
           {
          }
        },
      error => console.log('oops', error));
  });
}

setOrderSmsConfirmation(OrderID:number,SMSCode:string,innerWidth:any)
{
  let url= this.proxy.baseQAUrl+"Order/setOrderSmsConfirmation?OrderID="+OrderID+"&SMSCode="+SMSCode;
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
         // if(innerWidth<=480)
         // {
          // if (res.error==false && res.responseMessage=="ok")
            {
              observer.next(res);
            }
         // else
        //  {
       //   this.proxy.showError("קוד שגוי הזן שנית","");
        //   this.router.navigate(["/verificationCode"]);
        //   observer.next(res);
        //  }
        //  }
         // else
          //observer.next(res);
        },
      error => console.log('oops', error));
  });
}

    setInviteName(MenuID:number,MenuNumber:number,InviteName:string)
    {
      let url= this.proxy.baseQAUrl+"Order/setInviteName?MenuID="+MenuID+"&MenuNumber="+MenuNumber+"&InviteName="+InviteName;
      return new Observable(observer => {
      this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
      .subscribe(
          result => {
              let res: any = result;
              if (res.error==false)
               {
                let resList: ServerResponse = res;
                observer.next(res)          
              }
              else this.proxy.showError("בעיה בהוספת שם למנה ","");
            },
          error => console.log('oops', error));
      }); 
    }

   GetHistoryOrders(Search:string)
   {
    let url;
     if(Search!=null)
     url= this.proxy.baseQAUrl+"Order/GetHistoryOrders?Search="+Search;
    else
    url= this.proxy.baseQAUrl+"Order/GetHistoryOrders";
    return new Observable(observer => {
    this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)
             {
                let resList: OrderHistory[] = res.list;
                this.orderHistoryList=resList;
                if(this.orderHistoryList.length>0)
                observer.next(this.orderHistoryList)
                else this.proxy.showError("אין הזמנות בהיסטוריה","");
            }
          },
        error => console.log('oops', error)); 
    });
   }

   getDownloadInvoice(ID:number)
   {
   let url= this.proxy.baseQAUrl+"Order/getDownloadInvoice?ID="+ID;
   return new Observable(observer => {
    this.proxy.execute<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)  
        {
              observer.next(res)  
        }
          },
        error => console.log('oops', error)); 
    });
   }

  setNewDelivery(Origin:string,Destination:string,DeliveryTime:string,Phone:string,Description:string)
  {
    let url= this.proxy.baseQAUrl+"Order/setNewDelivery?Origin="+Origin+"&Destination="+Destination+
    "&DeliveryTime="+DeliveryTime+"&Phone="+Phone+"&Description="+Description;
    return new Observable(observer => {
    this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)
             {
              let resList: ServerResponse = res;
              this.orderDelivery=true;
              observer.next(this.orderDelivery);
            }
            else this.proxy.showError("שגיאה בהוספת משלוח","");
          },
        error => console.log('oops', error)); 
      }); 
  }

  setDeliveryStatus(Status:number)
  {
    let url= this.proxy.baseQAUrl+"Order/setDeliveryStatus?Status="+Status;
    return new Observable(observer => {
    this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)
             {
              let resList: ServerResponse = res;
            this.orderDelivery=true;
            observer.next(this.orderDelivery);
            }
            else this.proxy.showError("שגיאה בעידכון סטטוס משלוח","");
          },
        error => console.log('oops', error)); 
    });  
  }

 getHistoryDelivery()
 {
  let url= this.proxy.baseQAUrl+"Order/getHistoryDelivery";
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
              let resList: HistoryDelivery[] = res.list;
              this.historyDeliveryList=resList;
              if(this.historyDeliveryList.length>0)
              observer.next(this.historyDeliveryList);
              else this.proxy.showError("אין משלוחים בהיסטוריה","");   
          }
          else this.proxy.showError("אין משלוחים בהיסטוריה","");
        },
      error => console.log('oops', error)); 
  });
 }

 setOrderRating(Rate:number)
 {
  let url= this.proxy.baseQAUrl+"Order/setOrderRating?Rate="+Rate;
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
          }
          else this.proxy.showError("שגיאה בעידכון דירוג","");
        },
      error => console.log('oops', error));  
 }

setNewOrderByHistory(ID:number)
{
  let url= this.proxy.baseQAUrl+"Order/setNewOrderByHistory?ID="+ID;
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
            observer.next(resList)
           // this.getOrderDetails(true);
          }
          else this.proxy.showError("שגיאה באחזור הזמנה מההיסטוריה","");
        },
      error => console.log('oops', error));  
  });
}
}