import { Component, OnInit , Output ,EventEmitter ,HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user-service';
import { LoginPhoneVarificationComponent } from '../login-phone-varification/login-phone-varification.component';
import {MatDialog, MatDialogConfig ,MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-main-home',
  templateUrl: './main-home.component.html',
  styleUrls: ['./main-home.component.css']
})
export class MainHomeComponent implements OnInit {
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.backPress();
  // }
  
   constructor(public router:Router,private bussinssService:BussinssService,private dataService:DataService ,private matDialog:MatDialog) {
   }

  ngOnInit(): void {
    localStorage.Delivery=-1;
    if(location.hash=="#/codeWorker")
    this.router.navigate(["../MainHome"])
    if(localStorage.SMS_CODE=="")
this.openDialog();
else{
     this.bussinssService.getLastMenu();
     if(localStorage.ACCESS_TOKEN  == "null")
     this.dataService.state.next("התחבר");
     else
     this.dataService.state.next("התנתק");
}
this.dataService.show.next(false);
this.changeShowPrice.emit();
this.dataService.byFood.next(false);
this.changByFood.emit();
    }

    backPress()
    {
      this.router.navigate(["../mainHome"])
    }

openDialog(): void {
  const dialogRef = this.matDialog.open(LoginPhoneVarificationComponent, {
    backdropClass: 'cdk-overlay-transparent-backdrop',
    hasBackdrop: false,
  });
}
}
